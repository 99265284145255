import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Icon for view
import { Box, Divider, IconButton, Popover, Typography } from '@mui/material';
import Trash from 'assets/icons/Trash.svg';
import { useCallback, useState } from 'react';

const PopoverComponent = ({ onDelete, onView }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleDeleteClick = useCallback(() => {
        onDelete();
        handleClose();
    }, [handleClose, onDelete]);

    const handleViewClick = useCallback(() => {
        onView();
        handleClose();
    }, [handleClose, onView]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ borderRadius: 20 }}
            >
                <Box
                    sx={{
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        bgcolor: 'background.paper',
                        borderRadius: '12px',
                        boxShadow: 3,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            cursor: 'pointer',
                        }}
                        onClick={handleViewClick}
                    >
                        <VisibilityIcon
                            style={{ color: '#0061DB', marginRight: 8 }}
                        />
                        <Typography
                            sx={{
                                color: '#0061DB',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                        >
                            View
                        </Typography>
                    </Box>
                    <Divider
                        sx={{
                            borderStyle: 'dashed',
                            width: '100%',
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            cursor: 'pointer',
                        }}
                        onClick={handleDeleteClick}
                    >
                        <img
                            src={Trash}
                            alt="Delete"
                            style={{ color: '#FF6347', marginRight: 8 }}
                        />
                        <Typography
                            sx={{
                                color: '#FF6347',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                        >
                            Delete
                        </Typography>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};

export default PopoverComponent;
