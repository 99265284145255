import { Box, Typography, IconButton } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Icon from 'assets/icons/error.png';

function ErrorAnimation({ onClose, data }) {
    return (
        <Box
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: '#1A202C99',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 99900000,
            }}
        >
            <Box
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative', // To position the close button relative to this box
                }}
                p={4}
                borderRadius={4}
            >
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <img src={Icon} alt="" srcset="" width="50px" height="50px" />
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '25px',
                        marginTop: '20px',
                    }}
                >
                    Error
                </Typography>
                <Typography sx={{ color: '#9EA3AE' }}>{data}</Typography>
            </Box>
        </Box>
    );
}

export default ErrorAnimation;
