import {
    Box,
    Button,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import SettingsGrey from 'assets/icons/settingsGrey.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TableSkeleton from 'shared/table-skeleton';
import UpgradeToPro from 'shared/UpgradeToPro';
import { store } from 'store';
import {
    createInvoiceRequest,
    deleteInvoiceRequest,
    fetchInvoicesRequest,
} from 'store/actions';
import { useCompanyService } from 'store/services/company.service';
import { processPaymentStatus } from 'utils/helpers';
import ConfirmDialog from './Components/ConfirmDialog';
import NewInvoicePopup from './Components/NewInvoicePopup';
import PopoverComponent from './Components/PopoverComponent';
import CustomPagination from './Components/TablePagination';

function InvoiceList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getUserAssociatedCompany } = useCompanyService();
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [filterStatus, setFilterStatus] = useState('');
    const [filteredRows, setFilteredRows] = useState([]);
    const companyid = useSelector((state) => state?.company?.data?.id);

    const handleChange = useCallback((event) => {
        setFilterStatus(event.target.value);
    }, []);

    const handleChangeMonth = useCallback((event) => {
        setValue(event.target.value);
    }, []);

    const invoices = useSelector((state) => state.invoice.invoices);
    const { clients, dropDownServices } = useSelector((state) => state.invoice);

    function findFullNameById(id) {
        const item = clients.find((element) => element.id === id);
        return item ? item.full_name : null;
    }

    useEffect(() => {
        if (filterStatus === 'draft') {
            const draftData = invoices.filter(
                (item) => item.status === 'draft',
            );
            setFilteredRows(draftData);
        } else {
            setFilteredRows(invoices);
        }
    }, [filterStatus, invoices]);

    useEffect(() => {
        setIsLoading(true);
        const { token } = store.getState().auth;
        dispatch(fetchInvoicesRequest({ token, setIsLoading }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, []);

    const paginatedRows = filteredRows?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    );

    const [dialogOpen, setDialogOpen] = React.useState(true);
    const [importTransaction, setImportTransaction] = React.useState(false);
    const handleDialogClose = () => {
        setDialogOpen(false);
        setImportTransaction(false);
    };

    const handleSyncAll = useCallback(
        async (name) => {
            const { token } = store.getState().auth;
            const invoiceData = {
                name: name,
                company: companyid,
            };
            try {
                dispatch(
                    createInvoiceRequest({ invoiceData, token, navigate }),
                );
            } catch (error) {
                console.error('API Error:', error);
            }
        },
        [companyid, dispatch, navigate],
    );

    const createNewInvoice = useCallback(() => {
        setImportTransaction(true);
        setDialogOpen(true);
    }, []);

    // delete
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);

    const handleDeleteClick = useCallback((rowId) => {
        setSelectedRowId(rowId);
        setConfirmDialogOpen(true);
    }, []);

    const handleConfirmClose = useCallback(() => {
        setConfirmDialogOpen(false);
    }, []);

    const handleConfirmDelete = useCallback(() => {
        const { token } = store.getState().auth;
        dispatch(deleteInvoiceRequest({ invoiceId: selectedRowId, token }));
        setConfirmDialogOpen(false);
    }, [dispatch, selectedRowId]);

    const handleViewClick = useCallback(
        (id) => {
            dispatch({
                type: 'VIEW_INVOICE_BY_ID_REQUEST',
                payload: { id, navigate },
            });
        },
        [dispatch, navigate],
    );
    const is_subscription_active = useSelector(
        (state) => state?.company?.data?.subscription_active,
    );

    return (
        <>
            <Stack
                sx={{
                    filter: !is_subscription_active ? 'blur(2px)' : 'none',
                    pointerEvents: !is_subscription_active ? 'none' : 'auto',
                    userSelect: !is_subscription_active ? 'none' : 'auto',
                }}
                onKeyDown={(e) => {
                    if (!is_subscription_active) {
                        e.preventDefault();
                    }
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    pb={0}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#121826'
                                : 'transparent',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                        width="100%"
                        alignItems="center"
                    >
                        {/* <Box
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                        }}
                    >
                        <FormControl
                            sx={{
                                m: 1,
                                minWidth: 160,
                                width: '100%',
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#212936'
                                        : 'white',
                            }}
                            size="small"
                        >
                            <InputLabel id="date-range-label">
                                All Status
                            </InputLabel>
                            <Select
                                labelId="custom-select-label"
                                id="custom-select"
                                value={filterStatus}
                                onChange={handleChange}
                                label="All Status"
                                sx={{
                                    backgroundColor: '#F4F4F6',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : 'white',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '16px', // Apply border-radius to the root
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        borderRadius: '16px', // Apply border-radius to the input
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none', // Hide default border
                                    },
                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline':
                                        {
                                            border: '1px solid #E5E6EB', // Border color on focus
                                        },
                                }}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={'draft'}>Draft</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl
                            sx={{
                                m: 1,
                                minWidth: 160,
                                width: '100%',
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#212936'
                                        : 'white',
                            }}
                            size="small"
                        >
                            <InputLabel id="date-range-label">
                                Selected Date
                            </InputLabel>
                            <Select
                                labelId="custom-select-label"
                                id="custom-select"
                                value={value}
                                onChange={handleChangeMonth}
                                label="Selected Date"
                                sx={{
                                    backgroundColor: '#F4F4F6',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : 'white',
                                    borderRadius: '6px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '& .Mui-focused .MuiOutlinedInput-notchedOutline':
                                        {
                                            border: '1px solid #E5E6EB',
                                        },
                                }}
                            >
                                <MenuItem value={'lastDay'}>Last Day</MenuItem>
                                <MenuItem value={'lastWeek'}>
                                    Last Week
                                </MenuItem>
                                <MenuItem value={'lastMonth'}>
                                    Last Month
                                </MenuItem>
                                <MenuItem value={'lastYear'}>
                                    Last Year
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box> */}

                        <Box
                            sx={{
                                width: { xs: '100%', sm: 'auto' },
                                display: 'flex',
                            }}
                            gap={2}
                        >
                            <Button
                                sx={{
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#212936'
                                            : 'white',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#9EA3AE'
                                            : '#6C727F',
                                    borderRadius: '8px',
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '1px solid #394150'
                                            : '1px solid #E5E6EB',
                                    textTransform: 'none',
                                    paddingX: '20px',
                                    paddingY: '10px',
                                }}
                                size="small"
                                // startIcon={<SettingsIcon />}
                                onClick={() => navigate('/invoiceSettings')}
                            >
                                <img
                                    src={SettingsGrey}
                                    alt="Icon"
                                    style={{
                                        width: 18,
                                        height: 18,
                                        marginRight: '6px',
                                    }}
                                />
                                Invoice Settings
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: '#0061DB',
                                    color: 'white',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    paddingX: '20px',
                                    paddingY: '10px',
                                    '&:hover': {
                                        backgroundColor: '#004BB5',
                                    },
                                }}
                                size="small"
                                // onClick={() => navigate('/invoice')}
                                onClick={createNewInvoice}
                            >
                                + Create New Invoice
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Box p={3}>
                    <Box
                        sx={{
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#212936'
                                    : 'white',

                            borderRadius: '12px',
                        }}
                        p={1}
                    >
                        <TableContainer
                            sx={{
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                    height: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                },
                            }}
                        >
                            <Table
                                aria-label="simple table"
                                sx={{
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#212936'
                                            : 'white',
                                    minWidth: 650,
                                    border: 'none',
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Invoice name</TableCell>

                                        <TableCell>Clients</TableCell>
                                        <TableCell>Balance</TableCell>
                                        <TableCell>Date issued</TableCell>
                                        <TableCell>Payment date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                {isLoading ? (
                                    <TableBody>
                                        {[...Array(5)].map((i, index) => (
                                            <TableSkeleton
                                                key={index}
                                                numberOfColumns={7}
                                            />
                                        ))}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {paginatedRows?.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    {row?.client?.full_name ||
                                                        ''}
                                                </TableCell>
                                                <TableCell>
                                                    {row.total_amount}
                                                </TableCell>
                                                <TableCell>
                                                    {row.due_date}
                                                </TableCell>
                                                <TableCell>
                                                    {row.due_date}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        noWrap
                                                        sx={{
                                                            fontSize: '13px',
                                                            fontWeight: '700',
                                                            backgroundColor:
                                                                row.status ===
                                                                'sent'
                                                                    ? '#ECFDF3'
                                                                    : row.status ===
                                                                      'Overdue'
                                                                    ? '#FEF3F2'
                                                                    : row.status ===
                                                                      'draft'
                                                                    ? '#EEF4FF'
                                                                    : row.status ===
                                                                      'payment_processing'
                                                                    ? '#FFF7E5'
                                                                    : row.status ===
                                                                      'paid'
                                                                    ? '#E6F4FF'
                                                                    : '#FEF3F2',

                                                            color:
                                                                row.status ===
                                                                'sent'
                                                                    ? '#067647'
                                                                    : row.status ===
                                                                      'Overdue'
                                                                    ? '#B42318'
                                                                    : row.status ===
                                                                      'draft'
                                                                    ? '#3538CD'
                                                                    : row.status ===
                                                                      'payment_processing'
                                                                    ? '#FFB400'
                                                                    : row.status ===
                                                                      'paid'
                                                                    ? '#007BFF'
                                                                    : '#FEF3F2',
                                                            border:
                                                                row.status ===
                                                                'sent'
                                                                    ? '1px solid #ABEFC6'
                                                                    : row.status ===
                                                                      'Overdue'
                                                                    ? '1px solid #FECDCA'
                                                                    : row.status ===
                                                                      'draft'
                                                                    ? '1px solid #C7D7FE'
                                                                    : row.status ===
                                                                      'payment_processing'
                                                                    ? '1px solid #FFE5B4'
                                                                    : row.status ===
                                                                      'paid'
                                                                    ? '1px solid #C3E7FF'
                                                                    : '1px solid #FECDCA',
                                                            width: '150px',
                                                            padding: '4px',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            borderRadius:
                                                                '12px',
                                                            textTransform:
                                                                'capitalize',
                                                        }}
                                                    >
                                                        {processPaymentStatus(
                                                            row?.status,
                                                        )}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <PopoverComponent
                                                        onDelete={() =>
                                                            handleDeleteClick(
                                                                row.id,
                                                            )
                                                        }
                                                        onView={() =>
                                                            handleViewClick(
                                                                row.id,
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>

                            <Divider />

                            <CustomPagination
                                count={filteredRows?.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[5, 10, 15]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Box>
                </Box>
                <ConfirmDialog
                    open={confirmDialogOpen}
                    onClose={handleConfirmClose}
                    onConfirm={handleConfirmDelete}
                />

                {importTransaction && (
                    <NewInvoicePopup
                        onSyncAll={handleSyncAll}
                        open={dialogOpen}
                        onClose={handleDialogClose}
                    />
                )}
            </Stack>
            {!is_subscription_active && (
                <Stack
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        pointerEvents: 'auto',
                    }}
                >
                    <UpgradeToPro onlyIcon />
                </Stack>
            )}
        </>
    );
}

export default InvoiceList;
