import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@mui/material';

const CustomPaper = (props) => {
    return (
        <Paper
            {...props}
            sx={{
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '8px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                overflow: 'hidden',
            }}
        />
    );
};

const AddServiceModal = ({
    open,
    onClose,
    onSave,
    isEdit,
    serviceItem,
    dropDownServices,
    onNameChange,
    onPriceChange,
    onQuantityChange,
    onDetailsChange,
    loading,
    onDropDownClick,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                },
            }}
        >
            <DialogTitle
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                {isEdit ? 'Edit Service' : 'Add Service'}
            </DialogTitle>
            <DialogContent
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#555' : '#888',
                        borderRadius: '8px',
                        border: '2px solid transparent',
                        backgroundClip: 'content-box',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#777' : '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#2D3B4E'
                                : '#f1f1f1',
                        borderRadius: '8px',
                    },
                }}
            >
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    border="none"
                    borderRadius="8px"
                    position="relative"
                    sx={{
                        border: 'none',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                md: 'row',
                            },
                            gap: 2,
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    PaperComponent={CustomPaper}
                                    freeSolo
                                    options={dropDownServices?.filter(
                                        (service) => service?.id,
                                    )}
                                    getOptionLabel={(option) =>
                                        option?.name || ''
                                    }
                                    renderOption={(props, option, state) =>
                                        renderOption(
                                            props,
                                            option,
                                            onDropDownClick,
                                            {
                                                selected: state.selected,
                                                index: state.index,
                                                optionsLength:
                                                    dropDownServices?.length,
                                            },
                                        )
                                    }
                                    value={serviceItem}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            size="small"
                                            placeholder="Service Name"
                                            variant="outlined"
                                            name="name"
                                            value={serviceItem?.name}
                                            onChange={onNameChange}
                                            error={!!serviceItem?.errors?.name}
                                            helperText={
                                                serviceItem?.errors?.name
                                            }
                                            sx={{
                                                bgcolor: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#394150'
                                                        : '#F4F4F6',
                                                borderRadius: '6px',
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: (theme) =>
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? 'none'
                                                                : '1px solid #E5E6EB',
                                                        borderRadius: '8px',
                                                    },
                                                    '&:hover fieldset': {
                                                        border: 'none',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                md: 'row',
                            },
                            gap: 2,
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Unit Price"
                                    variant="outlined"
                                    value={serviceItem?.unitPrice}
                                    onChange={onPriceChange}
                                    error={!!serviceItem?.errors?.unitPrice}
                                    helperText={serviceItem?.errors?.unitPrice}
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '6px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Quantity"
                                    variant="outlined"
                                    value={serviceItem?.qty}
                                    onChange={onQuantityChange}
                                    error={!!serviceItem?.errors?.qty}
                                    helperText={serviceItem?.errors?.qty}
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '6px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Service details"
                        variant="outlined"
                        value={serviceItem?.details}
                        onChange={onDetailsChange}
                        error={!!serviceItem?.errors?.details}
                        helperText={serviceItem?.errors?.details}
                        sx={{
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#394150'
                                    : '#F4F4F6',
                            borderRadius: '6px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'none'
                                            : '1px solid #E5E6EB',
                                    borderRadius: '8px',
                                },
                                '&:hover fieldset': {
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'none'
                                            : '1px solid #E5E6EB',
                                    borderRadius: '8px',
                                },
                                '&.Mui-focused fieldset': {
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'none'
                                            : '1px solid #E5E6EB',
                                    borderRadius: '8px',
                                },
                            },
                        }}
                    />
                </Box>
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Button
                    sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#6C727F',
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        textTransform: 'none',
                        marginRight: '10px',
                    }}
                    size="small"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    sx={{
                        backgroundColor: '#0061DB',
                        color: 'white',
                        borderRadius: '8px',
                        textTransform: 'none',
                        paddingX: '20px',
                        paddingY: '8px',
                        '&:hover': {
                            backgroundColor: '#004BB5',
                        },
                        width: {
                            xs: '100%',
                            sm: 'auto',
                        },
                    }}
                    size="small"
                    onClick={onSave}
                    loading={loading}
                >
                    {isEdit ? 'Update' : 'Save'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddServiceModal;

const renderOption = (
    props,
    option,
    onDropDownClick,
    { selected, index, optionsLength },
) => {
    return (
        <>
            <Box
                onClick={(event) => {
                    onDropDownClick(option);
                    props?.onClick(event);
                }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    cursor: 'pointer',
                    bgcolor: selected ? 'action.selected' : 'inherit',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    },
                }}
            >
                <Box m={2}>
                    <Typography
                        sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            color: (theme) =>
                                theme.palette.mode === 'dark' ? 'white' : '',
                        }}
                    >
                        {option?.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '12px',
                            color: (theme) =>
                                theme.palette.mode === 'dark' ? 'white' : '',
                        }}
                    >
                        {option?.details}
                    </Typography>
                </Box>
                <Typography
                    m={2}
                    sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        color: (theme) =>
                            theme.palette.mode === 'dark' ? 'white' : '',
                    }}
                >
                    ${option?.price}
                </Typography>
            </Box>
            {index < optionsLength - 1 && <Divider />}
        </>
    );
};
