import { ListItemText } from '@mui/material';
import { ReactComponent as RightArrow } from 'assets/icons/RightArrow.svg';
import { ReactComponent as RightArrowDarkMode } from 'assets/icons/RightArrowDarkMode.svg';
import { useThemeToggle } from 'hooks/useThemeToggle';
import { forwardRef } from 'react';
import { StyledItem } from './styles';

const NavItemFirst = forwardRef(
    ({ item, mainTitle, depth, open, ...other }, ref) => {
        const { children } = item;
        const subItem = depth !== 1;
        const { themeMode } = useThemeToggle();

        return (
            <StyledItem ref={ref} open={open} depth={depth} {...other}>
                <ListItemText
                    primary={`${mainTitle}`}
                    primaryTypographyProps={{
                        noWrap: true,
                        sx: {
                            fontSize: 16,
                            lineHeight: '16px',
                            textAlign: 'center',
                            ...(subItem && {
                                fontSize: 14,
                                width: 'auto',
                                textAlign: 'left',
                            }),
                        },
                    }}
                />

                {!!children &&
                    (themeMode !== 'dark' ? (
                        <RightArrow
                            width={16}
                            height={16}
                            style={{ transform: 'rotate(90deg)' }}
                        />
                    ) : (
                        <RightArrowDarkMode width={16} height={16} />
                    ))}
            </StyledItem>
        );
    },
);

export default NavItemFirst;
