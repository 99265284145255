import { combineReducers } from '@reduxjs/toolkit';
import meSlice from './me.slice';
import authSlice from './auth.slice';
import companySlice from './company.slice';
import chatSlice from './chat.slice';
import bankAccountsSlice from './bankAccountsSlice';
import invoiceSlice from './invoiceSlice';

const rootReducer = combineReducers({
  me: meSlice,
  auth: authSlice,
  chat: chatSlice,
  company: companySlice,
  bank: bankAccountsSlice,
  invoice:invoiceSlice,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
