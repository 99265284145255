import {
    Button,
    Checkbox,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import Trash from 'assets/icons/Trash.svg';
import { isTaxIncluded } from 'utils/helpers';

const cellStyle = {
    width: '100px',
    textAlign: 'center',
};

function ServiceItemRow({
    service,
    index,
    hover,
    isHovered,
    invoiceTolbarTax,
    setHoveredRow,
    handleRemoveService,
    onTaxCheckboxChange,
    onEditClick,
}) {
    return (
        <TableRow
            key={index}
            hover={hover}
            sx={{ position: 'relative' }}
            onMouseEnter={() =>
                typeof setHoveredRow === 'function'
                    ? setHoveredRow(index)
                    : null
            }
            onMouseLeave={() =>
                typeof setHoveredRow === 'function' ? setHoveredRow(null) : null
            }
        >
            <TableCell
                sx={{
                    cursor:
                        typeof onEditClick === 'function'
                            ? 'pointer'
                            : 'default',
                }}
                onClick={() =>
                    typeof onEditClick === 'function'
                        ? onEditClick(service)
                        : null
                }
            >
                <Typography
                    sx={{
                        fontWeight: '600',
                        fontSize: '16px',
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#F9FAFB'
                                : '#121826',
                    }}
                >
                    {service?.name}
                </Typography>
                <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {service?.details}
                </Typography>
            </TableCell>
            <TableCell sx={cellStyle}>
                <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {service?.qty}
                </Typography>
            </TableCell>
            <TableCell sx={cellStyle}>
                <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {service?.item}
                </Typography>
            </TableCell>
            <TableCell sx={cellStyle}>
                <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {service?.unitPrice}
                </Typography>
            </TableCell>
            {invoiceTolbarTax?.map((tax, taxIndex) => (
                <TableCell sx={cellStyle}>
                    <Checkbox
                        key={taxIndex}
                        size="small"
                        disableRipple={
                            typeof onTaxCheckboxChange !== 'function'
                        }
                        checked={isTaxIncluded(tax, service?.taxed)}
                        sx={{
                            cursor:
                                typeof onTaxCheckboxChange === 'function'
                                    ? 'pointer'
                                    : 'default',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onFocus={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={(e) => {
                            e.stopPropagation();
                            if (typeof onTaxCheckboxChange === 'function') {
                                onTaxCheckboxChange(
                                    index,
                                    tax,
                                    e.target.checked,
                                );
                            }
                        }}
                    />
                </TableCell>
            ))}
            <TableCell sx={cellStyle}>
                <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    ${service?.unitPrice * service?.qty}
                </Typography>
            </TableCell>
            {isHovered && (
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveService(index);
                    }}
                    sx={{
                        position: 'absolute',
                        top: '25px',
                        right: '-10px',
                        border: '1px solid #FA9999',
                        borderRadius: '20%',
                        color: '#F75555',
                        bgcolor: 'white',
                        textTransform: 'none',
                        fontSize: {
                            xs: '10px',
                            sm: '16px',
                        },
                        width: '30px',
                        height: '30px',
                        minWidth: 'unset',
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        src={Trash}
                        alt="Icon"
                        style={{
                            width: 20,
                            height: 20,
                        }}
                    />
                </Button>
            )}
        </TableRow>
    );
}

export default ServiceItemRow;
