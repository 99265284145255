import { Save } from '@mui/icons-material';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { Avatar, Box, Divider, Grid, IconButton, Paper } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as EditIcon } from '../../assets/icons/Edit.svg';
import { ReactComponent as EditIconDarkMode } from '../../assets/icons/EditDarkMode.svg';
import { useAuth } from '../../hooks/useAuth';
import { useCustomMediaQuery } from '../../hooks/useMediaQuery';
import useNotification from '../../hooks/useNotification';
import { useThemeToggle } from '../../hooks/useThemeToggle';
import { Button, PhoneNumber, TextField, Typography } from '../../shared';
import { SET_CURRENT_USER_SUCCESS } from '../../store/actions';
import { useAuthService } from '../../store/services/auth.service';
import { getRandomColor } from '../../utils/helpers';

const makeStyles = ({ isScreenLowerThan1024 }) => ({
    personalInformation: {
        p: isScreenLowerThan1024 ? '24px' : '32px',
        borderRadius: '12px',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    container: {
        position: 'relative',
        '&:hover .overlay': {
            opacity: 1,
        },
    },
    avatar: {
        width: 100,
        height: 100,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#6c727f99',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: 1,
        },
        borderRadius: '50%',
    },
    icon: {
        color: 'white',
    },
    overlayLabel: {
        cursor: 'pointer',
    },
});

export const PersonalInformation = () => {
    const [sendNotification] = useNotification();
    const {
        user: { data },
    } = useAuth();
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const { themeMode } = useThemeToggle();
    const { updateUser } = useAuthService();

    const [isEdit, setIsEdit] = useState(false);
    const [user, setUser] = useState({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
        photo_url: data.photo_url,
    });

    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const styles = makeStyles({ isScreenLowerThan1024 });

    const isImageUrlValid =
        user.photo_url &&
        user.photo_url.match(/\.(jpeg|jpg|gif|png)$/) !== null;

    const avatarBgColor = useMemo(() => {
        return isImageUrlValid ? 'unset' : getRandomColor();
    }, [isImageUrlValid]);

    const handleUserChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleUploadProfileImage = (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            // Check if the file size is less than or equal to 2MB
            if (file.size > 2 * 1024 * 1024) {
                // File size exceeds the limit
                sendNotification({
                    msg: 'File size exceeds the maximum limit of 2MB',
                    variant: 'error',
                });
                return;
            }
            const formData = new FormData();
            formData.append('photo_url', file);
            updateUser(formData)
                .then((res) => {
                    setUser(res);
                    dispatch(SET_CURRENT_USER_SUCCESS({ data: res }));
                })
                .catch((err) => console.error(err));
        } else {
            fileInputRef.current.click();
        }
    };

    const handleUpdateUser = (e) => {
        setIsEdit(!isEdit);
        updateUser({ ...user, photo_url: undefined }).then((res) => {
            dispatch(SET_CURRENT_USER_SUCCESS({ data: res }));
        });
    };
    return (
        <Grid
            item
            container
            spacing={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={styles.personalInformation}
        >
            <Grid item>
                <Typography
                    text={'Personal Information'}
                    customStyles={{
                        fontWeight: 700,
                        fontSize: 18,
                    }}
                />
                <Typography
                    text={'Here you can update your personal information.'}
                    customStyles={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: (theme) => theme.primary.dark,
                    }}
                />
            </Grid>
            <Grid item>
                {!isEdit ? (
                    <Button
                        text="Edit"
                        startIcon={
                            themeMode === 'dark' ? (
                                <EditIconDarkMode fill={'#FFF'} />
                            ) : (
                                <EditIcon fill={'#FFF'} />
                            )
                        }
                        customStyles={{
                            background: (theme) =>
                                theme.backgroundColor.primary,
                            color: (theme) => theme.primary.dark,
                            border: '1px solid #E5E6EB',
                            padding: '10px 16px',
                            '&:hover': {
                                backgroundColor: (theme) =>
                                    theme.backgroundColor.primary,
                            },
                        }}
                        onClick={() => setIsEdit(!isEdit)}
                    />
                ) : (
                    <Button
                        text="Save Changes"
                        startIcon={<Save stroke={'#FFF'} />}
                        customStyles={{
                            background: (theme) => theme.backgroundColor.light,
                            color: (theme) => theme.primary.light,
                            border: '1px solid #E5E6EB',
                            padding: '10px 16px',
                            '&:hover': {
                                backgroundColor: (theme) =>
                                    theme.backgroundColor.light,
                            },
                        }}
                        onClick={() => handleUpdateUser()}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                <Box width={'100%'}>
                    <Divider />
                </Box>
            </Grid>
            <Grid container item xs={12} alignItems={'center'} spacing={2}>
                <Grid item>
                    <Box sx={styles.container}>
                        <Avatar
                            sx={{
                                width: 100,
                                height: 100,
                                bgcolor: avatarBgColor,
                            }}
                            alt={user.last_name}
                            src={
                                isImageUrlValid
                                    ? `https://app.circler.io/${user.photo_url}`
                                    : undefined
                            }
                        >
                            {isImageUrlValid
                                ? undefined
                                : user.first_name.charAt(0)}
                        </Avatar>
                        <label htmlFor="cover_update" sx={styles.overlayLabel}>
                            <Paper sx={styles.overlay}>
                                <IconButton
                                    onClick={(e) => handleUploadProfileImage(e)}
                                >
                                    <CloudUpload sx={styles.icon} />
                                </IconButton>
                            </Paper>
                            <input
                                accept="image/*"
                                onChange={(e) => handleUploadProfileImage(e)}
                                style={{ display: 'none' }}
                                id="cover_update"
                                type="file"
                                ref={fileInputRef}
                            />
                        </label>
                    </Box>
                </Grid>
                <Grid item>
                    <Typography
                        text={`${user.first_name} ${user.last_name}`}
                        customStyles={{
                            fontWeight: 700,
                            fontSize: 20,
                        }}
                    />
                    {/* <Typography
                        text={'Product Design'}
                        customStyles={{
                            fontWeight: 400,
                            fontSize: 14,
                            color: (theme) => theme.primary.dark,
                        }}
                    /> */}
                </Grid>
            </Grid>
            <Grid container item alignItems={'center'} spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        fullWidth
                        value={user.first_name}
                        placeholder="First Name"
                        label="First Name"
                        name={'first_name'}
                        disabled={!isEdit}
                        onChange={handleUserChange}
                        customLabelStyles={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#D3D5DA'
                                    : '#4D5461',
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        fullWidth
                        disabled={!isEdit}
                        value={user.last_name}
                        name={'last_name'}
                        placeholder="Last Name"
                        label="Last Name"
                        onChange={handleUserChange}
                        customLabelStyles={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#D3D5DA'
                                    : '#4D5461',
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        fullWidth
                        disabled={true}
                        value={user.email}
                        name={'email'}
                        placeholder="Email"
                        label="Email"
                        onChange={handleUserChange}
                        customLabelStyles={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#D3D5DA'
                                    : '#4D5461',
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <PhoneNumber
                        disabled={true}
                        value={user.phone_number}
                        name="phoneNumber"
                        label="Phone Number"
                        onChange={handleUserChange}
                        customLabelStyles={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#D3D5DA'
                                    : '#4D5461',
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
