import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { fetchClientsRequest } from 'store/actions'; // Assuming this action is defined to fetch existing clients
import AddClient from '../AddClient'; // Assuming this component is already defined

const AddClientPopup = ({ open, onClose, handleSave }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const { clients } = useSelector((state) => state.invoice);
    const dispatch = useDispatch();

    useEffect(() => {
        const { token } = store.getState().auth;
        if (token) {
            dispatch(fetchClientsRequest({ token }));
        }
    }, [dispatch, activeTab]);

    const handleTabChange = useCallback((event, newValue) => {
        setActiveTab(newValue);
    }, []);

    const handleSearchChange = useCallback((event) => {
        setSearchQuery(event.target.value);
    }, []);

    const filteredClients = clients?.filter((client) =>
        client?.full_name?.toLowerCase()?.includes(searchQuery.toLowerCase()),
    );

    const [value, setValue] = useState('');
    const handleChange = useCallback((event) => {
        setValue(event.target.value);
    }, []);
    const setAdd = useCallback(() => {
        setActiveTab(0);
    }, []);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                    // padding: '16px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                },
            }}
        >
            <DialogTitle
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                Add Client Information
            </DialogTitle>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    '& .MuiTab-root': {
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#FFFFFF'
                                : '#000000',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#0061DB',
                    },
                    '& .Mui-selected': {
                        color: '#0061DB',
                    },
                }}
            >
                <Tab label="Existing Client" />
                <Tab label="Create New Client" />
            </Tabs>
            <DialogContent
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#555' : '#888',
                        borderRadius: '8px',
                        border: '2px solid transparent',
                        backgroundClip: 'content-box',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#777' : '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#2D3B4E'
                                : '#f1f1f1',
                        borderRadius: '8px',
                    },
                }}
            >
                {activeTab === 1 && (
                    <AddClient
                        setAdd={setAdd}
                        value={value}
                        handleChange={handleChange}
                    />
                )}
                {activeTab === 0 && (
                    <Box>
                        <TextField
                            fullWidth
                            placeholder="Search clients"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                mb: 2,
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#F4F4F6',
                                borderRadius: '6px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '1px solid #394150'
                                                : '1px solid #E5E6EB',
                                        borderRadius: '8px',
                                    },
                                    '&:hover fieldset': {
                                        border: '1px solid #0061DB',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: '1px solid #0061DB',
                                    },
                                },
                            }}
                        />
                        <Box
                            sx={{
                                backgroundColor: 'transparent',
                            }}
                        >
                            {filteredClients?.map((client, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        mb: 2,
                                        p: 2,
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '1px solid #394150'
                                                : '1px solid #E5E6EB',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#2D3B4E'
                                                : '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#3A4A60'
                                                    : '#F0F0F0',
                                        },
                                    }}
                                    onClick={() => handleSave(client)}
                                >
                                    <Typography
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#FFFFFF'
                                                    : '#000000',
                                        }}
                                    >
                                        {client.full_name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#9EA3AE'
                                                    : '#6C727F',
                                        }}
                                    >
                                        {client.email}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AddClientPopup;
