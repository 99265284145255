import { Button, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SELECTED_ITEM } from 'store/actions';

const TransactionTabsList = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const selectedTab = useSelector((state) => state.me.selectedItems);
    const changeTab = (tab, index) => {
        dispatch(SET_SELECTED_ITEM(tab.label));
    };
    const tabsData = [
        { label: 'Transactions' },
        { label: 'Import transactions' },
        { label: 'Bank reconciliation' },
    ];

    const getTabButtonStyle = (tabLabel) => ({
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: selectedTab === tabLabel ? '600' : '500',
        backgroundColor: selectedTab === tabLabel ? '#CEEFDF' : 'transparent',
        borderRadius: selectedTab === tabLabel ? '3px' : 'none',
        color: selectedTab === tabLabel ? '#0A8C4D' : '#6C727F',
    });

    return (
        <Stack
            display="flex"
            flexDirection="row"
            gap={1}
            width="fit-content"
            style={{
                background:
                    theme.palette.mode === 'dark' ? 'transparent' : '#F4F4F6',
                minHeight: '60px',
                borderRadius: '5px',
                padding: '1rem 2rem',
            }}
            alignItems="center"
        >
            {tabsData?.map((tab) => (
                <Grid
                    item
                    key={tab.label}
                    style={{
                        borderRadius: '5px',
                    }}
                >
                    <Button
                        onClick={() => changeTab(tab)}
                        style={getTabButtonStyle(tab.label)}
                        variant={
                            selectedTab === tab.label ? 'contained' : 'text'
                        }
                    >
                        {tab.label}
                    </Button>
                </Grid>
            ))}
        </Stack>
    );
};

export default TransactionTabsList;
