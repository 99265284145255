import { Alert, Box, Snackbar, Typography } from '@mui/material';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { store } from 'store';

import { LoadingButton } from '@mui/lab';
import {
    SET_PAYMENT_DUE,
    createPaymentIntentRequest,
    updateInvoiceRequest,
} from 'store/actions';
import { formatDateService } from 'utils/helpers';
import BankTransferForm from './PaymentDue/BankTransferForm';
import CreditCardForm from './PaymentDue/CreditCardForm';
import PaymentAmount from './PaymentDue/PaymentAmount';
import PaymentHeader from './PaymentDue/PaymentHeader';
import PaymentOptions from './PaymentDue/PaymentOptions';

function PaymentDue({
    accountType,
    selectedD,
    setSelectedD,
    onSyncAll,
    invoiceStep,
    setInvoiceStep,
    isSingle,
}) {
    const isFirstRender = useRef(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { invoiceIds } = useParams();
    const isDisabled = !invoiceIds;
    const [value, setValue] = useState('');
    const paymentDue = useSelector((state) => state.me.paymentDue);
    const stripe = useStripe();
    const elements = useElements();
    const invoiceTotals = useSelector((state) => state.invoice.invoiceTotals);
    const invoiceId = useSelector((state) => state?.invoice?.singleInvoice?.id);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
    const paymentIntents = useSelector((state) => state.invoice.paymentIntents);
    const singleInvoice = useSelector((state) => state.invoice.singleInvoice);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);

    const [creditCardData, setCreditCardData] = useState(
        paymentDue?.accountTypes === 'first'
            ? paymentDue
            : {
                  cardNumber: '',
                  expire: '',
                  cvc: '',
                  cardName: '',
                  country: '',
                  addressLine1: '',
                  addressLine2: '',
                  city: '',
                  state: '',
                  zipCode: '',
              },
    );
    const [bankTransferData, setBankTransferData] = useState(
        paymentDue?.accountTypes === 'second'
            ? paymentDue
            : {
                  accountHolderName: '',
                  accountType: '',
                  routingNumber: '',
                  accountNumber: '',
                  confirmAccountNumber: '',
              },
    );

    const handleClear = useCallback(() => {
        dispatch(SET_PAYMENT_DUE(null));
    }, [dispatch]);

    const handleSnackbarClose = useCallback(() => {
        setSnackbarOpen(false);
    }, []);

    const handleChange = useCallback((event) => {
        setValue(event.target.value);
    }, []);

    const handleCreditCardChange = useCallback((event) => {
        const { name, value } = event.target;
        setCreditCardData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }, []);

    const handleBankTransferChange = useCallback((event) => {
        const { name, value } = event.target;
        setBankTransferData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }, []);

    useEffect(() => {
        if (paymentDue?.accountType === 'first') {
            setCreditCardData(paymentDue);
        }
    }, [paymentDue]);

    useEffect(() => {
        if (selectedD) {
            const method =
                selectedD === 'first'
                    ? 'card'
                    : selectedD === 'second'
                    ? 'us_bank_account'
                    : null;
            if (method) {
                const { token } = store.getState().auth;
                const payload = {
                    invoiceId: invoiceIds || invoiceId,
                    token: token,
                    payment_method: method,
                };

                if (invoiceIds) {
                    dispatch(createPaymentIntentRequest(payload));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedD]);

    const payBill = useCallback(async () => {
        try {
            setIsPaymentLoading(true);
            if (!stripe || !elements) {
                console.error('Stripe or elements not loaded');
                setIsPaymentLoading(false);
                return;
            }

            // For card payments
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            const clientSecret = paymentIntents;

            if (!clientSecret) {
                console.error('Client secret not found');
                setSnackbarMessage('Client secret not found');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setIsPaymentLoading(false);
                return;
            }

            let paymentResult;

            if (selectedD === 'first') {
                if (
                    !cardNumberElement ||
                    !cardExpiryElement ||
                    !cardCvcElement
                ) {
                    console.error('Stripe card elements are not loaded');
                    setSnackbarMessage(
                        'Card elements are not available. Please refresh the page or try again.',
                    );
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    setIsPaymentLoading(false);
                    return;
                }

                // Manual validation
                let hasErrors = false;

                if (cardNumberElement._empty) {
                    console.error('Card number is empty');
                    setSnackbarMessage('Please enter your card number.');
                    hasErrors = true;
                } else if (cardExpiryElement._empty) {
                    console.error('Card expiry date is empty');
                    setSnackbarMessage('Please enter your card expiry date.');
                    hasErrors = true;
                } else if (cardCvcElement._empty) {
                    console.error('Card CVC is empty');
                    setSnackbarMessage('Please enter your card CVC.');
                    hasErrors = true;
                }

                if (hasErrors) {
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    setIsPaymentLoading(false);
                    return;
                }
                paymentResult = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: cardNumberElement,
                        billing_details: {
                            name: creditCardData.cardName,
                            address: {
                                country: creditCardData.country,
                                line1: creditCardData.addressLine1,
                                line2: creditCardData.addressLine2,
                                city: creditCardData.city,
                                state: creditCardData.state,
                                postal_code: creditCardData.zipCode,
                            },
                        },
                    },
                });
                if (paymentResult?.paymentIntent?.status === 'succeeded') {
                    setSnackbarMessage('Payment successful!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    setIsPaymentSuccessful(true);
                    handleClear();
                    setTimeout(() => {
                        if (!invoiceIds) {
                            navigate('/invoiceList');
                        }
                    }, 3000);
                } else {
                    setSnackbarMessage('Payment failed!');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            } else if (selectedD === 'second') {
                if (
                    !bankTransferData.accountHolderName ||
                    !bankTransferData.accountNumber ||
                    !bankTransferData.routingNumber ||
                    !bankTransferData.email
                ) {
                    console.error('Incomplete bank transfer details');
                    setSnackbarMessage(
                        'Please complete all bank transfer details.',
                    );
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    setIsPaymentLoading(false);
                    return;
                }
                const url = new URL(window.location.href);
                url.searchParams.set('success', 'true');

                paymentResult = await stripe.confirmUsBankAccountPayment(
                    clientSecret,
                    {
                        payment_method: {
                            us_bank_account: {
                                account_holder_type: 'individual',
                                account_number: bankTransferData.accountNumber,
                                routing_number: bankTransferData.routingNumber,
                            },
                            billing_details: {
                                name: bankTransferData.accountHolderName,
                            },
                        },
                        return_url: url.href,
                    },
                );
                const { error, status, paymentIntent } = paymentResult;

                if (error) {
                    console.error('Payment failed:', error);
                    setSnackbarMessage(`Payment failed! ${error.message}`);
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    setIsPaymentLoading(false);
                    return;
                }

                if (
                    paymentIntent.next_action?.verify_with_microdeposits
                        ?.hosted_verification_url
                ) {
                    window.location.href =
                        paymentIntent.next_action?.verify_with_microdeposits?.hosted_verification_url;
                }
            } else {
                console.error('Unknown payment method selected');
            }
        } catch (error) {
            console.log(
                'There was a problem with the payment operation:',
                error,
            );
            setSnackbarMessage(
                'Payment failed due to an error. Please try again.',
            );
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setIsPaymentLoading(false);
        }
    }, [
        bankTransferData?.accountHolderName,
        bankTransferData?.accountNumber,
        bankTransferData?.email,
        bankTransferData?.routingNumber,
        creditCardData?.addressLine1,
        creditCardData?.addressLine2,
        creditCardData?.cardName,
        creditCardData?.city,
        creditCardData?.country,
        creditCardData?.state,
        creditCardData?.zipCode,
        elements,
        handleClear,
        invoiceIds,
        navigate,
        paymentIntents,
        selectedD,
        stripe,
    ]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        let paymentData;
        if (selectedD === 'first') {
            paymentData = {
                ...creditCardData,
                accountTypes: selectedD,
            };
        } else if (selectedD === 'second') {
            paymentData = {
                ...bankTransferData,
                accountTypes: selectedD,
            };
        }
        dispatch(SET_PAYMENT_DUE(paymentData));
    }, [selectedD, creditCardData, bankTransferData, dispatch]);

    useEffect(() => {
        if (invoiceId && !isSingle) {
            const { token } = store.getState().auth;
            const dueDate = formatDateService(new Date());

            const payload = {
                due_date: dueDate,
                payment_method: singleInvoice?.payment_method,
                is_recurring: singleInvoice?.is_recurring,
                ...(singleInvoice?.is_recurring &&
                singleInvoice?.recurring_details
                    ? { recurring_details: singleInvoice?.recurring_details }
                    : { recurring_details: null }),
            };

            dispatch(
                updateInvoiceRequest({
                    payload,
                    token,
                    invoiceId: invoiceId,
                }),
            );
        }
    }, [
        dispatch,
        invoiceId,
        singleInvoice?.is_recurring,
        singleInvoice?.payment_method,
        singleInvoice?.recurring_details,
        isSingle,
    ]);

    return (
        <Box
            sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#212936' : 'white',
                height: '100%',
                padding: '25px',
                borderRadius: '10px',
                position: 'relative',
            }}
        >
            <PaymentHeader
                onSyncAll={onSyncAll}
                invoiceStep={invoiceStep}
                setInvoiceStep={setInvoiceStep}
                handleClear={handleClear}
            />
            <PaymentAmount amount={invoiceTotals?.totalAmount || 0} />

            {accountType === 'third' && (
                <PaymentOptions
                    selectedD={selectedD}
                    setSelectedD={setSelectedD}
                />
            )}

            <Box display="flex" flexDirection="column" gap={3} mt={3}>
                {selectedD === 'first' && (
                    <CreditCardForm
                        handleCreditCardChange={handleCreditCardChange}
                        creditCardData={creditCardData}
                    />
                )}

                {selectedD === 'second' && (
                    <BankTransferForm
                        value={value}
                        handleChange={handleChange}
                        handleBankTransferChange={handleBankTransferChange}
                        bankTransferData={bankTransferData}
                    />
                )}

                <LoadingButton
                    loading={isPaymentLoading}
                    sx={{
                        backgroundColor: isPaymentSuccessful
                            ? '#0041a3'
                            : '#0061DB',
                        color: 'white', // Text color set to white
                        borderRadius: '8px',
                        '&:hover': {
                            backgroundColor: isPaymentSuccessful
                                ? '#0041a3'
                                : '#0051bb',
                            '@media (hover: none)': {
                                backgroundColor: isPaymentSuccessful
                                    ? '#0041a3'
                                    : '#0061DB',
                            },
                        },
                        textTransform: 'none',
                        paddingY: '10px',
                    }}
                    onClick={payBill}
                    disabled={isPaymentSuccessful || isDisabled} // Disable button when payment is successful
                >
                    {isPaymentSuccessful ? (
                        <Typography color="white">Paid</Typography>
                    ) : (
                        <Typography color="white">
                            Pay ${invoiceTotals?.totalAmount || 0}
                        </Typography>
                    )}
                </LoadingButton>
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

const PaymentDueWithStripe = (props) => {
    const keyData = process.env.REACT_APP_STRIPE_INTENT;
    const stripePromise = loadStripe(keyData);

    return (
        <Elements stripe={stripePromise}>
            <PaymentDue {...props} />
        </Elements>
    );
};

export default PaymentDueWithStripe;
