import { Box, Grid, IconButton, Stack } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'store';
import { COA_FETCH_WITH_CATEGORY } from 'store/services/bank.Service';
import { ReactComponent as Check } from '../assets/icons/Check.svg';
import { ReactComponent as CheckDarkMode } from '../assets/icons/CheckDarkMode.svg';
import { ReactComponent as Close } from '../assets/icons/Close.svg';
import { ReactComponent as CloseDarkMode } from '../assets/icons/CloseDarkMode.svg';
import { ReactComponent as DownArrow } from '../assets/icons/DownArrow.svg';
import { ReactComponent as EditIcon } from '../assets/icons/Edit.svg';
import { ReactComponent as EditIconDarkMode } from '../assets/icons/EditDarkMode.svg';
import { ReactComponent as Trash } from '../assets/icons/Trash.svg';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { useNonAccountantMode } from '../hooks/useNonAccountantMode';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { Button, TextField, Typography } from '../shared';
import { useCoreService } from '../store/services/core.service';
import { useTransactionService } from '../store/services/transactions.service';
import { DATE_OPTIONS } from '../utils/constants';
import { CURRENCIES } from '../utils/data';
import {
    capitalize,
    formatAccountCategoriesData,
    updateTransactionAccount,
} from '../utils/helpers';
import NestedDropDown from './Transaction/TabsData/ImportBankTransaction/Components/ImportTransactionSelect/NestedDropDown';

const styles = {
    pendingReviews: {
        padding: '8px',
        borderRadius: '8px',
        alignSelf: 'stretch',
        alignItems: 'flex-start',
        border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        position: 'relative',
        '&:hover button': {
            display: 'flex',
        },
        margin: '0 0 10px 0px',
    },
    reviewPanel: {
        display: 'flex',
        padding: '24px',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '12px',
        background: (theme) => theme.backgroundColor.primary,
        height: '650px',
    },
    deleteReview: {
        padding: '8px',
        display: 'flex',
        justifyContent: 'center',
        border: '1px solid #FA9999',
        borderRadius: '8px',
        height: '36px',
        background: (theme) => theme.backgroundColor.primary,
    },
    editReview: {
        padding: '8px',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '8px',
        height: '36px',
        border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        background: (theme) => theme.backgroundColor.primary,
    },
    confirmReview: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1 0 0',
        borderRadius: '8px',
        background: '#00C791',
        height: '36px',
    },
    notificationMenuStyle: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        // width: '300px',
        borderBottom: (theme) => `1px solid ${theme.borderColor.inputField}`,
        background: (theme) => theme.backgroundColor.primary,
        p: 1,
        '& ul': {
            maxHeight: '500px',
            overflowY: 'auto',
            p: 0,
            m: 0,
            width: '100%',
            '& li': {
                whiteSpace: 'nowrap', // Prevents text from wrapping to the next line
                overflow: 'hidden', // Hides any text that overflows the container
                textOverflow: 'ellipsis', // Displays an ellipsis (...) when text is clipped
                '&:hover': {
                    borderRadius: '12px',
                },
            },

            '&::-webkit-scrollbar': {
                width: '0.8rem',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                margin: 1,
                borderRadius: '8px',
                backgroundColor: 'rgba(0,0,0,.1)',
            },
        },
    },
    textWrapper: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '104px',
    },
};

const PendingReviews = ({
    transaction,
    reviewEditId,
    setTransactions,
    handleChangeAmount,
    handleReviewEntryEdit,
    handleEditTransaction,
    handleResetTransactions,
    handleConfirmTransaction,
}) => {
    const { themeMode } = useThemeToggle();
    const { nonAccountantMode } = useNonAccountantMode();
    const {
        undoTransaction,
        deleteTransaction,
        updatedTransaction,
        updatedPartialTransaction,
    } = useTransactionService();

    const {
        getTransactionStatusOptions,
        getCustomerChartsOfAccountsForEdit,
        isLoading,
    } = useCoreService();
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const { data } = useSelector((state) => state.company);

    const [accounts, setAccounts] = useState([]);
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

    const setupReviewEntries = () => {
        const reviewEntries = nonAccountantMode
            ? capitalize(transaction.transaction_type) === 'Revenue'
                ? [
                      ...transaction.taxes.map((tax) => {
                          return {
                              id: tax.id,
                              accountName: `${capitalize(tax.type)}`,
                              amount: `$${tax.amount}`,
                              isTaxField: true,
                          };
                      }),
                      {
                          accountId: transaction.account.id,
                          accountName: `${capitalize(
                              transaction.account?.account_name,
                          )}`,
                          amount: `$${transaction?.transaction_total}`,
                      },
                  ]
                : [
                      {
                          accountId: transaction.account.id,
                          accountName: `${capitalize(
                              transaction.account?.account_name,
                          )}`,
                          amount: `$${transaction?.transaction_total}`,
                      },
                  ]
            : transaction?.journal_entries?.map((journalEntry) => {
                  const amount = `${
                      journalEntry.debit !== '0.00'
                          ? `$${journalEntry.debit}`
                          : `($${journalEntry.credit})`
                  }`;
                  return {
                      amount,
                      accountId: journalEntry.account.id,
                      journalEntryId: journalEntry.id,
                      accountName: `${
                          journalEntry.account.account_number
                      } ${capitalize(journalEntry.account?.account_name)}`,
                  };
              });
        return reviewEntries;
    };

    const handleNotificationClick = (
        event,
        accountId,
        transactionId,
        journalEntryId,
    ) => {
        const { id: companyId } = data;
        setNotificationAnchorEl(event.currentTarget);
        getCustomerChartsOfAccountsForEdit(
            accountId,
            companyId,
            nonAccountantMode,
        ).then((res) => {
            const formattedAccounts = res.map((account) => {
                return {
                    name: (
                        <Grid container>
                            <Typography
                                text={
                                    nonAccountantMode
                                        ? `${capitalize(account?.account_name)}`
                                        : `${
                                              account?.account_number
                                          } - ${capitalize(
                                              account?.account_name,
                                          )}`
                                }
                                sx={{
                                    flexShrink: 0,
                                    fontSize: 14,
                                    color: (theme) => theme.textColor.primary,
                                }}
                            />
                        </Grid>
                    ),
                    onClick: () => {
                        handleEditTransaction(
                            0,
                            account,
                            transactionId,
                            journalEntryId,
                            nonAccountantMode,
                            false,
                        );
                    },
                    showDivider: true,
                };
            });
            setAccounts(formattedAccounts);
        });
    };

    const handleStatusClick = (event, transactionId) => {
        const { id: companyId } = data;
        setNotificationAnchorEl(event.currentTarget);
        getTransactionStatusOptions(companyId, nonAccountantMode).then(
            (res) => {
                const formattedAccounts = res.map((account) => {
                    return {
                        name: (
                            <Grid container>
                                <Typography
                                    text={
                                        nonAccountantMode
                                            ? `${capitalize(
                                                  account?.account_name,
                                              )}`
                                            : `${
                                                  account?.account_number
                                              } - ${capitalize(
                                                  account?.account_name,
                                              )}`
                                    }
                                    sx={{
                                        flexShrink: 0,
                                        fontSize: 14,
                                        color: (theme) =>
                                            theme.textColor.primary,
                                    }}
                                />
                            </Grid>
                        ),
                        onClick: () => {
                            handleEditTransaction(
                                0,
                                account,
                                transactionId,
                                undefined,
                                nonAccountantMode,
                                true,
                            );
                        },
                        showDivider: true,
                    };
                });
                // Add a new option for "Not Paid"
                const notPaidOption = {
                    name: (
                        <Grid container>
                            <Typography
                                text="Not Paid"
                                sx={{
                                    flexShrink: 0,
                                    fontSize: 14,
                                    color: (theme) => theme.textColor.primary,
                                }}
                            />
                        </Grid>
                    ),
                    onClick: () => {
                        handleEditTransaction(
                            0,
                            { is_paid: false },
                            transactionId,
                            undefined,
                            nonAccountantMode,
                            true,
                        );
                    },
                    showDivider: true,
                };
                // Create a label for "Payment methods"
                const paymentMethodsLabel = {
                    name: (
                        <Grid container>
                            <Typography
                                text="Paid with: "
                                sx={{
                                    flexShrink: 0,
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    color: (theme) => theme.textColor.primary,
                                }}
                            />
                        </Grid>
                    ),
                    showDivider: true,
                };
                // Combine the new option and existing options
                const updatedAccounts = [
                    notPaidOption,
                    paymentMethodsLabel,
                    ...formattedAccounts,
                ];

                setAccounts(updatedAccounts);
            },
        );
    };

    const handleNotificationClose = () => {
        setNotificationAnchorEl(null);
    };

    const setupFormattedJournalEntriesPayload = (
        journalEntries,
        description,
    ) => {
        const transformedData = {
            journal_entries: journalEntries.map((entry) => ({
                account: entry.account.id,
                amount: entry.debit !== '0.00' ? entry.debit : entry.credit,
                entry_type: entry.debit !== '0.00' ? 'Debit' : 'Credit',
            })),
            description: description,
        };
        return transformedData;
    };

    const handleUpdateTransaction = () => {
        if (nonAccountantMode) {
            const formattedTransactionPayload = {
                ...transaction,
                account: transaction.account.id,
                payment_method_account: transaction?.payment_method_account?.id,
            };
            updatedTransaction(transaction.id, formattedTransactionPayload)
                .then((res) => {
                    setTransactions((prevTransactions) => {
                        let updatedTransactions = [...prevTransactions];
                        const transactionIndex = prevTransactions.findIndex(
                            (transaction) => transaction.id === res.id,
                        );
                        updatedTransactions[transactionIndex] = res;
                        return updatedTransactions;
                    });
                    handleReviewEntryEdit(-1);
                })
                .catch((err) => console.error(err));
        } else {
            updatedPartialTransaction(
                transaction.id,
                setupFormattedJournalEntriesPayload(
                    transaction.journal_entries,
                    transaction.description,
                ),
            )
                .then(handleReviewEntryEdit(-1))
                .catch((err) => console.error(err));
        }
    };

    const handleCurrencyClick = (e, transactionId) => {
        setNotificationAnchorEl(e.currentTarget);
        const formattedAccounts = CURRENCIES.map((currency) => {
            return {
                name: (
                    <Grid container>
                        <Typography
                            text={`${currency.value} - ${currency.label}`}
                            sx={{
                                flexShrink: 0,
                                fontSize: 14,
                                color: (theme) => theme.textColor.primary,
                            }}
                        />
                    </Grid>
                ),
                onClick: () => {
                    handleEditTransaction(
                        0,
                        { ...currency, isCurrencyChanged: true },
                        transactionId,
                        0,
                        nonAccountantMode,
                        false,
                    );
                },
                showDivider: true,
            };
        });
        setAccounts(formattedAccounts);
    };

    const handleUndoTransaction = (transactionId, transaction) => {
        undoTransaction(transactionId, transaction).then((res) => {
            setTransactions((prevTransactions) => {
                // Create a new array with the updated value at the specified index
                const updatedTransactions = [...prevTransactions];
                const transactionIndex = prevTransactions.findIndex(
                    (transaction) => transaction.id === res.id,
                );
                if (transactionIndex !== -1) {
                    updatedTransactions[transactionIndex] = res;
                }
                return updatedTransactions;
            });
        });
    };

    const handleDeleteTransaction = (transactionId) => {
        deleteTransaction(transactionId)
            .then((res) => {
                setTransactions((prevTransactions) => {
                    const filteredTransactions = prevTransactions.filter(
                        (transaction) => transaction.id !== transactionId,
                    );
                    return filteredTransactions;
                });
            })
            .catch((err) => console.error(err));
    };

    const [panelData, setPanelData] = useState({
        title: 'Select',
        children: [],
    });
    const [originalPanelData, setOriginalPanelData] = useState([]);
    const [isPanelDataLoading, setIsPanelDataLoading] = useState(false);
    const [completePanelData, setCompletePanelData] = useState({
        title: 'Select',
        children: [],
    });
    const [openDropDownIndex, setOpenDropDownIndex] = useState(null);

    const updateCoaAccount = useCallback(
        async (plaidId, accountId, transactionId) => {
            const account = originalPanelData?.find(
                (account) => account?.id === accountId,
            );
            setTransactions((previous) =>
                updateTransactionAccount(previous, transaction?.id, account),
            );
        },
        [originalPanelData, setTransactions, transaction?.id],
    );

    const onPanelListItemClick = useCallback(
        (accountNumber, plaidId, transactionId) => {
            setOpenDropDownIndex(null);
            updateCoaAccount(plaidId, accountNumber, transactionId);
        },
        [updateCoaAccount],
    );

    const changeDropDownLength = useCallback(() => {
        if (panelData?.children?.length === 2) {
            setPanelData({ ...completePanelData });
        } else {
            setPanelData({
                ...completePanelData,
                children: completePanelData?.children?.slice(0, 2),
            });
        }
    }, [completePanelData, panelData?.children?.length]);

    const fetchCompanyAccountsCategoryData = useCallback(async (companyId) => {
        setIsPanelDataLoading(true);
        setPanelData({
            title: 'Select',
            children: [],
        });
        setOriginalPanelData([]);
        try {
            const { token } = store.getState().auth;
            const response = await fetch(COA_FETCH_WITH_CATEGORY(companyId), {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setPanelData(formatAccountCategoriesData(data, true));
            setOriginalPanelData(data?.active_accounts || []);
            setCompletePanelData(formatAccountCategoriesData(data));
        } catch (error) {
            console.error('Error fetching dropdown options:', error);
            setPanelData({
                title: 'Select',
                children: [],
            });
        } finally {
            setIsPanelDataLoading(false);
        }
    }, []);

    const importAccountCetegories = useCallback(
        (companyId) => {
            fetchCompanyAccountsCategoryData(companyId);
        },
        [fetchCompanyAccountsCategoryData],
    );

    const handleDropDownToggle = useCallback(
        (index, depth, companyId) => {
            if (openDropDownIndex !== index && depth === 1 && companyId) {
                importAccountCetegories(companyId);
            } else if (index !== openDropDownIndex && depth === 1) {
            }
            setOpenDropDownIndex((prev) => (prev === index ? null : index));
        },
        [importAccountCetegories, openDropDownIndex],
    );
    const handleDropDownClose = useCallback(() => {
        setOpenDropDownIndex(null);
    }, []);

    return (
        <Grid container sx={styles.pendingReviews}>
            <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                height={'25px'}
            >
                <Grid item xs={4} p={'4px 8px'}>
                    <Typography
                        text={capitalize(transaction.transaction_type)}
                        customStyles={{
                            fontSize: 14,
                            fontWeight: 700,
                            color: (theme) => theme.textColor.primary,
                        }}
                    />
                </Grid>
                {!isScreenLowerThan1024 && (
                    <Grid
                        item
                        xs={8}
                        display={'flex'}
                        justifyContent={'flex-end'}
                    >
                        {reviewEditId !== -1 &&
                        reviewEditId === transaction.id ? (
                            <Grid container justifyContent={'flex-end'}>
                                <Grid item>
                                    <IconButton
                                        sx={{
                                            cursor: 'pointer',
                                            padding: '0',
                                            width: '32px',
                                            height: '38px',
                                            borderRadius: '0px 0px 0px 8px',
                                            backgroundColor: (theme) =>
                                                theme.borderColor.inputField,
                                            '&:hover': {
                                                backgroundColor: (theme) =>
                                                    theme.borderColor
                                                        .inputField,
                                            },
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleUpdateTransaction();
                                        }}
                                    >
                                        {themeMode === 'dark' ? (
                                            <CheckDarkMode />
                                        ) : (
                                            <Check />
                                        )}
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        sx={{
                                            cursor: 'pointer',
                                            padding: '0',
                                            width: '32px',
                                            height: '38px',
                                            borderRadius: '0px 8px 0px 0px',
                                            backgroundColor: (theme) =>
                                                theme.borderColor.inputField,
                                            '&:hover': {
                                                backgroundColor: (theme) =>
                                                    theme.borderColor
                                                        .inputField,
                                            },
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleResetTransactions();
                                            handleReviewEntryEdit(-1);
                                        }}
                                    >
                                        {themeMode === 'dark' ? (
                                            <CloseDarkMode />
                                        ) : (
                                            <Close fill={'#FFF'} />
                                        )}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ) : !transaction.is_reviewed ? (
                            <>
                                {/* hiding Confirm button for a single transaction  */}
                                {/* <Button
                                    variant="contained"
                                    text={
                                        <Typography
                                            text={'Confirm'}
                                            customStyles={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: (theme) =>
                                                    theme.textColor.white,
                                            }}
                                        />
                                    }
                                    color="primary"
                                    sx={{
                                        padding: '0 10px',
                                        display: 'none', // initially hide the button
                                        textAlign: 'center',
                                        background: (theme) =>
                                            theme.progress.main,
                                        borderRadius: '0px 0px 0px 8px',
                                        textTransform: 'capitalize',
                                        height: '38px',
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleConfirmTransaction([
                                            transaction.id,
                                        ]);
                                    }}
                                /> */}
                                <IconButton
                                    sx={{
                                        display: 'flex',
                                        cursor: 'pointer',
                                        padding: '0',
                                        width: '32px',
                                        height: '38px',
                                        borderRadius: '0px 0px 0px 0px',
                                        backgroundColor: (theme) =>
                                            theme.borderColor.inputField,
                                        '&:hover': {
                                            backgroundColor: (theme) =>
                                                theme.borderColor.inputField,
                                        },
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleReviewEntryEdit(transaction.id);
                                    }}
                                >
                                    {themeMode === 'dark' ? (
                                        <EditIconDarkMode />
                                    ) : (
                                        <EditIcon fill={'#FFF'} />
                                    )}
                                </IconButton>
                                <IconButton
                                    sx={{
                                        display: 'flex',
                                        cursor: 'pointer',
                                        padding: '0',
                                        width: '32px',
                                        height: '38px',
                                        borderRadius: '0px 8px 0px 0px',
                                        backgroundColor: (theme) =>
                                            theme.borderColor.inputField,
                                        '&:hover': {
                                            backgroundColor: (theme) =>
                                                theme.borderColor.inputField,
                                        },
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteTransaction(transaction.id);
                                    }}
                                >
                                    <Trash />
                                </IconButton>
                            </>
                        ) : (
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                textAlign={'right'}
                            >
                                <Typography
                                    text={
                                        <>
                                            <i>This was already reviewed.</i>
                                            <b style={{ cursor: 'pointer' }}>
                                                <u
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleUndoTransaction(
                                                            transaction.id,
                                                        );
                                                    }}
                                                >
                                                    Undo
                                                </u>
                                            </b>
                                        </>
                                    }
                                    customStyles={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: (theme) =>
                                            theme.textColor.primary,
                                    }}
                                />
                            </Box>
                        )}
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12} p={'0 6px'}>
                <Typography
                    text={new Date(
                        transaction.date + 'T00:00:00',
                    ).toLocaleDateString('en-US', DATE_OPTIONS)}
                    customStyles={{
                        fontSize: 14,
                        fontWeight: 400,
                        paddingBottom: 1,
                        color: (theme) => theme.textColor.primary,
                    }}
                />
            </Grid>
            <Grid item xs={12} p={'4px 8px'}>
                {reviewEditId === transaction.id ? (
                    <TextField
                        size="small"
                        value={transaction.description}
                        isSearchField="true"
                        variant="standard"
                        customStyle={{
                            '& .MuiInputBase-root': {
                                '& input': {
                                    fontSize: 14,
                                    color: (theme) => theme.textColor.primary,
                                    '&::placeholder': {
                                        color: (theme) =>
                                            theme.textColor.primary,
                                        opacity: 1,
                                        fontSize: 14,
                                    },
                                },
                            },
                        }}
                        onChange={(e) => {
                            e.stopPropagation();
                            handleEditTransaction(
                                0,
                                {
                                    transactionDescription: e.target.value,
                                },
                                reviewEditId,
                                0,
                                nonAccountantMode,
                                false,
                            );
                        }}
                    />
                ) : (
                    <Typography
                        text={transaction.description}
                        customStyles={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: (theme) => theme.textColor.primary,
                        }}
                    />
                )}
            </Grid>
            <Grid item xs={12} p={'4px 8px'}>
                {setupReviewEntries().map((review, index) => {
                    return (
                        <Grid
                            container
                            key={index}
                            justifyContent={'space-between'}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                xs={12}
                                display={'flex'}
                                width={isMobileScreen ? '100%' : '100%'}
                            >
                                {review.isTaxField &&
                                reviewEditId === transaction.id ? (
                                    <TextField
                                        size="small"
                                        value={review.accountName}
                                        isSearchField="true"
                                        variant="standard"
                                        customStyle={{
                                            '& .MuiInputBase-root': {
                                                '& input': {
                                                    fontSize: 14,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                    '&::placeholder': {
                                                        color: (theme) =>
                                                            theme.textColor
                                                                .primary,
                                                        opacity: 1,
                                                        fontSize: 14,
                                                    },
                                                },
                                                width: 100,
                                            },
                                        }}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            handleEditTransaction(
                                                0,
                                                {
                                                    ...review,
                                                    type: e.target.value,
                                                    amount: review.amount.replace(
                                                        '$',
                                                        '',
                                                    ),
                                                },
                                                reviewEditId,
                                                0,
                                                nonAccountantMode,
                                                false,
                                            );
                                        }}
                                    />
                                ) : // <Box
                                //     sx={{
                                //         width: '100%',
                                //         cursor: 'pointer',
                                //         padding: '8px',
                                //         ...(reviewEditId ===
                                //             transaction.id && {
                                //             '&:hover': {
                                //                 backgroundColor:
                                //                     'rgba(0, 0, 0, 0.04)',
                                //             },
                                //         }),
                                //     }}
                                //     onClick={(e) => {
                                //         if (
                                //             reviewEditId === transaction.id
                                //         ) {
                                //             e.stopPropagation();
                                //             // Your click handler logic here, for example:
                                //             handleNotificationClick(
                                //                 e,
                                //                 transaction.id,
                                //                 reviewEditId,
                                //                 review.journalEntryId,
                                //             );
                                //         }
                                //     }}
                                // >
                                //     <Typography
                                //         text={review.accountName}
                                //         sx={{
                                //             flexShrink: 0,
                                //             fontSize: 14,
                                //             color: (theme) =>
                                //                 theme.textColor.primary,
                                //             width: '100%',
                                //             ...(isMobileScreen &&
                                //                 styles.textWrapper),
                                //         }}
                                //     />
                                // </Box>
                                reviewEditId !== -1 &&
                                  reviewEditId === transaction?.id ? (
                                    <NestedDropDown
                                        item={panelData}
                                        title={
                                            transaction?.account?.account_name
                                        }
                                        plaidId={transaction?.id}
                                        companyId={transaction?.company}
                                        onItemClick={onPanelListItemClick}
                                        rowIndex={transaction?.id}
                                        transactionId={transaction?.id}
                                        changeDropDownLength={
                                            changeDropDownLength
                                        }
                                        isDropDownOpen={
                                            transaction?.id ===
                                            openDropDownIndex
                                        }
                                        handleDropDownToggle={
                                            handleDropDownToggle
                                        }
                                        isPanelDataLoading={isPanelDataLoading}
                                        handleDropDownClose={
                                            handleDropDownClose
                                        }
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <Typography
                                            text={
                                                transaction?.account
                                                    ?.account_name
                                            }
                                            sx={{
                                                flexShrink: 0,
                                                fontSize: 14,
                                                color: (theme) =>
                                                    theme.textColor.primary,
                                                width: '100%',
                                                ...(isMobileScreen &&
                                                    styles.textWrapper),
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
            {nonAccountantMode && (
                <Grid container item xs={12} p={'4px 8px'} width={'100%'}>
                    <Grid item>
                        <Typography
                            text={'Status: '}
                            customStyles={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: (theme) => theme.textColor.primary,
                            }}
                        />
                    </Grid>
                    &nbsp;
                    <Grid item display="flex">
                        <Typography
                            text={
                                transaction.payment_method_account
                                    ? `Paid - ${capitalize(
                                          transaction?.payment_method_account
                                              ?.account_name,
                                      )}`
                                    : 'Not Paid'
                            }
                            customStyles={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: (theme) => theme.textColor.primary,
                            }}
                        />
                        {reviewEditId === transaction.id && (
                            <>
                                <IconButton
                                    disableRipple
                                    sx={{
                                        cursor: 'pointer',
                                        padding: '0',
                                        '&:hover': {
                                            backgroundColor: 'unset',
                                        },
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleStatusClick(e, transaction.id);
                                    }}
                                >
                                    <DownArrow />
                                </IconButton>
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
            <Stack
                width="100%"
                display="flex"
                gap={3}
                flexDirection="row"
                alignItems="center"
                p={'4px 8px'}
            >
                <Stack
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    alignItems="center"
                >
                    <Typography
                        text={'Currency: '}
                        customStyles={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: (theme) => theme.textColor.primary,
                        }}
                    />

                    <Stack
                        display="flex"
                        flexDirection="row"
                        gap={0.5}
                        alignItems="center"
                    >
                        <Typography
                            text={transaction.currency}
                            customStyles={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: (theme) => theme.textColor.primary,
                            }}
                        />
                        {reviewEditId === transaction.id && (
                            <>
                                <IconButton
                                    disableRipple
                                    sx={{
                                        cursor: 'pointer',
                                        padding: '0',
                                        '&:hover': {
                                            backgroundColor: 'unset',
                                        },
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCurrencyClick(e, transaction.id);
                                    }}
                                >
                                    <DownArrow />
                                </IconButton>
                            </>
                        )}
                    </Stack>
                </Stack>

                {setupReviewEntries().map((review, index) => {
                    return reviewEditId === transaction.id ? (
                        <TextField
                            value={`${review.amount
                                .replace('(', '')
                                .replace(')', '')
                                .replace('$', '')}`}
                            type="number"
                            isSearchField="true"
                            variant="standard"
                            customStyle={{
                                '& .MuiInputBase-root': {
                                    '& input': {
                                        fontSize: 14,
                                        color: (theme) =>
                                            theme.textColor.primary,
                                        '&::placeholder': {
                                            color: (theme) =>
                                                theme.textColor.primary,
                                            opacity: 1,
                                            fontSize: 14,
                                        },
                                    },
                                },
                            }}
                            onChange={(e) => {
                                e.stopPropagation();
                                if (review.isTaxField) {
                                    handleEditTransaction(
                                        0,
                                        {
                                            ...review,
                                            amount: +e.target.value,
                                            type: review.accountName,
                                        },
                                        reviewEditId,
                                        0,
                                        nonAccountantMode,
                                        false,
                                    );
                                } else {
                                    handleEditTransaction(
                                        e.target.value,
                                        null,
                                        reviewEditId,
                                        review.journalEntryId,
                                        nonAccountantMode,
                                    );
                                }
                            }}
                        />
                    ) : (
                        <Typography
                            text={review.amount}
                            sx={{
                                fontSize: 14,
                                color: (theme) => theme.textColor.primary,
                            }}
                        />
                    );
                })}
            </Stack>

            {isScreenLowerThan1024 ? (
                reviewEditId !== -1 && reviewEditId === transaction.id ? (
                    <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                            <IconButton
                                sx={{
                                    cursor: 'pointer',
                                    padding: '0',
                                    width: '32px',
                                    height: '38px',
                                    borderRadius: '0px 0px 0px 8px',
                                    backgroundColor: (theme) =>
                                        theme.borderColor.inputField,
                                    '&:hover': {
                                        backgroundColor: (theme) =>
                                            theme.borderColor.inputField,
                                    },
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleUpdateTransaction();
                                }}
                            >
                                {themeMode === 'dark' ? (
                                    <CheckDarkMode />
                                ) : (
                                    <Check />
                                )}
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                sx={{
                                    cursor: 'pointer',
                                    padding: '0',
                                    width: '32px',
                                    height: '38px',
                                    borderRadius: '0px 8px 0px 0px',
                                    backgroundColor: (theme) =>
                                        theme.borderColor.inputField,
                                    '&:hover': {
                                        backgroundColor: (theme) =>
                                            theme.borderColor.inputField,
                                    },
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleResetTransactions();
                                    handleReviewEntryEdit(-1);
                                }}
                            >
                                {themeMode === 'dark' ? (
                                    <CloseDarkMode />
                                ) : (
                                    <Close fill={'#FFF'} />
                                )}
                            </IconButton>
                        </Grid>
                    </Grid>
                ) : transaction.is_reviewed ? (
                    <Box
                        width={'100%'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography
                            text={
                                <>
                                    <i>This was already reviewed.</i>
                                    <b style={{ cursor: 'pointer' }}>
                                        <u
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleUndoTransaction(
                                                    transaction.id,
                                                );
                                            }}
                                        >
                                            Undo
                                        </u>
                                    </b>
                                </>
                            }
                            customStyles={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: (theme) => theme.textColor.primary,
                            }}
                        />
                    </Box>
                ) : (
                    <Grid
                        item
                        container
                        spacing={1}
                        p={isMobileScreen ? '0' : '4px 8px'}
                        justifyContent={
                            isScreenLowerThan1024 && !isMobileScreen
                                ? 'flex-end'
                                : isMobileScreen
                                ? 'space-between'
                                : 'center'
                        }
                    >
                        <Grid item>
                            <IconButton
                                sx={styles.deleteReview}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteTransaction(transaction.id);
                                }}
                            >
                                <Trash />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                sx={styles.editReview}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleReviewEntryEdit(transaction.id);
                                }}
                            >
                                {themeMode === 'dark' ? (
                                    <EditIconDarkMode />
                                ) : (
                                    <EditIcon fill={'#FFF'} />
                                )}
                            </IconButton>
                        </Grid>
                        <Grid item>
                            {/* hiding Confirm button for a single transaction  */}
                            {/* <Button
                                sx={styles.confirmReview}
                                fullWidth={isScreenLowerThan1024}
                                text={
                                    <Typography
                                        text={'Confirm'}
                                        customStyles={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.textColor.white,
                                        }}
                                    />
                                }
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleConfirmTransaction([transaction.id]);
                                }}
                            /> */}
                        </Grid>
                    </Grid>
                )
            ) : (
                <></>
            )}

            {/* <Menu
                anchorEl={notificationAnchorEl}
                items={accounts}
                handleClose={handleNotificationClose}
                customStyles={{
                    ...styles.notificationMenuStyle,
                }}
                isLoading={isLoading}
            /> */}
        </Grid>
    );
};

export const Reviews = ({
    isHistoryFlow = false,
    transactions,
    setTransactions,
    getChatsByCompany,
    handleChangeAmount,
    handleEditTransaction,
    handleResetTransactions,
}) => {
    const boxRef = useRef(null);
    const [reviewEditId, setReviewEditId] = useState(-1);

    const { confirmTransaction, isLoading } = useTransactionService();

    useEffect(() => {
        // Scroll to the top of the Box when transactions change
        if (boxRef.current) {
            boxRef.current.scrollTop = 0; //boxRef.current.scrollHeight;
        }
    }, []);

    const handleConfirmTransaction = (transactionIds) => {
        confirmTransaction(transactionIds)
            .then((res) => {
                setTransactions((prevTransactions) => {
                    // Create a new array with the updated value at the specified index
                    let updatedTransactions = [...prevTransactions];
                    // Update items in the first array based on the second array
                    updatedTransactions = updatedTransactions.map((item) => {
                        let updatedItem = res.find(
                            (updated) => updated.id === item.id,
                        );
                        return updatedItem
                            ? { ...item, is_reviewed: updatedItem.is_reviewed }
                            : item;
                    });
                    return updatedTransactions;
                });
            })
            .catch((err) => console.error(err));
    };

    return (
        <Grid item width={'100%'}>
            <Box sx={styles.reviewPanel}>
                <Box display={'flex'} alignItems={'start'} width="100%">
                    <Typography
                        text={
                            isHistoryFlow
                                ? 'Transactions'
                                : 'Transactions For Review'
                        }
                        customStyles={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: (theme) => theme.textColor.dark,
                        }}
                    />
                </Box>
                <Box
                    ref={boxRef}
                    sx={{
                        overflow: 'auto',
                        height: 500,
                        '&::-webkit-scrollbar': {
                            width: '0.4rem',
                            marginLeft: 1,
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            margin: 1,
                            borderRadius: '8px',
                            backgroundColor: 'rgba(0,0,0,.1)',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        scrollBehavior: 'smooth', // Enable smooth scrolling
                    }}
                >
                    {transactions.length ? (
                        transactions.map((transaction, index) => {
                            return (
                                <Box
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        !isHistoryFlow &&
                                            getChatsByCompany(transaction.chat);
                                    }}
                                    sx={{
                                        ...(!isHistoryFlow && {
                                            cursor: 'pointer',
                                        }),
                                    }}
                                >
                                    <PendingReviews
                                        transaction={transaction}
                                        key={index}
                                        index={index}
                                        reviewEditId={reviewEditId}
                                        handleReviewEntryEdit={(reviewEditId) =>
                                            setReviewEditId(reviewEditId)
                                        }
                                        handleEditTransaction={
                                            handleEditTransaction
                                        }
                                        handleChangeAmount={handleChangeAmount}
                                        handleResetTransactions={
                                            handleResetTransactions
                                        }
                                        setTransactions={setTransactions}
                                        handleConfirmTransaction={
                                            handleConfirmTransaction
                                        }
                                    />
                                </Box>
                            );
                        })
                    ) : (
                        <Box
                            sx={{
                                height: 500,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography text={'No items for review.'} />
                        </Box>
                    )}
                </Box>
                <Box width={'100%'}>
                    <Button
                        text={
                            <Typography
                                text={'Post my transactions'}
                                customStyles={{
                                    fontWeight: 500,
                                    color: (theme) => theme.textColor.white,
                                }}
                            />
                        }
                        fullWidth
                        disabled={
                            !transactions.length ||
                            transactions.every(
                                (transaction) => transaction.is_reviewed,
                            )
                        }
                        loading={isLoading}
                        onClick={() =>
                            handleConfirmTransaction(
                                transactions
                                    .filter(
                                        (transaction) =>
                                            !transaction.is_reviewed,
                                    )
                                    .map((transaction) => transaction.id),
                            )
                        }
                    />
                </Box>
            </Box>
        </Grid>
    );
};
