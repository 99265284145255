import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Upgrade } from '../assets/icons/Upgrade.svg';
import { ReactComponent as UpgradeDarkMode } from '../assets/icons/UpgradeDarkMode.svg';
import { useThemeToggle } from '../hooks/useThemeToggle';

function UpgradeToPro({ sx, onlyIcon }) {
    const { themeMode } = useThemeToggle();
    const navigate = useNavigate();

    const handleNavigateToSubscriptions = () => {
        navigate('/subscriptions');
    };

    return onlyIcon ? (
        <IconButton disableRipple onClick={handleNavigateToSubscriptions}>
            <Upgrade />
        </IconButton>
    ) : (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                '& .MuiIconButton-root:hover': {
                    backgroundColor: 'unset',
                },
                ...sx,
            }}
        >
            {themeMode === 'dark' ? (
                <IconButton
                    disableRipple
                    onClick={handleNavigateToSubscriptions}
                >
                    <UpgradeDarkMode />
                </IconButton>
            ) : (
                <IconButton
                    disableRipple
                    onClick={handleNavigateToSubscriptions}
                >
                    <Upgrade />
                </IconButton>
            )}
        </Box>
    );
}

export default UpgradeToPro;
