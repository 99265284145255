import { Box, TextField, Typography, useTheme } from '@mui/material';
import Setting from 'assets/icons/setting.svg';
import SettingRed from 'assets/icons/settingRed.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setClientState } from 'store/actions';
import AddClientPopup from './AddClientPopup';

const InvoiceHeader = ({
    clientInfo,
    paramId,
    isNextButtonPressed,
    isNextButtonPressed2,
}) => {
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const textFieldRef = useRef(null);
    const theme = useTheme();
    const dispatch = useDispatch();
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });

    const invoiceType = useSelector((state) => state.me.invoiceType);
    const { singleInvoice, invoiceLogo } = useSelector(
        (state) => state.invoice,
    );

    const handleEditClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleAddClient = useCallback(() => {
        setPopupOpen(true);
    }, []);

    const handleSaveClient = useCallback(
        (selectedClient = null) => {
            if (selectedClient) {
                const updatedClientInfo = {
                    id: selectedClient?.id,
                    fullName: `${selectedClient?.full_name}\n${selectedClient?.email}\n${selectedClient?.phone_number}\n${selectedClient?.address_line_1} ${selectedClient?.city} ${selectedClient?.state} ${selectedClient?.zip_code}`,
                };
                dispatch(setClientState(updatedClientInfo));
            } else {
                dispatch(setClientState(clientInfo));
            }
            setPopupOpen(false);

            setIsEditing(false);
        },
        [clientInfo, dispatch],
    );

    const handleBlur = useCallback(() => {
        setIsEditing(false);
        dispatch(setClientState(clientInfo));
    }, [clientInfo, dispatch]);

    const handleClickOutside = useCallback(
        (event) => {
            if (
                textFieldRef.current &&
                !textFieldRef.current.contains(event.target)
            ) {
                handleBlur();
            }
        },
        [handleBlur],
    );

    useEffect(() => {
        if (isEditing) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    return (
        <Box
            sx={{
                bgcolor: theme.palette.mode === 'dark' ? '#121826' : '#F9FAFB',
                borderRadius: '10px',
                padding: '20px',
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                textAlign="center"
            >
                <Box display="flex" flexDirection="column">
                    <Typography sx={{ fontWeight: '400', fontSize: '11px' }}>
                        Invoice 1 of 2
                    </Typography>

                    <Typography
                        sx={{
                            fontWeight: '700',
                            fontSize: { xs: '16px', md: '20px' },
                        }}
                    >
                        Invoice
                    </Typography>
                </Box>

                <Box
                    p={0.5}
                    // border="1px solid #E5E6EB"
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                    borderRadius={3}
                    cursor="pointer"
                    onClick={() => navigate('/invoiceSetting')}
                >
                    <img
                        src={isNextButtonPressed2 ? SettingRed : Setting}
                        alt="Icon"
                        style={{
                            width: 18,
                            height: 18,
                            cursor: 'pointer',
                        }}
                    />
                </Box>
            </Box>

            <Box mt={4} display="flex" justifyContent="space-between">
                <Box>
                    <img
                        src={
                            singleInvoice?.brand_logo || invoiceLogo?.brand_logo
                        }
                        alt=""
                        srcSet=""
                        // width="80"
                        // height="80"
                        style={{
                            width: 'fit-content',
                            height: 'auto',
                            maxHeight: '100px',
                            maxWidth: '200px',
                        }}
                    />
                    <Typography
                        sx={{
                            color: '#6C727F',
                            marginY: '15px',
                        }}
                    >
                        Bill to
                    </Typography>

                    <Box
                        ml={2}
                        onClick={handleEditClick}
                        sx={{
                            cursor: 'pointer',
                            width: '100px',
                        }}
                    >
                        {isEditing ? (
                            <Box
                                ref={textFieldRef}
                                sx={{ width: { xs: '150px', md: '300px' } }}
                                onClick={handleAddClient}
                            >
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    variant="outlined"
                                    name="fullName"
                                    value={clientInfo.fullName || ''}
                                    onBlur={handleBlur}
                                    placeholder="Client’s full name"
                                    InputProps={{
                                        style: {
                                            backgroundColor:
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#f4f4f4',
                                            color:
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : 'black',
                                            border: 'none',
                                            padding: '10px',
                                            resize: 'none',
                                        },
                                    }}
                                    sx={{ mb: 1 }}
                                />
                                {/* <Button
                                    sx={{ mt: 1, textTransform: 'none' }}
                                    onClick={handleAddClient}
                                >
                                    {clientInfo?.fullName
                                        ? 'Change Client'
                                        : '+ Add Client'}
                                </Button> */}
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    width: '250px',
                                    backgroundColor: 'transparent',
                                    padding: '10px',
                                    borderRadius: '4px',
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                    boxSizing: 'border-box',
                                }}
                                onClick={handleAddClient}
                            >
                                <Typography
                                    sx={{
                                        color:
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : 'black',
                                        fontWeight: 500,
                                        fontSize: { md: 15, xs: 11 },
                                        ...(isNextButtonPressed && {
                                            color: 'red',
                                        }),
                                    }}
                                >
                                    {clientInfo?.fullName ||
                                        'Click here to add client information'}{' '}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box
                    display="flex"
                    sx={{
                        mt: { md: 0, xs: 6 },
                        flexDirection: {
                            md: 'row',
                            xs: 'column',
                        },
                        gap: { xs: 0, md: 6 },
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                color: '#6C727F',
                                marginBottom: '10px',
                                fontSize: { xs: '12px', md: '14px' },
                            }}
                        >
                            Invoice #
                        </Typography>
                        <Typography
                            sx={{
                                color:
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                                marginBottom: '10px',
                                fontWeight: '600',
                                fontSize: { xs: '12px', md: '14px' },
                            }}
                        >
                            {paramId}
                        </Typography>

                        <Typography
                            sx={{
                                color: '#6C727F',
                                marginBottom: '10px',
                                fontSize: { xs: '12px', md: '14px' },
                            }}
                        >
                            Date issued
                        </Typography>
                        <Typography
                            sx={{
                                color:
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                                marginBottom: '10px',
                                fontWeight: '600',
                                fontSize: { xs: '12px', md: '14px' },
                            }}
                        >
                            {formattedDate}
                        </Typography>
                    </Box>
                    <Box>
                        {/* <Typography
                            sx={{
                                color: '#6C727F',
                                marginBottom: '10px',
                                fontSize: { xs: '12px', md: '14px' },
                            }}
                        >
                            Purchase order #
                        </Typography> */}
                        {/* <Box>
                            <TextField
                                size="small"
                                multiline
                                value={purchaseOrder}
                                onChange={handlePurchaseOrderChange}
                                onBlur={handlePurchaseOrderBlur}
                                autoFocus={purchaseOrderEditing}
                                placeholder="P/Order#"
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                        '&:hover fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                        backgroundColor: purchaseOrderEditing
                                            ? (theme) =>
                                                  theme.palette.mode === 'dark'
                                                      ? '#2C3440'
                                                      : 'grey.200'
                                            : 'transparent',
                                        '&:hover': {
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#2C3440'
                                                    : 'grey.200',
                                        },
                                    },
                                    width: '100px',
                                    height: 'auto',
                                    cursor: 'pointer',
                                    marginTop: '-6px',
                                    fontWeight: '700',
                                    fontSize: { xs: '12px', md: '14px' },
                                }}
                                onClick={() => setPurchaseOrderEditing(true)}
                                onMouseLeave={handlePurchaseOrderBlur}
                            />
                        </Box> */}

                        <Typography
                            sx={{
                                color: '#6C727F',
                                marginBottom: '10px',
                                fontSize: { xs: '12px', md: '14px' },
                            }}
                        >
                            Next payment due
                        </Typography>
                        <Typography
                            sx={{
                                color:
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                                marginBottom: '10px',
                                fontWeight: '600',
                                fontSize: { xs: '12px', md: '14px' },
                            }}
                        >
                            {invoiceType === 'Recurring'
                                ? '-----'
                                : formattedDate}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <AddClientPopup
                open={popupOpen}
                onClose={() => setPopupOpen(false)}
                clientInfo={clientInfo}
                handleSave={handleSaveClient}
            />
        </Box>
    );
};
export default InvoiceHeader;
