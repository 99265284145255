import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import MyIcon3 from 'assets/icons/buildings.svg';
import MyIcon4 from 'assets/icons/call.svg';

import InvoiceSettingPreview from '../Components/InvoicePreview/InvoiceSettingPreview';
import AddClient from '../Components/AddClient';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClientRequest, fetchClientsRequest } from 'store/actions';
import { store } from 'store';
import Trash from 'assets/icons/Trash.svg';

function Client({ onSyncAll }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    let { clients } = useSelector((state) => state.invoice);
    const [add, setAdd] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(clients);

    useEffect(() => {
        const { token } = store.getState().auth;
        if (token) {
            dispatch(fetchClientsRequest({ token }));
        }
    }, [add, dispatch]);

    const [value, setValue] = useState('');

    const handleChange = useCallback((event) => {
        setValue(event.target.value);
    }, []);

    useEffect(() => {
        setFilteredData(clients);
    }, [clients]);

    const searchClients = useCallback(
        (event) => {
            const value = event?.target?.value;
            setSearchQuery(value);
            setFilteredData(
                clients?.filter((item) =>
                    item?.full_name
                        ?.toLowerCase()
                        ?.includes(value?.toLowerCase()),
                ),
            );
        },
        [clients],
    );

    const handleClientClick = useCallback(
        (clientId) => {
            if (clientId === selectedClientId) {
                setSelectedClientId(null);
            } else {
                setSelectedClientId(clientId);
            }
        },
        [selectedClientId],
    );

    const removeService = useCallback(
        (clientId) => {
            const { token } = store.getState().auth;
            dispatch(deleteClientRequest({ token, clientId }));
            setFilteredData((prev) =>
                prev?.filter((item) => item?.id !== clientId),
            );
            setSelectedClientId(null);
        },
        [dispatch],
    );

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={4}
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark'
                            ? '#121826'
                            : 'transparent',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>
                    Invoice Settings
                </Typography>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                mt={2}
                mx={4}
            >
                <Grid
                    container
                    spacing={2}
                    p={1}
                    sx={{
                        borderRadius: '12px',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                    }}
                >
                    <Grid item xs={12} md={7.5} pr={2}>
                        <Box>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: 1,
                                    width: '100%',
                                }}
                            >
                                <Box mb={3}>
                                    <Typography
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            color: '#6C727F',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Step 4 of 5
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: '700',
                                            fontSize: '18px',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#F9FAFB'
                                                    : '#121826',
                                        }}
                                    >
                                        Clients
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#9EA3AE'
                                                    : '#6C727F',
                                        }}
                                    >
                                        List of your clients. You can add new
                                        clients here.
                                    </Typography>
                                </Box>

                                {!add ? (
                                    <Button
                                        sx={{
                                            backgroundColor: '#0061DB',
                                            color: 'white',
                                            borderRadius: '8px',
                                            textTransform: 'none',
                                            paddingX: '20px',
                                            paddingY: '8px',
                                            '&:hover': {
                                                backgroundColor: '#004BB5',
                                            },
                                            width: { xs: '100%', sm: 'auto' },
                                        }}
                                        size="small"
                                        onClick={() => setAdd(true)}
                                    >
                                        + Add New Client
                                    </Button>
                                ) : null}
                            </Box>
                            <Divider />

                            {!add ? (
                                <>
                                    <Box
                                        mt={5}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="end"
                                        justifyContent="space-between"
                                        gap={4}
                                    >
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            value={searchQuery}
                                            onChange={searchClients}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: (theme) =>
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? '#394150'
                                                                : '#E5E6EB',
                                                        borderRadius: '8px',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#6C727F',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon
                                                            sx={{
                                                                color: (
                                                                    theme,
                                                                ) =>
                                                                    theme
                                                                        .palette
                                                                        .mode ===
                                                                    'dark'
                                                                        ? '#9EA3AE'
                                                                        : '#6C727F',
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        <Box
                                            sx={{
                                                height: '400px',
                                                overflowY: 'auto',
                                                mt: 2,
                                                width: '100%',
                                                '&::-webkit-scrollbar': {
                                                    width: '8px',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    backgroundColor: '#888',
                                                    borderRadius: '8px',
                                                    border: '2px solid transparent',
                                                    backgroundClip:
                                                        'content-box',
                                                },
                                                '&::-webkit-scrollbar-thumb:hover':
                                                    {
                                                        backgroundColor: '#555',
                                                    },
                                                '&::-webkit-scrollbar-track': {
                                                    backgroundColor: '#f1f1f1',
                                                    borderRadius: '8px',
                                                },
                                            }}
                                        >
                                            {filteredData?.map(
                                                (item, index) => (
                                                    <Box
                                                        key={index}
                                                        width="100%"
                                                        border={
                                                            selectedClientId ===
                                                            item?.id
                                                                ? '1px solid #394150'
                                                                : '1px solid #E5E6EB'
                                                        }
                                                        sx={{
                                                            cursor: 'pointer',
                                                            border: (theme) =>
                                                                selectedClientId ===
                                                                item?.id
                                                                    ? `1px solid ${
                                                                          theme
                                                                              .palette
                                                                              .mode ===
                                                                          'dark'
                                                                              ? '#394150'
                                                                              : '#D3D5DA'
                                                                      }`
                                                                    : `1px solid ${
                                                                          theme
                                                                              .palette
                                                                              .mode ===
                                                                          'dark'
                                                                              ? '#394150'
                                                                              : '#E5E6EB'
                                                                      }`,
                                                            backgroundColor:
                                                                selectedClientId ===
                                                                item?.id
                                                                    ? theme
                                                                          .palette
                                                                          .mode ===
                                                                      'dark'
                                                                        ? '#2C3440'
                                                                        : '#f0f0f0'
                                                                    : 'transparent',
                                                        }}
                                                        position="relative"
                                                        borderRadius="8px"
                                                        p={1}
                                                        px={2}
                                                        gap={2}
                                                        display="flex"
                                                        mb={2}
                                                        onClick={() =>
                                                            handleClientClick(
                                                                item.id,
                                                            )
                                                        }
                                                    >
                                                        {selectedClientId ===
                                                            item?.id && (
                                                            <Button
                                                                onMouseDown={(
                                                                    e,
                                                                ) =>
                                                                    e.stopPropagation()
                                                                }
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    removeService(
                                                                        selectedClientId,
                                                                    );
                                                                }}
                                                                sx={{
                                                                    position:
                                                                        'absolute',
                                                                    right: '5px',
                                                                    bottom: '5px',
                                                                    zIndex: '1000',
                                                                    border: '1px solid #FA9999',
                                                                    borderRadius:
                                                                        '20%',
                                                                    color: '#F75555',
                                                                    bgcolor:
                                                                        'white',
                                                                    textTransform:
                                                                        'none',
                                                                    fontSize: {
                                                                        xs: '10px',
                                                                        sm: '16px',
                                                                    },
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    minWidth:
                                                                        'unset',
                                                                    padding:
                                                                        '5px',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <img
                                                                    src={Trash}
                                                                    alt="Icon"
                                                                    style={{
                                                                        width: 20,
                                                                        height: 20,
                                                                    }}
                                                                />
                                                            </Button>
                                                        )}
                                                        <Avatar
                                                            alt={item.full_name}
                                                            src={item.avatar}
                                                            sx={{
                                                                width: 56,
                                                                height: 56,
                                                            }}
                                                        />
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            '500',
                                                                        fontSize:
                                                                            '14px',
                                                                        marginBottom:
                                                                            '6px',
                                                                        color: (
                                                                            theme,
                                                                        ) =>
                                                                            theme
                                                                                .palette
                                                                                .mode ===
                                                                            'dark'
                                                                                ? '#F9FAFB'
                                                                                : '#121826',
                                                                    }}
                                                                >
                                                                    {
                                                                        item.full_name
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            '400',
                                                                        fontSize:
                                                                            '12px',
                                                                        color: (
                                                                            theme,
                                                                        ) =>
                                                                            theme
                                                                                .palette
                                                                                .mode ===
                                                                            'dark'
                                                                                ? '#9EA3AE'
                                                                                : '#6C727F',
                                                                    }}
                                                                >
                                                                    {item.email}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            '400',
                                                                        fontSize:
                                                                            '14px',
                                                                        color: (
                                                                            theme,
                                                                        ) =>
                                                                            theme
                                                                                .palette
                                                                                .mode ===
                                                                            'dark'
                                                                                ? '#9EA3AE'
                                                                                : '#6C727F',
                                                                        marginBottom:
                                                                            '6px',
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            MyIcon3
                                                                        }
                                                                        alt="Icon"
                                                                        style={{
                                                                            width: 18,
                                                                            height: 18,
                                                                            marginRight:
                                                                                '6px',
                                                                        }}
                                                                    />
                                                                    {item.city}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                            '400',
                                                                        fontSize:
                                                                            '12px',
                                                                        color: (
                                                                            theme,
                                                                        ) =>
                                                                            theme
                                                                                .palette
                                                                                .mode ===
                                                                            'dark'
                                                                                ? '#9EA3AE'
                                                                                : '#6C727F',
                                                                        marginBottom:
                                                                            '6px',
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            MyIcon4
                                                                        }
                                                                        alt="Icon"
                                                                        style={{
                                                                            width: 18,
                                                                            height: 18,
                                                                            marginRight:
                                                                                '6px',
                                                                        }}
                                                                    />
                                                                    {
                                                                        item?.phone_number
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ),
                                            )}
                                        </Box>
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignItems="end"
                                        justifyContent="end"
                                        width="100%"
                                        gap={4}
                                    >
                                        <Button
                                            sx={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                color: '#6C727F',
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                                textTransform: 'none',
                                            }}
                                            onClick={() => onSyncAll('three')}
                                        >
                                            {'<-'} Previous
                                        </Button>
                                        <Button
                                            sx={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                color: '#6C727F',
                                                textTransform: 'none',
                                            }}
                                            onClick={() => onSyncAll('five')}
                                        >
                                            Skip
                                        </Button>
                                        <Button
                                            sx={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                color: '#6C727F',
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                                textTransform: 'none',
                                            }}
                                            onClick={() => onSyncAll('five')}
                                        >
                                            Next {'-->'}
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <AddClient
                                        setAdd={setAdd}
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </>
                            )}
                        </Box>
                    </Grid>

                    <InvoiceSettingPreview />
                </Grid>
            </Box>
        </>
    );
}

export default Client;
