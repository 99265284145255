import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

function PreviewPayment({ tab }) {
    const invoiceTotals = useSelector((state) => state.invoice.invoiceTotals);

    const paymentDue = useSelector((state) => state.me.paymentDue);
    const data = paymentDue;
    const getCurrentDate = useCallback(() => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }, []);

    const currentDate = getCurrentDate();

    const renderTextField = (label, value) => (
        <Box mb={2}>
            <Typography mb={1} fontSize="14px" fontWeight="500">
                {label}
            </Typography>
            <TextField
                fullWidth
                size="small"
                value={value}
                variant="outlined"
                disabled
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6',
                    borderRadius: '6px',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? 'none'
                                    : '1px solid #E5E6EB',
                            borderRadius: '8px',
                        },
                    },
                }}
            />
        </Box>
    );

    return (
        <>
            <Grid container spacing={2} mt={4} justifyContent="center">
                <Grid
                    item
                    xs={12}
                    md={tab === 0 ? 10 : 6}
                    lg={tab === 0 ? 10 : 6}
                >
                    <Box
                        sx={{
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#212936'
                                    : 'white',
                            height: '100%',
                            padding: '25px',
                            borderRadius: '10px',
                        }}
                    >
                        <Box display="flex" alignItems="center" gap={2} pb={3}>
                            <Box
                                border="1px solid #E5E6EB"
                                sx={{
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '1px solid #6C727F'
                                            : '1px solid #E5E6EB',
                                }}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius={20}
                                padding={1.5}
                            >
                                <MonetizationOnIcon />
                            </Box>
                            <Box>
                                <Typography fontWeight="600" fontSize="14px">
                                    Payment 2 of 2
                                </Typography>
                                <Typography
                                    fontWeight="400"
                                    fontSize="14px"
                                    color={(theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#6C727F'
                                            : '#6C727F'
                                    }
                                >
                                    Due: {currentDate}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />

                        <Typography fontWeight="600" fontSize="16px" mt={2}>
                            Amount due
                        </Typography>
                        <Typography fontWeight="700" fontSize="32px">
                            ${invoiceTotals?.totalAmount}
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={3}
                            mt={3}
                        >
                            {Object?.entries(data)?.map(([key, value]) =>
                                renderTextField(
                                    key
                                        .replace(/([A-Z])/g, ' $1')
                                        .replace(/^./, (str) =>
                                            str.toUpperCase(),
                                        ),
                                    value,
                                ),
                            )}

                            <Box>
                                <Typography
                                    fontSize="14px"
                                    fontWeight="500"
                                    mb={1}
                                >
                                    Enter an amount
                                </Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="0"
                                    disabled
                                    variant="outlined"
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '6px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '1px solid #E5E6EB',
                                                borderRadius: '8px',
                                            },
                                            '&:hover fieldset': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* <Grid container spacing={2} mt={1} justifyContent="center">
                <Grid
                    item
                    xs={12}
                    md={tab === 0 ? 10 : 6}
                    lg={tab === 0 ? 10 : 6}
                >
                    <Box
                        sx={{
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#212936'
                                    : 'white',
                            height: '100%',
                            padding: '25px',
                            borderRadius: '10px',
                        }}
                    >
                        <Typography ml={1.8} pb={2} sx={{ fontWeight: '800' }}>
                            PAYMENT SCHEDULE
                        </Typography>

                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow
                                        sx={{
                                            borderBottom: 'none',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                borderBottom: 'none',
                                                fontWeight: 'bold',
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : '#4D5461',
                                            }}
                                        >
                                            Amount
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                borderBottom: 'none',
                                                fontWeight: 'bold',
                                                textAlign: 'left',
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : '#4D5461',
                                            }}
                                        >
                                            Due date
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                borderBottom: 'none',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : '#4D5461',
                                            }}
                                        >
                                            Payment date
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                borderBottom: 'none',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : '#4D5461',
                                            }}
                                        >
                                            Payment ID
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                borderBottom: 'none',
                                                fontWeight: 'bold',
                                                textAlign: 'right',
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : '#4D5461',
                                            }}
                                        ></TableCell>
                                    </TableRow>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{
                                                borderBottom: 'none',
                                                borderTop: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                            }}
                                        >
                                            <TableCell
                                                sx={{
                                                    borderBottom: 'none',
                                                    borderTop: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '1px solid #394150'
                                                            : '1px solid #E5E6EB',

                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#D3D5DA'
                                                            : 'black',
                                                }}
                                            >
                                                {row.amount}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderBottom: 'none',
                                                    textAlign: 'left',
                                                    borderTop: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '1px solid #394150'
                                                            : '1px solid #E5E6EB',
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#D3D5DA'
                                                            : 'black',
                                                }}
                                            >
                                                {row.dueDate}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderBottom: 'none',
                                                    textAlign: 'center',
                                                    borderTop: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '1px solid #394150'
                                                            : '1px solid #E5E6EB',
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#D3D5DA'
                                                            : 'black',
                                                }}
                                            >
                                                {row.paymentDate}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderBottom: 'none',
                                                    textAlign: 'center',
                                                    borderTop: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '1px solid #394150'
                                                            : '1px solid #E5E6EB',
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#D3D5DA'
                                                            : 'black',
                                                }}
                                            >
                                                {row.paymentId}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    borderBottom: 'none',
                                                    textAlign: 'center',
                                                    borderTop: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '1px solid #394150'
                                                            : '1px solid #E5E6EB',
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#D3D5DA'
                                                            : 'black',
                                                }}
                                            >
                                                {row.paymentId ===
                                                'Upcoming' ? (
                                                    <Button
                                                        sx={{
                                                            color: '#6C727F',
                                                        }}
                                                        onClick={() =>
                                                            console.log(row.id)
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        sx={{
                                                            color: '#6C727F',
                                                        }}
                                                        onClick={() =>
                                                            console.log(row.id)
                                                        }
                                                    >
                                                        <MoreVertIcon />
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid> */}
        </>
    );
}

export default PreviewPayment;
