import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import { LoadingButton } from '@mui/lab';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { downloadPdf } from 'utils/helpers';

export default function DrawerComponent({
    isDrawerOpen,
    handleDrawer,
    accountName,
    title,
    isLoading,
    accounts,
    type,
    handleClick,
    onUpdate,
    isUpdateLoading,
    accounts2,
    handleBack,
    handleDownloadStatementFiles,
    isDownloadLoading,
}) {
    const theme = useTheme();

    const renderAccountTitle = (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ py: 1, pl: 2.5 }}
        >
            <Typography variant="subtitle" sx={{ flexGrow: 1 }}>
                {accountName}
            </Typography>
        </Stack>
    );

    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={() => handleDrawer(false)}
            slotProps={{
                backdrop: { invisible: true },
            }}
            sx={{
                [`& .${drawerClasses.paper}`]: {
                    ...{
                        ...{
                            backdropFilter: `blur(${20}px)`,
                            WebkitBackdropFilter: `blur(${20}px)`,
                            backgroundColor: alpha(
                                theme.palette.mode === 'dark'
                                    ? '#212936'
                                    : '#fff',
                                1,
                            ),
                        },
                        backgroundImage:
                            'url(/assets/cyan-blur.png), url(/assets/red-blur.png)',
                        backgroundRepeat: 'no-repeat, no-repeat',
                        backgroundPosition: 'top right, left bottom',
                        backgroundSize: '50%, 50%',
                    },
                    width: 400,
                },
            }}
        >
            <RenderHead
                title={title}
                type={type}
                handleBack={handleBack}
                handleDrawer={handleDrawer}
            />
            {renderAccountTitle}
            {type === 'statements' && (
                <UpdateSection
                    onUpdate={onUpdate}
                    isUpdateLoading={isDownloadLoading}
                    handleDownloadStatementFiles={handleDownloadStatementFiles}
                />
            )}

            <Divider />
            {isLoading && (
                <CircularProgress size={60} sx={{ margin: 'auto' }} />
            )}
            {isUpdateLoading && (
                <CircularProgress size={60} sx={{ margin: 'auto' }} />
            )}
            {!isLoading && !accounts?.length ? (
                <Typography variant="h6" margin="auto">
                    No Data
                </Typography>
            ) : null}
            {type === 'reconcile' && !isLoading && accounts?.length ? (
                <TabsReconcile accounts={accounts} handleClick={handleClick} />
            ) : null}
            {type === 'statements' && !isLoading && accounts?.length ? (
                <TabsStatements statements={accounts} onUpdate={onUpdate} />
            ) : null}
            {type === 'statements2' && !isUpdateLoading && accounts2?.length ? (
                <TabsStatements2 statements={accounts2} />
            ) : null}
        </Drawer>
    );
}

const TabsReconcile = ({ accounts, handleClick }) => (
    <Stack spacing={3} sx={{ overflow: 'auto' }}>
        <Table
            sx={{
                width: '100%',
                border: 'none',
            }}
        >
            <TableBody>
                {accounts?.map((statement, index) => (
                    <TableRow
                        key={index}
                        hover
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleClick(statement)}
                    >
                        <TableCell>
                            <Typography variant="body2">
                                {`Statement Date: ${statement?.statement_date}`}
                            </Typography>
                            <Typography
                                variant="caption"
                                color="text.secondary"
                            >
                                {`Reconciled at: ${format(
                                    statement?.reconciled_at,
                                    'yyyy-MM-dd hh:mm a',
                                )}`}
                            </Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Stack>
);

const TabsStatements = ({ statements, onUpdate }) => (
    <Stack spacing={3} sx={{ overflow: 'auto' }}>
        <Table
            sx={{
                width: '100%',
                border: 'none',
            }}
        >
            <TableBody>
                {statements?.map((statement, index) => (
                    <TableRow
                        key={index}
                        hover
                        onMouseEnter={() => null}
                        onMouseLeave={() => null}
                        // onClick={() => onUpdate(statement?.bank_account)}
                        sx={{ cursor: 'default' }}
                    >
                        <TableCell>
                            <Stack
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Stack>
                                    <Typography variant="body2">
                                        {`Statement: ${
                                            statement?.year
                                        }-${statement?.month
                                            .toString()
                                            .padStart(2, '0')}`}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        {`Created on: ${format(
                                            statement?.created_at,
                                            'MM/dd/yyyy',
                                        )}`}
                                    </Typography>
                                </Stack>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        downloadPdf(
                                            statement?.statement_file,
                                            `statement_${statement?.month}_${statement?.year}`,
                                        );
                                    }}
                                    sx={{
                                        minWidth: 0,
                                        padding: 0,
                                    }}
                                >
                                    <DownloadIcon />
                                </IconButton>
                            </Stack>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Stack>
);
const TabsStatements2 = ({ statements }) => (
    <Stack spacing={3} sx={{ overflow: 'auto' }}>
        <Table
            sx={{
                width: '100%',
                border: 'none',
            }}
        >
            <TableBody>
                {statements?.map((statement, index) => (
                    <TableRow
                        key={index}
                        hover
                        onMouseEnter={() => null}
                        onMouseLeave={() => null}
                    >
                        <TableCell>
                            <Stack
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Stack>
                                    <Typography variant="body2">
                                        {`Statement: ${
                                            statement?.year
                                        }-${statement?.month
                                            .toString()
                                            .padStart(2, '0')}`}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        {`Created on: ${format(
                                            statement?.created_at,
                                            'MM/dd/yyyy',
                                        )}`}
                                    </Typography>
                                </Stack>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        downloadPdf(
                                            statement?.statement_file,
                                            `statement_${statement?.month}_${statement?.year}`,
                                        );
                                    }}
                                    sx={{
                                        minWidth: 0,
                                        padding: 0,
                                    }}
                                >
                                    <DownloadIcon />
                                </IconButton>
                            </Stack>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Stack>
);

const UpdateSection = ({
    onUpdate,
    isUpdateLoading,
    handleDownloadStatementFiles,
}) => (
    <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ py: 1, pl: 2.5, pr: 2.5 }}
    >
        {/* <Typography variant="caption" color="text.secondary">
            Last updated 08/08/2024 10:08:50 AM
        </Typography> */}
        <LoadingButton
            loading={isUpdateLoading}
            variant="text"
            size="small"
            startIcon={<UpdateIcon />}
            onClick={() => handleDownloadStatementFiles()}
        >
            Sync To Bank Account
        </LoadingButton>
    </Stack>
);

const RenderHead = ({ title, handleDrawer, handleBack, type }) => (
    <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2, pr: 1, pl: 2.5, pb: 0 }}
    >
        {type === 'statements2' && (
            <IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    handleBack();
                }}
                sx={{
                    minWidth: 0,
                    padding: 0,
                }}
            >
                <ArrowBackIcon />
            </IconButton>
        )}
        <Typography variant="subtitle" sx={{ flexGrow: 1 }}>
            {title}
        </Typography>
        <IconButton onClick={() => handleDrawer(false)}>
            <CloseIcon />
        </IconButton>
    </Stack>
);
