import { Box, Divider, Typography } from '@mui/material';
import Dollar from 'assets/icons/dollar-square.svg';
import { useCallback } from 'react';

const PaymentHeader = ({
    onSyncAll,
    invoiceStep,
    setInvoiceStep,
    handleClear,
}) => {
    const getCurrentDate = useCallback(() => {
        const date = new Date();
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }, []);

    const currentDate = getCurrentDate();

    const clickBack = useCallback(() => {
        if (!invoiceStep) {
            handleClear();
            setInvoiceStep('INVOICE');
        } else {
            handleClear();
            onSyncAll('one');
        }
    }, [handleClear, invoiceStep, onSyncAll, setInvoiceStep]);
    return (
        <>
            <Typography
                sx={{
                    marginTop: -1.5,
                    marginBottom: 1,
                    fontWeight: 800,
                    cursor: 'pointer',
                }}
                onClick={clickBack}
            >
                {'<--'}
            </Typography>
            <Box display="flex" alignItems="center" gap={2} pb={3}>
                <Box
                    border="1px solid #E5E6EB"
                    sx={{
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #6C727F'
                                : '1px solid #E5E6EB',
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={20}
                    padding={1.5}
                >
                    <img
                        src={Dollar}
                        alt="Icon"
                        style={{
                            width: 24,
                            height: 24,
                        }}
                    />
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>
                        Payment 2 of 2
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: '400',
                            fontSize: '14px',
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#6C727F'
                                    : '#6C727F',
                        }}
                    >
                        Due: {currentDate}
                    </Typography>
                </Box>
            </Box>
            <Divider />
        </>
    );
};

export default PaymentHeader;
