import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export const ProtectedRoute = ({ route = true, children }) => {
    const { user } = useAuth();
    if (!user?.data) {
        // user is not authenticated
        return <Navigate to="/login" />;
    } else if (route !== 'onboarding' && !user?.data?.is_onboarded) {
        return <Navigate to="/onboarding" />;
    }
    return children;
};
