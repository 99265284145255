import { Box, Divider, Typography } from '@mui/material';
import AddTaxDetails from './AddTaxDetails';

function InvoiceShow() {
    return (
        <Box
            sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#212936' : 'white',
                borderRadius: '10px',
                textAlign: 'center',
            }}
        >
            <Typography
                sx={{
                    fontWeight: '800',
                    padding: '16px',
                    fontSize: '18px',
                }}
            >
                Invoice
            </Typography>
            <Divider />

            <AddTaxDetails />

            <Divider
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#394150' : 'white',
                }}
            />
        </Box>
    );
}

export default InvoiceShow;
