import AddIcon from '@mui/icons-material/Add';
import {
    Box,
    Button as ButtonM,
    Grid,
    Stack,
    Typography as TypographyM,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UpgradeToPro from 'shared/UpgradeToPro';
import demo_video from '../assets/video/demo_video.mp4';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import {
    AnimatedDots,
    Button,
    FullWidthTabs,
    Modal,
    NonAccountantMode,
    Typography,
} from '../shared';
import { SET_CURRENT_USER_SUCCESS } from '../store/actions';
import { useAuthService } from '../store/services/auth.service';
import { useChatService } from '../store/services/chat.service';
import { useTransactionService } from '../store/services/transactions.service';
import {
    CREATE_NEW_CHAT_ROOM_ERROR,
    CREATE_NEW_CHAT_ROOM_PENDING,
    CREATE_NEW_CHAT_ROOM_SUCCESS,
    SELECTED_CHAT_SESSION,
} from '../store/slices/chat.slice';
import Chat from './Chat';
import { Reviews } from './Reviews';

const makeStyles = ({ isScreenLowerThan1024 }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        p: isScreenLowerThan1024 ? '20px' : '25px 40px',
    },
    options: {
        borderRadius: '8px',
        border: '1px solid #E5E6EB',
        background: '#FFF',
        height: '52px',
        display: 'flex',
        justifyContent: 'center',
    },
    chatPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '12px',
        background: (theme) => theme.backgroundColor.primary,
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
});

export const Home = ({
    showCreateChatRoomButton = true,
    chatSession,
    uploadedFiles,
    transactionList = [],
    isHistoryFlow = false,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedChatSession, setSelectedChatSession] = useState(
        chatSession || [],
    );

    const { updateUserDemo } = useAuthService();
    const is_subscription_active = useSelector(
        (state) => state?.company?.data?.subscription_active,
    );
    const {
        getChats,
        createChatItems,
        createNewChatRoom,
        getChatsByCompany,
        isLoading: isChatLoading,
    } = useChatService();
    const { getPendingTransactionsForReview } = useTransactionService();

    const { data: chat } = useSelector((state) => state.chat);
    const { data: company } = useSelector((state) => state.company);
    const { data: user } = useSelector((state) => state.me);

    const [transactions, setTransactions] = useState([]);
    const [chatBoxMessage, setChatBoxMessage] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState(uploadedFiles || []);

    const [chatList, setChatList] = useState([]);
    const [selectedChatIndex, setSelectedChatIndex] = useState(-1);

    const isDemoWatchedByUser = user.is_demo;
    const originalTransactions = structuredClone(
        transactionList.length ? transactionList : transactions,
    );

    const chatSessionId =
        chat?.chatId !== -1 && chat?.chatId !== undefined
            ? chat?.chatId
            : chat?.id;

    const getChatsByChatId = (chatId) => {
        getChats(chatId).then((chats) => {
            setChatBoxMessage(chats);
        });
    };

    useEffect(() => {
        const initialMessage = {
            id: Math.random(), // Consider a more reliable ID generation for production
            sender: null,
            message:
                'Hey, How can I help you today? Drag and drop invoice files to this chat to book transactions or simply give me details of a new transaction.',
            timestamp: new Date().toISOString(),
        };
        setChatBoxMessage(
            chatSession && chatSession.length > 0
                ? [initialMessage, ...chatSession]
                : [initialMessage],
        );
        setSelectedFiles([]);
        if (!isHistoryFlow && company?.id) {
            getPendingTransactionsForReview(company?.id).then((transactions) =>
                setTransactions(transactions),
            );
        }
    }, [
        chatSession,
        getPendingTransactionsForReview,
        company?.id,
        isHistoryFlow,
    ]);

    useEffect(() => {
        if (isHistoryFlow) {
            if (transactionList?.length) {
                setTransactions(transactionList);
            } else {
                setTransactions([]);
            }
        }
    }, [transactionList, isHistoryFlow]);

    useEffect(() => {
        // Function to fetch chats by company ID
        const fetchChatsByCompany = async () => {
            if (!company?.id || selectedChatSession.length !== 0) return;

            const initialMessage = {
                id: Math.random(),
                sender: null,
                message:
                    'Hey, How can I help you today? Drag and drop invoice files to this chat to book transactions or simply give me details of a new transaction.',
                timestamp: new Date().toISOString(),
            };

            try {
                const res = await getChatsByCompany(company.id);
                setChatList(res);

                if (res && res.length > 0) {
                    // const lastChatId = res[res.length - 1].id;
                    const lastChatId = res[0].id;
                    setSelectedChatIndex(lastChatId);

                    dispatch(SELECTED_CHAT_SESSION({ chatId: lastChatId }));

                    // Fetching chat details for the last chat ID
                    const chatDetails = await getChats(lastChatId);
                    setChatBoxMessage([initialMessage, ...chatDetails]);
                } else {
                    // If no chats are found, just set the initialMessage
                    setChatBoxMessage([initialMessage]);
                }
            } catch (error) {
                console.error('Error fetching chats:', error);
            }
        };

        fetchChatsByCompany();
    }, [company?.id, selectedChatSession.length]);

    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const styles = makeStyles({
        isScreenLowerThan1024,
    });

    const handleCreateNewChatRoom = () => {
        const { id: companyId } = company;
        dispatch(CREATE_NEW_CHAT_ROOM_PENDING({ loading: true }));
        createNewChatRoom(companyId)
            .then((res) => {
                setChatBoxMessage([
                    {
                        id: Math.random(),
                        sender: null,
                        message:
                            'Hey, How can I help you today? Drag and drop invoice files to this chat to book transactions or simply give me details of a new transaction.',
                        files: [],
                        timestamp: new Date().toISOString(),
                    },
                ]);
                setSelectedFiles([]);
                setTransactions([]);
                setSelectedChatIndex(res.id);
                dispatch(CREATE_NEW_CHAT_ROOM_SUCCESS({ data: res }));
            })
            .catch((err) => dispatch(CREATE_NEW_CHAT_ROOM_ERROR({ err })));
    };

    const handleSendMessage = (selectedFiles, userInput, transactionType) => {
        const formData = new FormData();
        formData.append('type', transactionType);
        if (selectedFiles.length) {
            setChatBoxMessage((prev) => {
                return [
                    ...prev,
                    {
                        id: '0001',
                        sender: null,
                        message: (
                            <span>
                                Circler is working on your transactions{' '}
                                <AnimatedDots />
                            </span>
                        ),
                        timestamp: new Date().toISOString(),
                    },
                ];
            });
            selectedFiles.forEach((file) => formData.append('file', file));
        }
        if (userInput) {
            formData.append('message', userInput);
        }

        const companyId = company?.id;
        // if (!companyId) {
        //     console.error("Company ID is missing");
        //     return;
        // }

        createChatItems(companyId, chatSessionId, formData)
            .then((res) => {
                setChatBoxMessage((prev) => {
                    const filteredMessages = prev.filter(
                        (message) => message.id !== '0001',
                    );
                    return [...filteredMessages, res.chatbot_message];
                });
                getPendingTransactionsForReview(company?.id).then(
                    (transactions) => setTransactions(transactions),
                );
                setSelectedFiles([]);
            })
            .catch((err) => {
                if (err.redirectPath) {
                    navigate(err.redirectPath);
                }

                setChatBoxMessage((prev) => {
                    const filteredMessages = prev.filter(
                        (message) => message.id !== '0001',
                    );
                    return filteredMessages;
                });
            });
    };

    const handleCloseModal = () => {
        updateUserDemo({ is_demo: true }).then((res) => {
            //TODO: Need to test this
            dispatch(SET_CURRENT_USER_SUCCESS({ data: res }));
        });
    };

    const handleResetTransactions = () => {
        setTransactions(originalTransactions);
    };

    const handleEditTransaction = (
        amount,
        account,
        transactionId,
        journalEntryId,
        nonAccountantMode,
        isStatusChanged = false,
    ) => {
        setTransactions((prevTransactions) => {
            // Find the index of the transaction in the transactions array
            const transactionIndex = prevTransactions.findIndex(
                (r) => r.id === transactionId,
            );

            if (transactionIndex !== -1) {
                // Get the transaction object
                const transaction = { ...prevTransactions[transactionIndex] };

                if (nonAccountantMode) {
                    let updatedTransaction = { ...transaction };
                    if (account) {
                        if (isStatusChanged) {
                            updatedTransaction.is_paid =
                                account.is_paid ?? true;

                            //User has selected a valid payment option
                            if (account.id) {
                                updatedTransaction.payment_method_account = {
                                    account_name: account?.account_name,
                                    account_number: account?.account_number,
                                    id: account?.id,
                                };
                            } else {
                                updatedTransaction.payment_method_account =
                                    null;
                            }
                        } else if (account.isTaxField) {
                            const taxIndex = updatedTransaction.taxes.findIndex(
                                (tax) => tax.id === account.id,
                            );

                            if (taxIndex !== -1) {
                                const updatedTransactionCopy = {
                                    ...updatedTransaction,
                                }; // Create a shallow copy of the entire object
                                const prevTransactionTaxes = [
                                    ...updatedTransactionCopy.taxes,
                                ]; // Create a shallow copy of the taxes array
                                prevTransactionTaxes[taxIndex] = account; // Update the specific item in the copied array
                                updatedTransactionCopy.taxes =
                                    prevTransactionTaxes; // Update the taxes array in the copied object
                                updatedTransaction = {
                                    ...updatedTransactionCopy,
                                };
                                // const prevTransactionTaxes = {
                                //     ...updatedTransaction[taxIndex],
                                // };
                                // debugger;
                                // updatedTransaction.taxes[taxIndex] = account;
                            }
                        } else if (account?.isCurrencyChanged) {
                            updatedTransaction.currency = account.value;
                        } else if (account?.transactionDescription) {
                            updatedTransaction.description =
                                account.transactionDescription;
                        } else {
                            updatedTransaction.account = {
                                account_name: account?.account_name,
                                account_number: account?.account_number,
                                id: account?.id,
                            };
                        }
                    } else {
                        updatedTransaction.transaction_total = amount;
                    }
                    const newTransactions = [...prevTransactions];
                    newTransactions[transactionIndex] = updatedTransaction;
                    return newTransactions;
                } else {
                    // Create a new transaction object with updated journal_entries
                    let updatedTransaction = {
                        ...transaction,
                    };

                    //TODO Need to refactor this code
                    if (account?.isCurrencyChanged) {
                        updatedTransaction.currency = account.value;
                    }
                    if (account?.transactionDescription) {
                        updatedTransaction.description =
                            account.transactionDescription;
                    }
                    // Update the properties of the transaction as needed
                    if (
                        transaction.journal_entries &&
                        transaction.journal_entries.length > 0
                    ) {
                        const updatedJournalEntries =
                            transaction.journal_entries.map(
                                (entry, entryIndex) => {
                                    // Update the first journal entry's description
                                    if (entry.id === journalEntryId) {
                                        if (account) {
                                            return {
                                                ...entry,
                                                account: {
                                                    account_name:
                                                        account?.account_name,
                                                    account_number:
                                                        account?.account_number,
                                                    id: account.id,
                                                },
                                            };
                                        } else if (amount) {
                                            if (entry.debit !== '0.00') {
                                                entry.debit = amount;
                                            } else {
                                                entry.credit = amount;
                                            }
                                            return entry;
                                        }
                                    }
                                    return entry;
                                },
                            );
                        updatedTransaction = {
                            ...updatedTransaction,
                            journal_entries: updatedJournalEntries,
                        };
                        // Create a new transactions array with the updated transaction
                        const newTransactions = [...prevTransactions];
                        newTransactions[transactionIndex] = updatedTransaction;

                        return newTransactions;
                    }
                }
            }

            // If the transaction is not found, return the current state
            return prevTransactions;
        });
    };

    const tabs = [
        {
            label: 'Circler Bookkeeper',
            content: (
                <Chat
                    isLoading={isChatLoading}
                    chatBoxMessage={chatBoxMessage}
                    handleSendMessage={handleSendMessage}
                    setChatBoxMessage={setChatBoxMessage}
                    setSelectedFiles={setSelectedFiles}
                    selectedFiles={selectedFiles}
                />
            ),
        },
        {
            label: 'Transactions for Review',
            content: (
                <Reviews
                    isHistoryFlow={isHistoryFlow}
                    transactions={transactions}
                    handleEditTransaction={handleEditTransaction}
                    handleResetTransactions={handleResetTransactions}
                    setTransactions={setTransactions}
                    getChatsByCompany={getChatsByChatId}
                />
            ),
        },
    ];

    return (
        <>
            <Box
                sx={{
                    ...styles?.root,
                    filter: !is_subscription_active ? 'blur(2px)' : 'none',
                    pointerEvents: !is_subscription_active ? 'none' : 'auto',
                    userSelect: !is_subscription_active ? 'none' : 'auto',
                }}
                tabIndex={!is_subscription_active ? -1 : 0}
                onKeyDown={(e) => {
                    if (!is_subscription_active) {
                        e.preventDefault();
                    }
                }}
            >
                {selectedChatIndex !== -1 ? (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            container
                            spacing={2}
                            justifyContent={'space-between'}
                        >
                            {showCreateChatRoomButton && (
                                <Grid
                                    item
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        text={'New Chat'}
                                        variant="h5"
                                        customStyles={{
                                            fontWeight: '700',
                                            fontSize: '20px',
                                            lineHeight: '24px',
                                        }}
                                    />
                                </Grid>
                            )}
                            {/* 
                        <Grid item display={'flex'} alignItems={'center'}>
                            <ButtonM
                                onClick={() => null}
                                sx={{
                                    border: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '1px solid #394150'
                                            : '1px solid #E5E6EB',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#9EA3AE'
                                            : '#6C727F',
                                    borderRadius: '8px',
                                    outline: 'none',
                                    textTransform: 'none',
                                }}
                            >
                                <TypographyM variant="subtitle">
                                    Chat History
                                </TypographyM>
                            </ButtonM>
                        </Grid> */}
                            {showCreateChatRoomButton && <Box flexGrow={1} />}

                            {showCreateChatRoomButton && (
                                <>
                                    <Grid
                                        item
                                        display={'flex'}
                                        alignItems={'center'}
                                    >
                                        <ButtonM
                                            onClick={() => navigate('/history')}
                                            sx={{
                                                background: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#212936'
                                                        : '#fff',
                                                height: '48px',
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '1px solid #394150'
                                                        : '1px solid #E5E6EB',
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#9EA3AE'
                                                        : '#6C727F',
                                                borderRadius: '8px',
                                                outline: 'none',
                                                textTransform: 'none',
                                            }}
                                        >
                                            <TypographyM variant="subtitle">
                                                Chat History
                                            </TypographyM>
                                        </ButtonM>
                                    </Grid>
                                    <Grid
                                        item
                                        display={'flex'}
                                        alignItems={'center'}
                                    >
                                        <Button
                                            customStyles={{
                                                color: '#fff',
                                                height: '48px',
                                                '& .MuiButton-startIcon': {
                                                    mr: isScreenLowerThan1024
                                                        ? '0px'
                                                        : '8px',
                                                },
                                            }}
                                            text={
                                                isScreenLowerThan1024 ? (
                                                    ''
                                                ) : (
                                                    <Typography
                                                        text={
                                                            'Create New Chat Room'
                                                        }
                                                        customStyles={{
                                                            fontSize: 16,
                                                            fontWeight: 500,
                                                            color: '#fff',
                                                        }}
                                                    />
                                                )
                                            }
                                            fullWidth
                                            loading={isChatLoading}
                                            startIcon={<AddIcon />}
                                            onClick={() =>
                                                handleCreateNewChatRoom()
                                            }
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        {isScreenLowerThan1024 ? (
                            isDemoWatchedByUser ? (
                                <>
                                    <Grid
                                        item
                                        display={'flex'}
                                        xs={12}
                                        alignItems={'center'}
                                    >
                                        {
                                            <NonAccountantMode
                                                label={'Non-accountant Mode'}
                                            />
                                        }
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display={'flex'}
                                        alignItems={'center'}
                                    >
                                        <FullWidthTabs
                                            tabs={tabs}
                                            variant="fullWidth"
                                            tabCustomStyles={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                textTransform: 'capitalize',
                                            }}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    width={'100%'}
                                    height={'calc(100vh - 300px)'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                >
                                    <Typography
                                        text={
                                            'Please create a new session by clicking the button on top right'
                                        }
                                        customStyles={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                        }}
                                    />
                                </Grid>
                            )
                        ) : isDemoWatchedByUser ? (
                            <>
                                <Grid item xs={12} md={8} lg={8} width={'100%'}>
                                    <Chat
                                        isLoading={isChatLoading}
                                        selectedFiles={selectedFiles}
                                        chatBoxMessage={chatBoxMessage}
                                        setSelectedFiles={setSelectedFiles}
                                        handleSendMessage={handleSendMessage}
                                        setChatBoxMessage={setChatBoxMessage}
                                    />
                                </Grid>

                                <Grid item width={'100%'} xs={12} md={4} lg={4}>
                                    <Box sx={styles.chatPanel}>
                                        <Reviews
                                            isHistoryFlow={isHistoryFlow}
                                            transactions={transactions}
                                            handleEditTransaction={
                                                handleEditTransaction
                                            }
                                            handleResetTransactions={
                                                handleResetTransactions
                                            }
                                            setTransactions={setTransactions}
                                            getChatsByCompany={getChatsByChatId}
                                        />
                                    </Box>
                                </Grid>
                            </>
                        ) : (
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                width={'100%'}
                                height={'calc(100vh - 300px)'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <Typography
                                    text={
                                        'Please create a new session by clicking the button on top right'
                                    }
                                    customStyles={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Grid
                        container
                        height={'calc(100vh - 270px)'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Grid item display={'flex'} alignItems={'center'}>
                            <Button
                                customStyles={{
                                    color: '#fff',
                                    height: '48px',
                                    '& .MuiButton-startIcon': {
                                        mr: isScreenLowerThan1024
                                            ? '0px'
                                            : '8px',
                                    },
                                }}
                                text={
                                    <Typography
                                        text={'Create New Chat Room'}
                                        customStyles={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: '#fff',
                                        }}
                                    />
                                }
                                fullWidth
                                loading={isChatLoading}
                                startIcon={<AddIcon />}
                                onClick={() => handleCreateNewChatRoom()}
                            />
                        </Grid>
                    </Grid>
                )}
                <Modal
                    title="Demo"
                    open={!isDemoWatchedByUser}
                    handleClose={handleCloseModal}
                    fullWidth={true}
                    maxWidth={'xl'}
                >
                    <Box
                        p={1}
                        sx={{
                            position: 'relative',
                        }}
                    >
                        <ReactPlayer
                            className={styles.reactPlayer}
                            url={demo_video}
                            width="100%"
                            height="100%"
                            controls={true}
                            playing={!isDemoWatchedByUser}
                        />
                    </Box>
                </Modal>
            </Box>

            {!is_subscription_active && (
                <Stack
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        pointerEvents: 'auto',
                    }}
                >
                    <UpgradeToPro onlyIcon />
                </Stack>
            )}
        </>
    );
};
