import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography as MuiTypography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as SendMessageIcon } from '../../assets/icons/Send.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/Upload.svg';
import { ReactComponent as UploadIconDarkMode } from '../../assets/icons/UploadDarkMode.svg';
import { useCustomMediaQuery } from '../../hooks/useMediaQuery';
import { useThemeToggle } from '../../hooks/useThemeToggle';
import { TextField, Typography } from '../../shared';

const styles = {
    chatInput: {
        padding: '16px 16px',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        borderRadius: '12px 12px 0 0',
    },
    chatUpload: {
        cursor: 'pointer',
        display: 'flex',
        padding: '12px 10px 12px 16px',
        alignItems: 'center',
        alignSelf: 'stretch',
        background: (theme) => theme.borderColor.inputField,
        borderRadius: '0 0 12px 12px',
        justifyContent: 'space-between',
    },
    uploadDetails: {
        width: '100%',
        padding: 2,
        borderRadius: '12px',
        border: (theme) => `1px solid ${theme.borderColor.inputField}`,
        background: (theme) => theme.backgroundColor.primary,
        m: '10px 0px',
    },
    textWrapper: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};

export const ChatInput = ({
    isLoading,
    selectedFiles,
    setSelectedFiles,
    handleSendMessage,
    openFileUploadModal,
}) => {
    const { themeMode } = useThemeToggle();
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width:1025px)');

    const [userInput, setUserInput] = useState('');

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (
                event?.key === 'Enter' &&
                !isLoading &&
                userInput?.trim() !== ''
            ) {
                handleSendMessage(userInput);
                setUserInput('');
            }
        };
        document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, userInput]);

    return (
        <Grid container py={1}>
            {/* {selectedFiles.length > 0 && (
                <Grid container item xs={12} sx={styles.uploadDetails}>
                    <Grid
                        item
                        container
                        xs={10}
                        md={8}
                        lg={8}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <Grid item>
                            <FileUpload height="40px" />
                        </Grid>
                        <Grid item ml={1}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    maxWidth: isScreenLowerThan1024
                                        ? isMobileScreen
                                            ? '140px'
                                            : '400px'
                                        : '600px',
                                    overflow: 'hidden',
                                }}
                            >
                                <Typography
                                    text={`You have uploaded ${selectedFiles.length} file(s)`}
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        ...styles.textWrapper,
                                    }}
                                />
                                <Typography
                                    text={formatBytes(selectedFiles.size)}
                                    sx={{
                                        color: (theme) =>
                                            theme.textColor.primary,
                                        fontSize: 14,
                                        fontWeight: 400,
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        md={4}
                        lg={4}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <IconButton onClick={() => setSelectedFiles([])}>
                            <Trash />
                        </IconButton>
                    </Grid>
                </Grid>
            )} */}
            <Grid item xs={12}>
                <Box sx={styles.chatInput}>
                    <TextField
                        placeholder="Ask or search anything"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                        }}
                        customStyle={{
                            '& .MuiInputBase-root': {
                                background: (theme) =>
                                    theme.backgroundColor.primary,
                                color: (theme) => theme.primary.dark,
                            },
                        }}
                        value={userInput}
                        onChange={(e) => {
                            setUserInput(e.target.value);
                        }}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid item container sx={styles.chatUpload}>
                    <Grid
                        container
                        item
                        xs={isMobileScreen ? 2 : 6}
                        onClick={openFileUploadModal}
                        justifyContent={'space-between'}
                    >
                        <Grid
                            item
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            p={'0 !important'}
                        >
                            {themeMode === 'dark' ? (
                                <UploadIconDarkMode />
                            ) : (
                                <UploadIcon />
                            )}
                        </Grid>
                        {!isMobileScreen && (
                            <Grid
                                item
                                xs={isMobileScreen ? 9 : 11}
                                display={'flex'}
                                alignItems={'start'}
                                p={'0 !important'}
                            >
                                <Typography
                                    text="Upload File"
                                    customStyles={{
                                        fontWeight: 500,
                                        fontSize: 14,
                                        color: (theme) => theme.primary.dark,
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container xs={6}>
                        <Grid
                            item
                            xs={12}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                        >
                            <LoadingButton
                                startIcon={
                                    !isLoading ? <SendMessageIcon /> : null
                                }
                                sx={{
                                    background: (theme) =>
                                        theme.backgroundColor.primary,
                                    color: '#6C727F',
                                    padding: '10px 16px',
                                }}
                                loading={isLoading}
                                onClick={() => {
                                    if (userInput?.trim() !== '') {
                                        handleSendMessage(userInput);
                                        setUserInput('');
                                    }
                                }}
                            >
                                <MuiTypography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: (theme) =>
                                            theme.textColor.primary,
                                        visibility: isLoading
                                            ? 'hidden'
                                            : 'visible',
                                    }}
                                >
                                    Send Message
                                </MuiTypography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
