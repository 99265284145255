import React, { useCallback, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import { Avatar, Badge, Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { ReactComponent as CirclerIcon } from '../../assets/icons/Icon.svg';
import { ReactComponent as Attachments } from '../../assets/icons/Attachments.svg';
import { ReactComponent as UploadPreview } from '../../assets/icons/UploadPreview.svg';
import { ReactComponent as CirclerIconDarkMode } from '../../assets/icons/CirclerIconDarkMode.svg';
import { Typography } from '../../shared';
import { useDropzone } from 'react-dropzone';
import { useCustomMediaQuery } from '../../hooks/useMediaQuery';
import { useChatService } from '../../store/services/chat.service';

const makeStyles = ({ isMobileScreen }) => ({
    circlerChatBox: {
        borderRadius: '0px 12px 12px 12px',
        background: (theme) => theme.senderChat.primary,
        display: 'flex',
        padding: '12px',
        alignItems: 'center',
        height: 'auto',
    },

    receiverChatBox: {
        borderRadius: '12px 0px 12px 12px',
        background: (theme) => theme.receiverChat.primary,
        display: 'flex',
        padding: '12px',
        alignItems: 'center',
        height: 'auto',
    },
    chatText: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        maxWidth: '300px',
        wordWrap: 'break-word', // Correct property for word wrap
        wordBreak: 'break-word', // Allow breaking of long words
        overflow: 'hidden', // Prevent overflow issues
        padding: '10px',
        color: (theme) => theme.textColor.dark,
    },
    attachmentStyle: {
        display: 'flex',
        padding: '10px 16px',
        alignItems: 'center',
        flex: '1 0 0',
        borderRadius: '8px 0px 8px 8px',
        border: '1px solid #0061DB',
        background: (theme) => theme.backgroundColor.primary,
        maxWidth: '275px',
        m: 1,
        marginRight: isMobileScreen ? '0' : '63px',
        cursor: 'pointer',
    },
    textWrapper: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
    },
});

const ChatItem = ({
    id,
    files,
    sender,
    message,
    imageURL,
    isLoading,
    isDarkMode,
    timestamp,
    setSelectedFiles,
}) => {
    const { getFileAccess } = useChatService();
    const { data: company } = useSelector((state) => state.company);
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const styles = makeStyles({ isMobileScreen, message });
    // Parse the ISO date string into a Date object
    const dateObject = new Date(timestamp);

    // Format the Date object to extract the time
    const timeString = format(dateObject, 'hh:mm a');

    const handleViewFile = (fileUrl) => {
        getFileAccess(fileUrl, company.id)
            .then((res) => {
                window.open(res.signedUrl);
            })
            .catch((err) => {});
    };

    return (
        <Grid container justifyContent={'space-between'}>
            <Grid
                key={id}
                p={1}
                item
                xs={12}
                container
                spacing={2}
                justifyContent={!sender ? 'flex-start' : 'flex-end'}
            >
                {sender && (
                    <>
                        {message && (
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: isMobileScreen
                                            ? 'center'
                                            : 'flex-end',
                                    }}
                                    xs={11}
                                >
                                    <Box
                                        display={'flex'}
                                        alignItems={'flex-start'}
                                        height={'100%'}
                                    >
                                        <Typography
                                            text={timeString}
                                            customStyles={{
                                                ...styles.chatText,
                                                fontSize: '12px',
                                                color: (theme) =>
                                                    theme.textColor.light,
                                                p: 1,
                                            }}
                                        />
                                    </Box>

                                    <Box sx={styles.receiverChatBox}>
                                        <Typography
                                            text={message}
                                            customStyles={{
                                                ...styles.chatText,
                                                color: (theme) =>
                                                    theme.receiverChat.color,
                                                p: 0,
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        cursor: 'pointer',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}
                                    xs={1}
                                >
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        variant="dot"
                                        color="success"
                                        sx={{
                                            '& .MuiBadge-dot': {
                                                backgroundColor: '#0CAF60',
                                            },
                                        }}
                                    >
                                        {sender === null ? (
                                            isDarkMode ? (
                                                <CirclerIconDarkMode />
                                            ) : (
                                                imageURL || <CirclerIcon />
                                            )
                                        ) : (
                                            <Avatar
                                                atl="image"
                                                src={imageURL}
                                            />
                                        )}
                                    </Badge>
                                </Grid>
                            </Grid>
                        )}
                        <Grid
                            item
                            width={'100%'}
                            container
                            spacing={2}
                            xs={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                paddingTop: '0px !important',
                                margin: '24px 0',
                            }}
                            justifyContent={'flex-end'}
                            flexDirection={'column'}
                        >
                            {files &&
                                files.map((attachment) => {
                                    return (
                                        <Grid
                                            item
                                            sx={styles.attachmentStyle}
                                            onClick={() => {
                                                attachment.file_url &&
                                                    handleViewFile(
                                                        attachment.file_url,
                                                    );
                                            }}
                                            key={attachment.file_url}
                                        >
                                            {attachment.file_url && (
                                                <>
                                                    <Attachments />
                                                    &nbsp;
                                                    <Typography
                                                        text={attachment.file_url.substring(
                                                            attachment.file_url.lastIndexOf(
                                                                '/',
                                                            ) + 1,
                                                        )}
                                                        customStyles={{
                                                            p: 0,
                                                            fontWeight: 500,
                                                            fontSize: '14px',
                                                            color: (theme) =>
                                                                theme.textColor
                                                                    .dark,
                                                            ...styles.textWrapper,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </>
                )}

                {sender === null && (
                    <Grid
                        item
                        container
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Grid
                            item
                            xs={1}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                variant="dot"
                                color="success"
                                sx={{
                                    '& .MuiBadge-dot': {
                                        backgroundColor: '#0CAF60',
                                    },
                                }}
                            >
                                {sender === null ? (
                                    isDarkMode ? (
                                        <CirclerIconDarkMode />
                                    ) : (
                                        imageURL || <CirclerIcon />
                                    )
                                ) : (
                                    <Avatar atl="image" src={imageURL} />
                                )}
                            </Badge>
                        </Grid>
                        &nbsp;
                        <Grid item sx={styles.circlerChatBox}>
                            <Typography
                                text={message}
                                customStyles={{
                                    ...styles.chatText,
                                    color: (theme) => theme.senderChat.color,
                                    p: 0,
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            display={'flex'}
                            alignItems={'flex-start'}
                            height={'100%'}
                        >
                            <Typography
                                text={timeString}
                                customStyles={{
                                    ...styles.chatText,
                                    fontSize: '12px',
                                    color: (theme) => theme.textColor.light,
                                    p: 1,
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export const ChatBox = ({
    isLoading,
    isDarkMode,
    chatBoxMessage,
    setSelectedFiles,
    allowedImage = true,
}) => {
    const handleDrop = useCallback(
        (acceptedFiles) => {
            // Handle the dropped files here
            setSelectedFiles(acceptedFiles);
        },
        [setSelectedFiles],
    );

    const { getRootProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        noClick: true,
        ...(allowedImage && {
            accept: {
                'application/file': [
                    '.jpeg',
                    '.png',
                    '.jpg',
                    '.gif',
                    '.pdf',
                    '.svg',
                ],
            },
        }),
        ...(allowedImage && { maxFiles: 5 }),
    });

    const messagesEndRef = useRef(null);
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatBoxMessage]);

    return (
        <Grid container {...getRootProps()}>
            {isDragActive ? (
                <Grid
                    item
                    py={1}
                    display={'flex'}
                    justifyContent={'center'}
                    width={'100%'}
                >
                    <UploadPreview height={380} />
                </Grid>
            ) : (
                <Grid
                    item
                    py={5}
                    width={'100%'}
                    height={'300px'}
                    overflow={'auto'}
                    sx={{
                        '&::-webkit-scrollbar': {
                            width: '0.4rem',
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            margin: 1,
                            borderRadius: '8px',
                            backgroundColor: 'rgba(0,0,0,.1)',
                        },
                    }}
                >
                    {chatBoxMessage.map(({ id, ...rest }) => (
                        <ChatItem
                            key={id}
                            isLoading={isLoading}
                            isDarkMode={isDarkMode}
                            setSelectedFiles={setSelectedFiles}
                            {...rest}
                        />
                    ))}
                    <div ref={messagesEndRef} />
                </Grid>
            )}
        </Grid>
    );
};
