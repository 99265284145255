import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    accounts: [],
    linkToken: null,
    transactions: [],
    unimportedTransactions: [],
    unmatchedTransactions: [],
    MatchedTransactions: [],
    matchedTransactions: [],
    attachedTransactions: [],
    reconciliationTotals: null,
    plaidId: null,
    syncInitial: [],
    loading: false,
    error: null,
    syncTransactionsData: {},
    reconcileBalance: null,
};

export const bankAccountsSlice = createSlice({
    name: 'bankAccounts',
    initialState,
    reducers: {
        fetchBankAccountsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchBankAccountsSuccess: (state, { payload }) => {
            state.accounts = payload;
            state.loading = false;
        },
        fetchBankAccountsFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        fetchLinkTokenRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchLinkTokenSuccess: (state, { payload }) => {
            state.linkToken = payload;
            state.loading = false;
        },
        fetchLinkTokenFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        setAccessTokenRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        setAccessTokenSuccess: (state, { payload }) => {
            state.plaidId = payload.plaid_item_id;
            state.loading = false;
        },
        setAccessTokenFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        fetchTransactionsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchTransactionsSuccess: (state, { payload }) => {
            state.transactions = payload;
            state.loading = false;
        },
        fetchTransactionsFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        importTransactionsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        importTransactionsSuccess: (state) => {
            state.loading = false;
        },
        importTransactionsFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        syncInitialTransactionsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        syncInitialTransactionsSuccess: (state, { payload }) => {
            state.loading = false;
            state.syncInitial = payload;
        },
        syncInitialTransactionsFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        fetchUnimportedTransactionsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchUnimportedTransactionsSuccess: (state, { payload }) => {
            state.unimportedTransactions = payload;
            state.loading = false;
        },
        fetchUnimportedTransactionsFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },

        attachTransactionRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        attachTransactionSuccess: (state, { payload }) => {
            const index = state.attachedTransactions.findIndex(
                (transaction) =>
                    transaction.plaid_transaction === payload.plaid_transaction,
            );

            if (index !== -1) {
                state.attachedTransactions[index] = payload;
            } else {
                state.attachedTransactions.push(payload);
            }
            state.loading = false;
        },
        attachTransactionFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        fetchUnmatchedTransactionsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchUnmatchedTransactionsSuccess: (state, { payload }) => {
            state.unmatchedTransactions = payload;
            state.loading = false;
        },
        fetchUnmatchedTransactionsFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        fetchMatchedTransactionsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchMatchedTransactionsSuccess: (state, { payload }) => {
            state.MatchedTransactions = payload;
            state.loading = false;
        },
        fetchMatchedTransactionsFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        fetchmatchedTransactionsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchmatchedTransactionsSuccess: (state, { payload }) => {
            state.matchedTransactions = payload;
            state.loading = false;
        },
        fetchmatchedTransactionsFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },

        inputEndingBalanceRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        inputEndingBalanceSuccess: (state, { payload }) => {
            state.loading = false;
            state.reconcileBalance = payload;
        },
        inputEndingBalanceFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },

        fetchReconciliationTotalsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchReconciliationTotalsSuccess: (state, { payload }) => {
            state.reconciliationTotals = payload;
            state.loading = false;
        },
        fetchReconciliationTotalsFailure: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        syncTransactionsRequest: (state) => {
            // Add this line
            state.loading = true;
            state.error = null;
        },
        syncTransactionsSuccess: (state, { payload }) => {
            // Add this line
            state.syncTransactionsData = payload;
            state.loading = false;
        },
        syncTransactionsFailure: (state, { payload }) => {
            // Add this line
            state.loading = false;
            state.error = payload;
        },
    },
});

export const {
    fetchBankAccountsRequest,
    fetchBankAccountsSuccess,
    fetchBankAccountsFailure,
    fetchLinkTokenRequest,
    fetchLinkTokenSuccess,
    fetchLinkTokenFailure,
    setAccessTokenRequest,
    setAccessTokenSuccess,
    setAccessTokenFailure,
    fetchTransactionsRequest,
    fetchTransactionsSuccess,
    fetchTransactionsFailure,
    importTransactionsRequest,
    importTransactionsSuccess,
    importTransactionsFailure,
    syncInitialTransactionsRequest,
    syncInitialTransactionsSuccess,
    syncInitialTransactionsFailure,
    fetchUnimportedTransactionsRequest,
    fetchUnimportedTransactionsSuccess,
    fetchUnimportedTransactionsFailure,
    attachTransactionRequest,
    attachTransactionSuccess,
    attachTransactionFailure,
    fetchUnmatchedTransactionsRequest,
    fetchUnmatchedTransactionsSuccess,
    fetchUnmatchedTransactionsFailure,
    fetchMatchedTransactionsRequest,
    fetchMatchedTransactionsSuccess,
    fetchMatchedTransactionsFailure,
    fetchmatchedTransactionsRequest,
    fetchmatchedTransactionsSuccess,
    fetchmatchedTransactionsFailure,
    inputEndingBalanceRequest,
    inputEndingBalanceSuccess,
    inputEndingBalanceFailure,
    fetchReconciliationTotalsRequest,
    fetchReconciliationTotalsSuccess,
    fetchReconciliationTotalsFailure,
    syncTransactionsRequest,
    syncTransactionsSuccess,
    syncTransactionsFailure,
} = bankAccountsSlice.actions;

export default bankAccountsSlice.reducer;
