import { alpha, styled } from '@mui/material/styles';
import { Popover, ListItemButton, ListItemIcon } from '@mui/material';

export const StyledItem = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'active' && prop !== 'open',
})(({ active, disabled, open, depth, theme }) => {
    const isLight = theme?.palette?.mode === 'light';

    const subItem = depth !== 1;

    const activeStyle = {
        color: '#00AB55',
        backgroundColor: alpha('#00AB55', 0.08),
        ...(!isLight && {
            color: '#5BE584',
        }),
    };

    const activeSubStyle = {
        color: '#353535',
        backgroundColor: 'transparent',
    };

    const hoverStyle = {
        color: isLight ? '#353535' : '#fff',
        backgroundColor: alpha('#919EAB', 0.2),
    };

    return {
        flexDirection: 'row',
        textTransform: 'capitalize',
        padding: theme.spacing(1, 0, 0.5, 0),
        color: isLight ? '#000000de' : '#fff',
        borderRadius: 8,
        '&:hover': hoverStyle,
        // Sub item
        ...(subItem && {
            flexDirection: 'row',
            padding: theme.spacing(1),
        }),
        // Active item
        ...(active && {
            ...activeStyle,
            '&:hover': {
                ...activeStyle,
            },
        }),
        // Active sub item
        ...(subItem &&
            active && {
                ...activeSubStyle,
                '&:hover': {
                    ...activeSubStyle,
                },
            }),
        // Open
        ...(open && !active && hoverStyle),
        // Disabled
        ...(disabled && {
            '&.Mui-disabled': {
                opacity: 0.64,
            },
        }),
    };
});

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)({
    marginRight: 0,
    marginBottom: 4,
    width: 22,
    height: 22,
});

// ----------------------------------------------------------------------

export const StyledPopover = styled(Popover)(({ theme, depth }) => ({
    pointerEvents: 'none',
    '& .MuiPopover-paper': {
        width: 160,
        ...(depth === 3 && { width: 'auto' }),
        ...(depth === 2 && { width: 'auto' }),
        pointerEvents: 'auto',
        padding: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        boxShadow: `0 0 2px 0 ${alpha(
            theme?.palette?.mode === 'light' ? '#000' : '#000',
            0.24,
        )}`,
        borderRadius: Number(8) * 1.5,
        ...{
            backdropFilter: `blur(${6}px)`,
            WebkitBackdropFilter: `blur(${6}px)`,
            ...(theme?.palette?.mode === 'light'
                ? { backgroundColor: '#fff' }
                : { backgroundColor: '#161C24' }),
        },
    },
}));
export const StyledPopover2 = styled(Popover)(({ theme, depth }) => ({
    // pointerEvents: 'auto',
    '& .MuiPopover-paper': {
        width: 160,
        ...(depth === 3 && { width: 'auto' }),
        ...(depth === 2 && { width: 'auto' }),
        pointerEvents: 'auto',
        padding: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        boxShadow: `0 0 2px 0 ${alpha(
            theme?.palette?.mode === 'light' ? '#000' : '#000',
            0.24,
        )}`,
        borderRadius: Number(8) * 1.5,
        ...{
            backdropFilter: `blur(${6}px)`,
            WebkitBackdropFilter: `blur(${6}px)`,
            ...(theme?.palette?.mode === 'light'
                ? { backgroundColor: '#fff' }
                : { backgroundColor: '#161C24' }),
        },
    },
}));
