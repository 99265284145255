/* eslint-disable no-unused-vars */
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogContent,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import {
    SET_TRANSACTION_TYPE,
    syncInitialTransactionsRequest,
} from 'store/actions';
dayjs.extend(isSameOrAfter);

function ImportTransactions({
    onSyncAll,
    open,
    onClose,
    setImportTransaction,
    setIsLoading,
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { accounts } = useSelector((state) => state.bank);
    const { plaidId } = useSelector((state) => state.bank);
    const todayDate = dayjs(new Date()).format('YYYY-MM-DD');
    const yesterdayDate = dayjs(todayDate).subtract(1, 'day');
    const [selectedDate, setSelectedDate] = useState(yesterdayDate);
    const [showAlert, setShowAlert] = useState('');
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [isImportLoading, setIsImportLoading] = useState(false);

    const handleDateChange = (newDate) => {
        if (newDate && dayjs(newDate).isValid()) {
            setSelectedDate(dayjs(newDate));
        } else {
            setSelectedDate(null);
        }
    };
    const importT = async () => {
        setIsImportLoading(true);
        try {
            const { token } = store.getState().auth;
            if (selectedDate) {
                const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
                let result;
                if (accounts?.length) {
                    result = accounts?.map((item) => ({
                        id: item?.account_id,
                        name: item?.name,
                    }));
                }
                dispatch(
                    syncInitialTransactionsRequest({
                        formattedDate,
                        plaidId,
                        token,
                        setShowAlertMessage,
                        setShowAlert,
                        setIsImportLoading,
                        onSyncAll,
                        result,
                        setImportTransaction,
                        setIsLoading,
                    }),
                );
            }
        } catch (err) {}
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="import-dialog-title"
                maxWidth="md"
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        minHeight: '400px',
                        borderRadius: '16px',
                    },
                }}
            >
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        minHeight: '400px',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                    }}
                >
                    <Box sx={{ padding: 1 }}>
                        <Typography
                            sx={{
                                color: '#0061DB',
                                fontWeight: '700',
                                fontSize: '12px',
                            }}
                        >
                            Step 1 of 2
                        </Typography>
                        <Typography
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'white'
                                        : '#121826',
                                fontWeight: '700',
                                fontSize: '18px',
                            }}
                        >
                            Import Transactions
                        </Typography>

                        <Box sx={{ width: { xs: '100%', md: '100%' } }} mt={3}>
                            <Typography
                                sx={{
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'white'
                                            : '#121826',
                                    fontWeight: '700',
                                    fontSize: '32px',
                                    lineHeight: '38px',
                                }}
                            >
                                From how far back would you like to import your
                                transactions?
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    mb: '5px',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#D3D5DA'
                                            : '#121826',
                                }}
                                mt={2}
                            >
                                Import starting from
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        disableFuture
                                        // shouldDisableDate={(date) => {
                                        //     const dayjsDate = dayjs(date);
                                        //     return dayjsDate.isSameOrAfter(
                                        //         dayjs(),
                                        //         'day',
                                        //     );
                                        // }}
                                        shouldDisableDate={(date) => {
                                            const dayjsDate = dayjs(date);
                                            const ninetyDaysAgo =
                                                dayjs().subtract(365, 'days');

                                            // Disable if the date is before 90 days ago or today or after
                                            return (
                                                dayjsDate.isBefore(
                                                    ninetyDaysAgo,
                                                    'day',
                                                ) ||
                                                dayjsDate.isSameOrAfter(
                                                    dayjs(),
                                                    'day',
                                                )
                                            );
                                        }}
                                        disableHighlightToday
                                        l="Select Date"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            gap={2}
                            mt={5}
                            width="100%"
                        >
                            <Button
                                sx={{
                                    border: '1px solid #394150',
                                    color: '#6C727F',
                                    borderRadius: '8px',
                                    outline: 'none',
                                    textTransform: 'none',
                                }}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                loading={isImportLoading}
                                fullWidth={isSmallScreen}
                                sx={{
                                    backgroundColor: '#0061DB',
                                    color: 'white',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    paddingX: '20px',
                                    '&:hover': {
                                        backgroundColor: '#0056b3',
                                        '@media (hover: hover)': {
                                            backgroundColor: '#0056b3',
                                        },
                                    },
                                }}
                                onClick={importT}
                            >
                                Import Now
                            </LoadingButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

            {showAlert === 'SUCCESS' && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 9999,
                        mt: 2,
                    }}
                >
                    {showAlertMessage === 'ERR_BAD_RESPONSE' ? (
                        <Alert severity="error">Import Transaction Error</Alert>
                    ) : (
                        <Alert severity="success">
                            Import Transaction Successfully
                        </Alert>
                    )}
                </Box>
            )}
        </>
    );
}

export default ImportTransactions;
