import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { INVOICE_PREVIEW_URL } from 'utils/constants';
import CreateLinkPopup from '../ShareInvoice/CreateLinkPopup';
import SharePopup from '../ShareInvoice/SharePopup';

function PreviewTop({ onTabChange, onSyncAll, syncStatus }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const changeTab = (tab, index) => {
        setSelectedTab(index);
        onTabChange(index);
    };
    const tabsData = [{ label: 'Web preview' }, { label: 'Mobile preview' }];

    const handlePreview = useCallback(() => {
        if (syncStatus === 'preview one') {
            onSyncAll('one');
        } else {
            onSyncAll('two');
        }
    }, [onSyncAll, syncStatus]);

    const [sharePopupOpen, setSharePopupOpen] = useState(false);
    const [createLinkPopupOpen, setCreateLinkPopupOpen] = useState(false);
    const [link, setLink] = useState('');

    const handleSharePopupOpen = useCallback(() => {
        setSharePopupOpen(true);
    }, []);

    const handleSharePopupClose = useCallback(() => {
        setSharePopupOpen(false);
    }, []);

    const handleCreateLinkPopupOpen = useCallback(() => {
        setCreateLinkPopupOpen(true);
    }, []);

    const handleCreateLinkPopupClose = useCallback(() => {
        setCreateLinkPopupOpen(false);
    }, []);

    const { invoice } = useSelector((state) => state.invoice);
    const invoiceId = useSelector((state) => state.me.invoiceId);
    const id = invoiceId;
    let paramId;
    if (id) {
        paramId = id;
    } else {
        paramId = invoice?.id;
    }
    const companyid = useSelector((state) => state.company.data?.id);
    const invoiceIds = paramId;
    const companyIds = companyid;
    let emailLink = `${INVOICE_PREVIEW_URL}/invoice/${invoiceIds}/${companyIds}`;

    const handleCreateLink = useCallback(() => {
        const newLink = `${INVOICE_PREVIEW_URL}/invoice/${invoiceIds}/${companyIds}`;
        navigator.clipboard.writeText(newLink);
        setLink(newLink);
        handleCreateLinkPopupClose();
    }, [companyIds, handleCreateLinkPopupClose, invoiceIds]);

    const handleCopyLink = useCallback(() => {
        navigator.clipboard.writeText(link);
    }, [link]);
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mt={-1}
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontWeight: '700',
                            fontSize: '18px',
                            lineHeight: '22px',
                        }}
                    >
                        Invoice Name
                    </Typography>
                    <Typography
                        sx={{
                            color: '#6C727F',
                            fontSize: '14px',
                            fontWeight: '500',
                        }}
                    >
                        You’re now previewing this as a client. Feel free to
                        play around, nothing will be sent!
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="end"
                    sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                        width: { xs: '100%', sm: '60%' },
                        gap: { xs: 1, sm: 3 },
                        marginTop: { xs: 3, sm: 0 },
                    }}
                >
                    {/* <Button
                        width="100%"
                        sx={{
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#212936'
                                    : 'white',
                            color: '#6C727F',
                            borderRadius: '8px',
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            textTransform: 'none',
                            paddingX: '20px',
                        }}
                        startIcon={<SendIcon />}
                        onClick={handleSharePopupOpen}
                    >
                        Send yourself as a test
                    </Button> */}
                    <Button
                        sx={{
                            backgroundColor: '#FDDDDD',
                            color: '#F75555',
                            borderRadius: '8px',
                            border: '1px solid #FCBBBB',
                            textTransform: 'none',
                            paddingX: '20px',
                        }}
                        startIcon={<CancelIcon />}
                        onClick={handlePreview}
                    >
                        Exit preview
                    </Button>
                </Box>
            </Box>

            <Divider />

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    flexDirection: { xs: 'column', sm: 'row' },
                }}
            >
                <Box
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#121826'
                                : '#F4F4F6',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    gap={2}
                    px={1}
                >
                    {tabsData?.map((tab, index) => (
                        <Button
                            key={index}
                            onClick={() => changeTab(tab, index)}
                            sx={{
                                textTransform: 'none',
                                fontSize: '15px',
                                fontWeight:
                                    selectedTab === index ? '600' : '500',
                                backgroundColor:
                                    selectedTab === index
                                        ? '#CEEFDF'
                                        : 'transparent',
                                borderRadius:
                                    selectedTab === index ? '3px' : 'none',
                                margin: '8px 0px',
                                color:
                                    selectedTab === index
                                        ? '#0A8C4D'
                                        : '#6C727F',
                                '&:hover': {
                                    color:
                                        selectedTab === index ? '#0A8C4D' : '',
                                },
                                variant:
                                    selectedTab === index
                                        ? 'contained'
                                        : 'text',
                            }}
                        >
                            {tab.label}
                        </Button>
                    ))}
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    sx={{ marginTop: { xs: 3, sm: 0 } }}
                >
                    <Box
                        sx={{
                            border: '1px solid #E5E6EB',
                            borderRadius: '1000px',
                            padding: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#6C727F',
                        }}
                    >
                        <DescriptionIcon />
                    </Box>
                    <Box>
                        <Typography
                            sx={{ fontWeight: '500', fontSize: '14px' }}
                        >
                            Invoice
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: '400',
                                fontSize: '14px',
                                color: '#6C727F',
                            }}
                        >
                            Due : Feb 1, 2024
                        </Typography>
                    </Box>
                    {/* <Button
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            color: '#6C727F',
                            borderRadius: '8px',
                            marginLeft: '10px',
                            textTransform: 'none',
                        }}
                    >
                        Next
                    </Button> */}
                </Box>
            </Box>

            <SharePopup
                open={sharePopupOpen}
                handleClose={handleSharePopupClose}
                onCreateLink={handleCreateLinkPopupOpen}
                link={link}
                onCopyLink={handleCopyLink}
                emailLink={emailLink}
                invoiceIds={invoiceIds}
            />
            <CreateLinkPopup
                open={createLinkPopupOpen}
                handleClose={handleCreateLinkPopupClose}
                onCreateLink={handleCreateLink}
            />
        </>
    );
}

export default PreviewTop;
