// InvoiceDetails.jsx
import { Box, Typography, useTheme } from '@mui/material';

const InvoiceDetails = ({ invoiceNumber, dueDate, dateIssued }) => {
    const theme = useTheme();
    return (
        <Box
            display="flex"
            sx={{
                mt: { md: 0, xs: 6 },
                flexDirection: { md: 'row', xs: 'column' },
                gap: { xs: 0, md: 6 },
            }}
        >
            <Box>
                <Typography sx={{ color: '#6C727F', marginBottom: '10px' }}>
                    Invoice #
                </Typography>
                <Typography
                    sx={{
                        color:
                            theme.palette.mode === 'dark'
                                ? '#D3D5DA'
                                : '#4D5461',
                        marginBottom: '10px',
                        fontWeight: '600',
                    }}
                >
                    {invoiceNumber}
                </Typography>
                <Typography sx={{ color: '#6C727F', marginBottom: '10px' }}>
                    Next payment due
                </Typography>
                <Typography
                    sx={{
                        color:
                            theme.palette.mode === 'dark'
                                ? '#D3D5DA'
                                : '#4D5461',
                        marginBottom: '10px',
                        fontWeight: '600',
                    }}
                >
                    {dueDate}
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ color: '#6C727F', marginBottom: '10px' }}>
                    Date issued
                </Typography>
                <Typography
                    sx={{
                        color:
                            theme.palette.mode === 'dark'
                                ? '#D3D5DA'
                                : '#4D5461',
                        marginBottom: '10px',
                        fontWeight: '600',
                    }}
                >
                    {dateIssued}
                </Typography>
            </Box>
        </Box>
    );
};

export default InvoiceDetails;
