import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
    Box,
    Button,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Preview from 'assets/icons/preview.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { INVOICE_PREVIEW_URL } from 'utils/constants';
import { formatDateTime, processPaymentStatus } from 'utils/helpers';
import PaymentToolbar from './Components/InvoiceToolbar/paymentOptionsIndex';
import PaymentDueWithStripe from './Components/PaymentDue';
import PaymentSchedule from './Components/PaymentDue/PaymentSchedule';
import CreateLinkPopup from './Components/ShareInvoice/CreateLinkPopup';
import SharePopup from './Components/ShareInvoice/SharePopup';

function PaymentDue({
    onSyncAll,
    invoiceStep,
    totalPreview,
    setInvoiceStep,
    isSingle,
}) {
    const paymentTableRef = useRef();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const paymentDue = useSelector((state) => state.me.paymentDue);
    const singleInvoice = useSelector((state) => state?.invoice?.singleInvoice);
    const [accountType, setAccountType] = useState(
        paymentDue?.accountTypes || 'third',
    );
    const [selectedD, setSelectedD] = useState(null);

    useEffect(() => {
        if (singleInvoice?.payment_method === 'credit_card') {
            setAccountType('first');
            setSelectedD('first');
        } else if (singleInvoice?.payment_method === 'bank_transfer') {
            setAccountType('second');
            setSelectedD('second');
        } else {
            setAccountType('third');
            setSelectedD('first');
        }
    }, [singleInvoice?.payment_method]);

    const scrollToPaymentTable = useCallback(() => {
        paymentTableRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [paymentTableRef]);

    const handleInvoiceViewTable = useCallback(() => {
        scrollToPaymentTable();
    }, [scrollToPaymentTable]);

    const invoices = useSelector((state) => state.invoice.invoices);

    const [sharePopupOpen, setSharePopupOpen] = useState(false);
    const [createLinkPopupOpen, setCreateLinkPopupOpen] = useState(false);
    const [link, setLink] = useState('');

    const handleSharePopupOpen = () => {
        setSharePopupOpen(true);
    };

    const handleSharePopupClose = () => {
        setSharePopupOpen(false);
    };

    const handleCreateLinkPopupOpen = () => {
        setCreateLinkPopupOpen(true);
    };

    const handleCreateLinkPopupClose = () => {
        setCreateLinkPopupOpen(false);
    };

    const companyid = useSelector((state) => state.company.data?.id);
    const invoiceIds = singleInvoice?.id;
    const companyIds = companyid;
    let emailLink = `${INVOICE_PREVIEW_URL}/invoice/${invoiceIds}/${companyIds}`;

    const handleCreateLink = () => {
        const newLink = `${INVOICE_PREVIEW_URL}/invoice/${invoiceIds}/${companyIds}`;
        navigator.clipboard.writeText(newLink);
        setLink(newLink);
        handleCreateLinkPopupClose();
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(link);
    };

    return (
        <>
            {invoiceStep && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                >
                    <Box>
                        <Box display="flex" alignItems="center" gap={2} mb={1}>
                            <Typography
                                sx={{
                                    fontWeight: '700',
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                }}
                            >
                                {singleInvoice?.name}
                            </Typography>
                            <Typography
                                noWrap
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    backgroundColor:
                                        singleInvoice?.status === 'sent'
                                            ? '#ECFDF3'
                                            : singleInvoice?.status ===
                                              'Overdue'
                                            ? '#FEF3F2'
                                            : singleInvoice?.status === 'draft'
                                            ? '#EEF4FF'
                                            : singleInvoice?.status ===
                                              'payment_processing'
                                            ? '#FFF7E5'
                                            : singleInvoice?.status === 'paid'
                                            ? '#E6F4FF'
                                            : '#FEF3F2',

                                    color:
                                        singleInvoice?.status === 'sent'
                                            ? '#067647'
                                            : singleInvoice?.status ===
                                              'Overdue'
                                            ? '#B42318'
                                            : singleInvoice?.status === 'draft'
                                            ? '#3538CD'
                                            : singleInvoice?.status ===
                                              'payment_processing'
                                            ? '#FFB400'
                                            : singleInvoice?.status === 'paid'
                                            ? '#007BFF'
                                            : '#FEF3F2',
                                    border:
                                        singleInvoice?.status === 'sent'
                                            ? '1px solid #ABEFC6'
                                            : singleInvoice?.status ===
                                              'Overdue'
                                            ? '1px solid #FECDCA'
                                            : singleInvoice?.status === 'draft'
                                            ? '1px solid #C7D7FE'
                                            : singleInvoice?.status ===
                                              'payment_processing'
                                            ? '1px solid #FFE5B4'
                                            : singleInvoice?.status === 'paid'
                                            ? '1px solid #C3E7FF'
                                            : '1px solid #FECDCA',
                                    width: '150px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '12px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {processPaymentStatus(singleInvoice?.status)}
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                color: '#6C727F',
                                fontSize: '14px',
                                fontWeight: '500',
                            }}
                        >
                            {formatDateTime(singleInvoice?.created_at)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }} gap={3}>
                        <Button
                            sx={{
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #505C6F'
                                        : '1px solid #E5E6EB',
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? ''
                                        : 'white',
                                color: '#6C727F',
                                borderRadius: '8px',
                                outline: 'none',
                                textTransform: 'none',
                                paddingX: '20px',
                            }}
                            onClick={() => onSyncAll('preview one')}
                            fullWidth={isSmallScreen}
                        >
                            <img
                                src={Preview}
                                alt="Icon"
                                style={{
                                    width: 18,
                                    height: 18,
                                    marginRight: '6px',
                                }}
                            />
                            Preview
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: '#0061DB',
                                color: 'white',
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: '#0051bb',

                                    '@media (hover: none)': {
                                        backgroundColor: '#0061DB',
                                    },
                                },
                                textTransform: 'none',
                                paddingX: '20px',
                            }}
                            startIcon={<ExitToAppIcon />}
                            fullWidth={isSmallScreen}
                            onClick={handleSharePopupOpen}
                        >
                            Share
                        </Button>
                    </Box>
                </Box>
            )}

            <Grid container spacing={2} mt={4}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={!invoiceStep ? 12 : 8}
                    lg={!invoiceStep ? 12 : 8}
                    xl={!invoiceStep ? 12 : 8}
                >
                    <PaymentDueWithStripe
                        onSyncAll={onSyncAll}
                        accountType={accountType}
                        selectedD={selectedD}
                        setSelectedD={setSelectedD}
                        totalPreview={totalPreview}
                        invoiceStep={invoiceStep}
                        setInvoiceStep={setInvoiceStep}
                        isSingle={isSingle}
                    />
                </Grid>

                {invoiceStep && (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <PaymentToolbar
                            onViewRecurringInvoiceDetailsClick={
                                handleInvoiceViewTable
                            }
                        />
                    </Grid>
                )}
            </Grid>

            <Grid container spacing={2} mt={1} ref={paymentTableRef}>
                <PaymentSchedule data={invoices} invoiceStep={invoiceStep} />
            </Grid>

            <SharePopup
                open={sharePopupOpen}
                handleClose={handleSharePopupClose}
                onCreateLink={handleCreateLinkPopupOpen}
                link={link}
                onCopyLink={handleCopyLink}
                emailLink={emailLink}
                invoiceIds={invoiceIds}
            />
            <CreateLinkPopup
                open={createLinkPopupOpen}
                handleClose={handleCreateLinkPopupClose}
                onCreateLink={handleCreateLink}
            />
        </>
    );
}

export default PaymentDue;
