import { all, spawn } from 'redux-saga/effects';
import authSaga from './auth.saga';
import { watchFetchBankAccounts } from './bankAccountsSaga';
import { watchBrandInvoiceSaga } from './invoiceSaga';

export default function* rootSaga() {
  yield all([
    spawn(authSaga),
    spawn(watchFetchBankAccounts),
    spawn(watchBrandInvoiceSaga),
  ]);
}
