import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: null,
    brandInvoice: [],
    clients: [],
    taxes: [],
    listServices: [],
    services: [],
    invoice: null,
    dropDownServices: [],
    invoiceLogo: null,
    invoices: [],
    allTaxes: [],
    stripeAccountLink: null,
    paymentIntents: null,
    singleInvoice: null,
    client: null,
    invoiceServices: [],
    invoiceTotals: null,
};

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        brandInvoiceRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        deleteBrandInvoiceRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        brandInvoiceSuccess: (state, action) => {
            state.loading = false;
            state.brandInvoice = action.payload;
            state.invoiceLogo = action.payload;
        },
        brandInvoiceFailure: (state) => {
            state.loading = false;
        },
        serviceInvoiceRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        serviceInvoiceSuccess: (state, action) => {
            state.loading = false;
            state.services = action.payload;
        },
        appendInvoiceServices: (state, action) => {
            state.loading = false;
            state.dropDownServices = state?.dropDownServices?.map(
                (service, index) =>
                    index === state?.dropDownServices?.length - 1
                        ? action?.payload
                        : service,
            );
        },
        appendInvoiceTaxes: (state, action) => {
            state.loading = false;
            if (state?.singleInvoice?.taxes) {
                if (state?.singleInvoice?.taxes?.length === 0) {
                    state.singleInvoice.taxes = [action?.payload];
                } else {
                    state.singleInvoice.taxes =
                        state?.singleInvoice?.taxes?.map((service, index) =>
                            index === state?.singleInvoice?.taxes?.length - 1
                                ? action?.payload
                                : service,
                        );
                }
            }
        },
        serviceInvoiceFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createClientRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        createClientSuccess: (state) => {
            state.loading = false;
        },
        createClientFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fetchClientsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        deleteClientRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchClientsSuccess: (state, action) => {
            state.clients = action.payload;
            state.loading = false;
        },
        fetchClientsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createTaxRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        createTaxSuccess: (state, action) => {
            state.loading = false;
            state.taxes.push(action.payload);
        },
        createTaxFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createInvoiceRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        createInvoiceSuccess: (state, action) => {
            state.loading = false;
            state.invoice = action.payload;
        },
        createInvoiceFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fetchServicesRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchServicesSuccess: (state, action) => {
            state.loading = false;
            state.dropDownServices = action.payload;
        },
        fetchServicesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateTaxRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        updateTaxSuccess: (state, action) => {
            state.loading = false;
            // Update the tax in the state
            const updatedTax = action.payload;
            const taxIndex = state.taxes.findIndex(
                (tax) => tax.id === updatedTax.id,
            );
            if (taxIndex !== -1) {
                state.taxes[taxIndex] = updatedTax;
            }
        },
        updateTaxFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        deleteTaxRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        deleteTaxSuccess: (state, action) => {
            state.loading = false;
            // Remove the deleted tax from the state
            const deletedTaxId = action.payload;
            state.taxes = state.taxes.filter((tax) => tax.id !== deletedTaxId);
        },
        deleteInvoiceService: (state, action) => {
            state.loading = false;
            const deletedServiceId = action.payload;
            state.dropDownServices = state?.dropDownServices?.filter(
                (service) => service?.id !== deletedServiceId,
            );
        },
        deleteTaxFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateInvoiceRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        updateInvoiceSuccess: (state, action) => {
            state.loading = false;
            state.invoice = action.payload;
        },
        updateInvoiceFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fetchInvoiceLogoRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchInvoiceLogoSuccess: (state, action) => {
            state.loading = false;
            state.invoiceLogo = action.payload;
        },
        fetchInvoiceLogoFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fetchInvoicesRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchInvoicesSuccess: (state, action) => {
            state.loading = false;
            state.invoices = action.payload;
        },
        fetchInvoicesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        deleteInvoiceRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        deleteInvoiceSuccess: (state, action) => {
            state.loading = false;
            state.invoices = state.invoices.filter(
                (invoice) => invoice.id !== action.payload,
            );
        },
        deleteInvoiceFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fetchTaxesRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchTaxesSuccess: (state, action) => {
            state.loading = false;
            state.allTaxes = action.payload;
        },
        fetchTaxesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createStripeAccountLinkRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        createStripeAccountLinkSuccess: (state, action) => {
            state.loading = false;
            state.stripeAccountLink = action.payload;
        },
        createStripeAccountLinkFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        createPaymentIntentRequest: (state) => {
            state.loading = true;
            state.error = null;
        },

        createPaymentIntentSuccess: (state, action) => {
            state.loading = false;
            state.paymentIntents = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.loading = false;
            if (state?.singleInvoice) {
                state.singleInvoice.payment_method = action?.payload;
            }
        },
        setInvoiceType: (state, action) => {
            state.loading = false;
            if (state?.singleInvoice) {
                state.singleInvoice.is_recurring = action?.payload;
            }
        },
        setRecurringInvoice: (state, action) => {
            state.loading = false;
            if (state?.singleInvoice) {
                state.singleInvoice.recurring_details = {
                    ...(state?.singleInvoice?.recurring_details ?? {}),
                    ...action.payload,
                };
            }
        },
        createPaymentIntentFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fetchInvoiceByIdRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchInvoiceByIdSuccess: (state, action) => {
            state.loading = false;
            state.singleInvoice = action.payload;
        },
        setSingleInvoiceTaxes: (state, action) => {
            if (state?.singleInvoice?.taxes)
                state.singleInvoice.taxes = action.payload;
        },
        fetchInvoiceByIdFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        sendEmailRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        sendEmailSuccess: (state) => {
            state.loading = false;
        },
        sendEmailFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setInvoiceServices: (state, action) => {
            state.invoiceServices = action.payload;
        },
        appendInvoiceNewService: (state, action) => {
            state.invoiceServices = [...state?.invoiceServices, action.payload];
        },
        setInvoiceTotals: (state, action) => {
            state.invoiceTotals = action.payload;
        },
        setClientState: (state, action) => {
            state.client = action.payload;
        },
        RESET_INVOICE: (state) => initialState,
    },
});

export const {
    brandInvoiceRequest,
    deleteBrandInvoiceRequest,
    brandInvoiceSuccess,
    brandInvoiceFailure,
    serviceInvoiceRequest,
    serviceInvoiceSuccess,
    appendInvoiceServices,
    appendInvoiceTaxes,
    serviceInvoiceFailure,
    createClientRequest,
    createClientSuccess,
    createClientFailure,
    fetchClientsRequest,
    deleteClientRequest,
    fetchClientsSuccess,
    fetchClientsFailure,
    createTaxRequest,
    createTaxSuccess,
    createTaxFailure,
    createInvoiceRequest,
    createInvoiceSuccess,
    createInvoiceFailure,
    fetchServicesRequest,
    fetchServicesSuccess,
    fetchServicesFailure,
    updateTaxRequest,
    updateTaxSuccess,
    updateTaxFailure,
    deleteTaxRequest,
    deleteTaxSuccess,
    deleteInvoiceService,
    deleteTaxFailure,
    updateInvoiceRequest,
    updateInvoiceSuccess,
    updateInvoiceFailure,
    fetchInvoiceLogoRequest,
    fetchInvoiceLogoSuccess,
    fetchInvoiceLogoFailure,
    fetchInvoicesRequest,
    fetchInvoicesSuccess,
    fetchInvoicesFailure,
    deleteInvoiceRequest,
    deleteInvoiceSuccess,
    deleteInvoiceFailure,
    fetchTaxesRequest,
    fetchTaxesSuccess,
    fetchTaxesFailure,
    createStripeAccountLinkRequest,
    createStripeAccountLinkSuccess,
    createStripeAccountLinkFailure,
    createPaymentIntentRequest,
    createPaymentIntentSuccess,
    setPaymentMethod,
    setInvoiceType,
    setRecurringInvoice,
    createPaymentIntentFailure,
    fetchInvoiceByIdRequest,
    fetchInvoiceByIdSuccess,
    setSingleInvoiceTaxes,
    fetchInvoiceByIdFailure,
    sendEmailRequest,
    sendEmailSuccess,
    sendEmailFailure,
    setInvoiceServices,
    appendInvoiceNewService,
    setClientState,
    RESET_INVOICE,
    setInvoiceTotals,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
