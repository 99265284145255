import styled from '@emotion/styled';
import { Collapse, Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CirclerLogo } from '../assets/icons/circler-logo.svg';
import { ReactComponent as DarkCirclerLogo } from '../assets/icons/DarkLogo.svg';
import {
    MOBILE_MENU_OPTIONS,
    SIDE_MENU_OPTIONS,
} from '../components/SideMenuOptions';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { Switch, Typography } from '../shared';
import { SET_SELECTED_ITEM } from '../store/actions';
import UpgradeToPro from './UpgradeToPro';
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.spacing(0, 0),
    padding: theme.spacing(3),
    backgroundImage: 'unset',
    // [theme.breakpoints.down("sm")]: {
    //     // padding: "2px"
    // },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function Sidebar({
    isDrawerOpen,
    setIsDrawerOpen,
    currentPath,
    handleMenuClick,
    notificationStyle,
    isScreenLowerThan1024,
}) {
    const { themeMode, toggleTheme } = useThemeToggle();
    const navigate = useNavigate();
    const sidebarMenus = isScreenLowerThan1024
        ? [...SIDE_MENU_OPTIONS, ...MOBILE_MENU_OPTIONS]
        : [...SIDE_MENU_OPTIONS];

    const handleNavigateToSubscriptions = () => {
        navigate('/subscriptions');
    };
    const is_subscription_active = useSelector(
        (state) => state?.company?.data?.subscription_active,
    );

    const [open, setOpen] = React.useState(null);

    const handleMenuClicks = (e, option) => {
        handleMenuClick(e, option);
        setOpen((currentOpen) =>
            currentOpen === option.name ? null : option.name,
        );
    };

    const dispatch = useDispatch();
    const selectedItem = useSelector((state) => state.me.selectedItems);

    const handleItemClick = (itemName) => {
        dispatch(SET_SELECTED_ITEM(itemName));
        if (['History', 'Home']?.includes(currentPath)) {
            handleMenuClick(null, {
                name: 'Transaction',
                icon: {},
                selectedIcon: {},
                darkMode: {},
                path: '/transaction',
                displayName: 'Transaction',
                collapse: true,
                items: [
                    {
                        name: 'Transactions',
                        path: '/home',
                    },
                    {
                        name: 'Import transactions',
                        path: '/home',
                    },
                    {
                        name: 'Bank reconciliation',
                        path: '/home',
                    },
                ],
            });
        }
    };

    return (
        <>
            <DrawerHeader>
                <Grid container>
                    <Box
                        sx={{
                            cursor: 'pointer',
                            p: '0 !important',
                        }}
                    >
                        {themeMode === 'light' ? (
                            <CirclerLogo
                                width={150}
                                height={40}
                                onClick={() => navigate('/home')}
                            />
                        ) : (
                            <DarkCirclerLogo
                                width={150}
                                height={40}
                                onClick={() => navigate('/home')}
                            />
                        )}
                    </Box>
                </Grid>
            </DrawerHeader>

            <List sx={{ top: isScreenLowerThan1024 ? 0 : 5 }}>
                {sidebarMenus.map((option, index) => {
                    const isActive = option.displayName === currentPath;
                    const Icon = isActive
                        ? option.selectedIcon
                        : themeMode === 'dark'
                        ? option.darkMode
                        : option.icon;

                    return (
                        <React.Fragment key={index}>
                            <Box width={'100%'}>
                                {(index === 6 || index === 8) && (
                                    <Divider
                                        sx={{
                                            background: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#E5E6EB',
                                        }}
                                    />
                                )}
                            </Box>

                            <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                    p: '8px 0',
                                }}
                            >
                                <Box className={isActive ? 'active' : ''} />
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: isDrawerOpen
                                            ? 'initial'
                                            : 'center',
                                        px: isActive ? 2.25 : 2.5,
                                        '& > .MuiListItemButton-root:hover': {
                                            backgroundColor: 'unset',
                                        },
                                    }}
                                    onClick={(e) => handleMenuClicks(e, option)}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: isDrawerOpen ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {Icon && <Icon />}
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            opacity: isDrawerOpen ? 1 : 0,
                                        }}
                                    >
                                        <Grid
                                            container
                                            justifyContent={'space-between'}
                                            sx={{
                                                width: isActive
                                                    ? '138px'
                                                    : '100%',
                                            }}
                                        >
                                            <Grid item>
                                                <Typography
                                                    text={option.name}
                                                    customStyles={
                                                        isActive
                                                            ? {
                                                                  color: (
                                                                      theme,
                                                                  ) =>
                                                                      '#0061DB',
                                                                  fontWeight: 600,
                                                              }
                                                            : {
                                                                  color: (
                                                                      theme,
                                                                  ) =>
                                                                      theme
                                                                          .textColor
                                                                          .primary,
                                                                  fontSize:
                                                                      isScreenLowerThan1024 &&
                                                                      index >= 5
                                                                          ? '14px'
                                                                          : '16px',
                                                                  fontWeight:
                                                                      isScreenLowerThan1024 &&
                                                                      index >= 5
                                                                          ? 500
                                                                          : 400,
                                                              }
                                                    }
                                                />
                                            </Grid>
                                            {option.hasOwnProperty(
                                                'notificationCount',
                                            ) ? (
                                                <Grid
                                                    item
                                                    sx={{
                                                        display: isDrawerOpen
                                                            ? 'block'
                                                            : 'none',
                                                    }}
                                                >
                                                    {/*  <Box
                                                        component="div"
                                                        sx={notificationStyle}
                                                    >
                                                      <Typography
                                                            text={'8'}
                                                            customStyles={{
                                                                color: '#FFFFFF',
                                                                fontSize:
                                                                    '12px',
                                                                fontWeight: 500,
                                                            }}
                                                        />
                                                    </Box> */}
                                                </Grid>
                                            ) : option.hasOwnProperty(
                                                  'showSwitch',
                                              ) ? (
                                                <Grid
                                                    item
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                >
                                                    <Switch
                                                        checked={
                                                            themeMode === 'dark'
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() =>
                                                            toggleTheme()
                                                        }
                                                    />
                                                </Grid>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>

                            {option.items && (
                                <Collapse
                                    in={open === option.name && isDrawerOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List
                                        component="div"
                                        disablePadding
                                        sx={{ marginLeft: '39px' }}
                                    >
                                        {option.items.map((item) => (
                                            <ListItemButton
                                                key={item.name}
                                                sx={{
                                                    pl: 4,
                                                    color:
                                                        selectedItem ===
                                                        item.name
                                                            ? '#0061DB'
                                                            : '#6C727F',
                                                }}
                                                onClick={() =>
                                                    handleItemClick(item.name)
                                                }
                                            >
                                                <ListItemText
                                                    primary={item.name}
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </React.Fragment>
                    );
                })}
            </List>
            {isDrawerOpen && !is_subscription_active && (
                <UpgradeToPro
                    sx={{ marginTop: 'auto', marginBottom: '20px' }}
                />
            )}
        </>
    );
}

export default Sidebar;
