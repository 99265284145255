/* eslint-disable eqeqeq */
import React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateIcon from '@mui/icons-material/Create';
import { Box, Button, Typography } from '@mui/material';

function MobileView({ reconcile, data, setSelectedAccount }) {
    return (
        <Box>
            {data.map((row) => (
                <Box
                    key={row.id}
                    sx={{
                        p: 2,
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        borderRadius: '10px',
                    }}
                    mb={2}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            <Typography>Account</Typography>
                            {row.name}
                        </Typography>

                        <Button
                            sx={{
                                color: '#6C727F',
                            }}
                            onClick={() => reconcile(row)}
                        >
                            <MoreVertIcon />
                        </Button>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                    >
                        <Typography
                            sx={{
                                borderBottom: 'none',
                                textAlign: 'center',
                                borderTop: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : 'black',
                            }}
                        >
                            <Typography>Reconciliation</Typography>
                            {row?.latest_reconciliation?.statement_date ? (
                                <Button
                                    onClick={() => setSelectedAccount(row)}
                                    sx={{
                                        marginTop: 1,
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '1px solid #394150'
                                                : '1px solid #E5E6EB',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#9EA3AE'
                                                : '#6C727F',
                                        borderRadius: '8px',
                                        outline: 'none',
                                        textTransform: 'none',
                                    }}
                                >
                                    <Typography variant="subtitle">
                                        {
                                            row?.latest_reconciliation
                                                ?.statement_date
                                        }
                                    </Typography>
                                </Button>
                            ) : (
                                '-'
                            )}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            {row.type == 'Bank' ? (
                                <AccountBalanceIcon
                                    sx={{ marginRight: '4px' }}
                                />
                            ) : row.type == 'credit' ? (
                                <CreditCardIcon sx={{ marginRight: '4px' }} />
                            ) : (
                                <AccountBalanceIcon
                                    sx={{ marginRight: '4px' }}
                                />
                            )}
                            {row.type}
                        </Box>
                    </Box>

                    {/* <Box display="flex" flexDirection="column">
                        <Typography>Auto-validation check</Typography>
                        <Typography
                            display="flex"
                            alignItems="center"
                            fontWeight="400"
                            fontSize="16px"
                        >
                            {' '}
                            <CreateIcon sx={{ marginRight: '4px' }} />
                            Difference $0.00
                        </Typography>
                        <Typography
                            fontWeight="500"
                            fontSize="14px"
                            color="#6C727F"
                        >
                            {row?.latest_reconciliation === null
                                ? '-'
                                : row?.latest_reconciliation
                                      ?.reconciled_through}
                        </Typography>
                    </Box> */}
                </Box>
            ))}
        </Box>
    );
}

export default MobileView;
