import {
    Autocomplete,
    Box,
    Divider,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import Countries from '../Countries';

function CreditCardForm({ handleCreditCardChange, creditCardData }) {
    const { invoiceIds } = useParams();
    const isDisabled = !invoiceIds;
    const theme = useTheme();
    const elementOptions = {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };

    return (
        <Box>
            <Box>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Card number
                </Typography>
                <Box
                    sx={{
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        border:
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        borderRadius: '8px',
                        padding: '10px',
                    }}
                >
                    <CardNumberElement
                        options={elementOptions}
                        onReady={(element) => {
                            element.update({ disabled: isDisabled });
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    mt: 2,
                }}
            >
                <Box
                    sx={{
                        flexBasis: {
                            xs: '48%',
                            md: '48%',
                        },
                        maxWidth: {
                            xs: '48%',
                            md: '48%',
                        },
                    }}
                >
                    <Typography
                        sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}
                    >
                        Expire
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? '#394150'
                                    : '#F4F4F6',
                            border:
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            borderRadius: '8px',
                            padding: '10px',
                        }}
                    >
                        <CardExpiryElement
                            options={elementOptions}
                            onReady={(element) => {
                                element.update({ disabled: isDisabled });
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexBasis: {
                            xs: '48%',
                            md: '48%',
                        },
                        maxWidth: {
                            xs: '48%',
                            md: '48%',
                        },
                    }}
                >
                    <Typography
                        sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}
                    >
                        CVC
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? '#394150'
                                    : '#F4F4F6',
                            border:
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            borderRadius: '8px',
                            padding: '10px',
                        }}
                    >
                        <CardCvcElement
                            options={elementOptions}
                            onReady={(element) => {
                                element.update({ disabled: isDisabled });
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Name on card
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    name="cardName"
                    value={creditCardData.cardName}
                    onChange={handleCreditCardChange}
                    placeholder="Ex. John Doe"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    disabled={isDisabled}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : 'none',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Country
                </Typography>
                <Autocomplete
                    options={Countries}
                    getOptionLabel={(option) => option.label}
                    disabled={isDisabled}
                    value={
                        Countries.find(
                            (country) =>
                                country.code === creditCardData.country,
                        ) || null
                    }
                    onChange={(event, newValue) => {
                        handleCreditCardChange({
                            target: {
                                name: 'country',
                                value: newValue ? newValue.code : '',
                            },
                        });
                    }}
                    renderOption={(props, option, { index }) => (
                        <Box component="li" {...props}>
                            {index !== 0 && <Divider />}
                            <Box sx={{ fontSize: '14px', padding: '8px 0' }}>
                                {option.label}
                            </Box>
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Country"
                            variant="outlined"
                            size="small"
                            sx={{
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#F4F4F6',
                                borderRadius: '6px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'none'
                                                : 'none',
                                        borderRadius: '8px',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    )}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Address Line 1
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    name="addressLine1"
                    value={creditCardData.addressLine1}
                    onChange={handleCreditCardChange}
                    placeholder="Ex. 123 Main St"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    disabled={isDisabled}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : 'none',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Address Line 2
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    name="addressLine2"
                    value={creditCardData.addressLine2}
                    onChange={handleCreditCardChange}
                    placeholder="Ex. Apt 4B"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    disabled={isDisabled}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : 'none',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    City
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    name="city"
                    value={creditCardData.city}
                    onChange={handleCreditCardChange}
                    placeholder="Ex. New York"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    disabled={isDisabled}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : 'none',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    State
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    name="state"
                    value={creditCardData.state}
                    onChange={handleCreditCardChange}
                    placeholder="Ex. NY"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    disabled={isDisabled}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : 'none',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ mb: 1, fontSize: '14px', fontWeight: '500' }}>
                    Zip Code
                </Typography>
                <TextField
                    fullWidth
                    size="small"
                    name="zipCode"
                    value={creditCardData.zipCode}
                    onChange={handleCreditCardChange}
                    placeholder="Ex. 10001"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    disabled={isDisabled}
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : 'none',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            </Box>
        </Box>
    );
}

export default CreditCardForm;
