import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    Divider,
    Typography,
} from '@mui/material';
import BankInvoice from 'assets/icons/bankInvoice.svg';
import BankInvoiceDark from 'assets/icons/bankInvoiceDark.svg';
import CardInvoice from 'assets/icons/cardInvoice.svg';
import CardInvoiceDark from 'assets/icons/cardInvoiceDark.svg';
import CreditCard from 'assets/icons/credit-card-bankInvoice.svg';
import CreditCardDark from 'assets/icons/credit-card-bankInvoiceDark.svg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setInvoiceType,
    setPaymentMethod,
    setRecurringInvoice,
} from 'store/actions';
import { formatDateService } from 'utils/helpers';
import RecurringInvoiceDetails from './RecurringInvoiceDetails';

function SetPaymentOptions({ onViewRecurringInvoiceDetailsClick }) {
    const dispatch = useDispatch();
    const singleInvoice = useSelector((state) => state?.invoice?.singleInvoice);
    useEffect(() => {
        if (singleInvoice?.is_recurring && !singleInvoice?.recurring_details) {
            dispatch(
                setRecurringInvoice({
                    recurring_frequency: 'day',
                    custom_interval: 0,
                    custom_period: 'days',
                    first_invoice_due_date: formatDateService(new Date()),
                    last_invoice_option: 'no_end_date',
                    on_last_invoice_date: null,
                    after_number_of_invoices: 0,
                }),
            );
        }
    }, [
        dispatch,
        singleInvoice?.is_recurring,
        singleInvoice?.recurring_details,
    ]);

    return (
        <Accordion
            defaultExpanded
            sx={{
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? 'transparent' : 'white',
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontSize: '16px', fontWeight: '500' }}
            >
                Set payment options
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" justifyContent="start" mb={1}>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                        }}
                    >
                        Invoice type
                    </Typography>
                </Box>
                <ButtonGroup
                    fullWidth
                    variant="string"
                    sx={{ display: 'flex', width: '100%' }}
                >
                    <Button
                        onClick={() => dispatch(setInvoiceType(false))}
                        sx={{
                            border: !singleInvoice?.is_recurring
                                ? '1px solid #1976d2'
                                : 'none',
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? (!singleInvoice?.is_recurring &&
                                          '#0061DB26') ||
                                      '#394150'
                                    : (!singleInvoice?.is_recurring &&
                                          '#0061DB26') ||
                                      theme.backgroundColor.secondary,
                            color: 'text.primary',
                            '&:hover': {
                                backgroundColor: !singleInvoice?.is_recurring
                                    ? '#bbdefb'
                                    : 'rgba(0, 0, 0, 0.04)',
                            },
                            textTransform: 'none',
                        }}
                    >
                        Standard
                    </Button>
                    {/* <Button
                        onClick={() => dispatch(setInvoiceType(true))}
                        sx={{
                            border: singleInvoice?.is_recurring
                                ? '1px solid #1976d2'
                                : 'none',
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? (singleInvoice?.is_recurring &&
                                          '#0061DB26') ||
                                      '#394150'
                                    : (singleInvoice?.is_recurring &&
                                          '#0061DB26') ||
                                      theme.backgroundColor.secondary,
                            color: 'text.primary',
                            '&:hover': {
                                backgroundColor: singleInvoice?.is_recurring
                                    ? '#bbdefb'
                                    : 'rgba(0, 0, 0, 0.04)',
                            },
                            textTransform: 'none',
                        }}
                    >
                        Recurring
                    </Button> */}
                </ButtonGroup>

                {singleInvoice?.is_recurring && (
                    <RecurringInvoiceDetails
                        onViewRecurringInvoiceDetailsClick={
                            onViewRecurringInvoiceDetailsClick
                        }
                    />
                )}

                <Divider />

                <Box display="flex" justifyContent="start" my={2}>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                        }}
                    >
                        Allow your clients to pay you with
                    </Typography>
                </Box>
                <ButtonGroup
                    fullWidth
                    variant="string"
                    sx={{ display: 'flex', width: '100%' }}
                >
                    {[
                        {
                            label: 'Credit Card',
                            value: 'credit_card',
                            icon: CardInvoice,
                            iconDark: CardInvoiceDark,
                        },
                        {
                            label: 'Bank transfer',
                            value: 'bank_transfer',
                            icon: BankInvoiceDark,
                            iconDark: BankInvoice,
                        },
                        {
                            label: 'Both',
                            value: 'both',
                            icon: CreditCardDark,
                            iconDark: CreditCard,
                        },
                    ].map((option, index) => (
                        <Button
                            key={index}
                            onClick={() =>
                                dispatch(setPaymentMethod(option.value))
                            }
                            sx={{
                                border:
                                    singleInvoice?.payment_method ===
                                    option?.value
                                        ? '1px solid #1976d2'
                                        : 'none',
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? (singleInvoice?.payment_method ===
                                              option.value &&
                                              '#0061DB26') ||
                                          '#394150'
                                        : (singleInvoice?.payment_method ===
                                              option.value &&
                                              '#0061DB26') ||
                                          theme.backgroundColor.secondary,
                                color: 'text.primary',
                                '&:hover': {
                                    backgroundColor:
                                        singleInvoice?.payment_method ===
                                        option.value
                                            ? '#bbdefb'
                                            : 'rgba(0, 0, 0, 0.04)',
                                },

                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 'auto',
                                padding: '12px',
                                textTransform: 'none',
                            }}
                        >
                            <img
                                src={
                                    singleInvoice?.payment_method ===
                                    option.value
                                        ? option.icon
                                        : option.iconDark
                                }
                                alt="Icon"
                                style={{
                                    width: 24,
                                    height: 24,
                                    marginBottom: 4,
                                }}
                            />
                            {option.label}
                        </Button>
                    ))}
                </ButtonGroup>
            </AccordionDetails>
        </Accordion>
    );
}

export default SetPaymentOptions;
