import { useEffect, useRef, useState } from 'react';
import NavItem from './NavItem';
import { StyledPopover } from './styles';

export default function NavList({
    data,
    depth,
    hasChild,
    title,
    onAccountLick,
    plaidId,
    transactionId,
    onRight,
}) {
    const navRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <NavItem
                ref={navRef}
                item={data}
                depth={depth}
                open={open}
                active={false}
                mainTitle={title}
                isExternalLink={false}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                onAccountLick={onAccountLick}
                plaidId={plaidId}
                transactionId={transactionId}
                onRight={onRight}
            />
            {hasChild && (
                <StyledPopover
                    open={open}
                    depth={depth}
                    anchorEl={navRef.current}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: onRight ? 'right' : 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: onRight ? 'left' : 'right',
                    }}
                    PaperProps={{
                        onMouseEnter: handleOpen,
                        onMouseLeave: handleClose,
                    }}
                >
                    <NavSubList
                        data={data.children}
                        depth={depth}
                        onAccountLick={onAccountLick}
                        plaidId={plaidId}
                        transactionId={transactionId}
                        onRight={onRight}
                    />
                </StyledPopover>
            )}
        </>
    );
}

function NavSubList({
    data,
    depth,
    onAccountLick,
    plaidId,
    transactionId,
    onRight,
}) {
    return (
        <>
            {data.map((list) => (
                <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={depth + 1}
                    hasChild={!!list.children}
                    onAccountLick={onAccountLick}
                    plaidId={plaidId}
                    transactionId={transactionId}
                    onRight={onRight}
                />
            ))}
        </>
    );
}
