import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    MenuItem,
    Modal,
    Select,
    Snackbar,
    Stack,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { saveReconciliation } from 'store/services/bank.Service';
import './balanceSummary.css';

const BalanceSummary = ({
    bankBalance,
    beginningBalance,
    circlerBalance,
    currentMonthTransactions,
    difference,
    realDifference,
    setSyncStatus,
    bank_reconciliation_id,
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const onCompleteReconciliation = () => {
        setShowPopup(!showPopup);
    };

    const handleConfirm = async () => {
        const data = await saveReconciliation(bank_reconciliation_id);

        setSnackbarOpen(true);

        setShowPopup(!showPopup);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="info"
                    sx={{ width: '100%' }}
                >
                    reconciliations saved successfully
                </Alert>
            </Snackbar>

            <Modal
                open={showPopup}
                onClose={() => setShowPopup(false)}
                aria-labelledby="confirmation-modal-title"
                aria-describedby="confirmation-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography
                        id="confirmation-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Are you sure?
                    </Typography>
                    <Typography
                        id="confirmation-modal-description"
                        sx={{ mt: 2 }}
                    >
                        Are you sure you want to complete reconciliation?
                    </Typography>
                    <Box
                        sx={{
                            mt: 3,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            onClick={onCompleteReconciliation}
                            sx={{ mr: 2 }}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            variant="contained"
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={8}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3} textAlign="center">
                            <Typography
                                sx={{ fontWeight: '600', fontSize: '16px' }}
                            >
                                ${bankBalance}
                            </Typography>
                            <Typography
                                sx={{ fontWeight: '400', fontSize: '14px' }}
                            >
                                Bank Account Balance
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2} textAlign="center">
                            <Typography
                                sx={{ fontWeight: '600', fontSize: '20px' }}
                            >
                                -
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={5}
                            textAlign="center"
                            sx={{ position: 'relative' }}
                        >
                            <Typography
                                sx={{ fontWeight: '600', fontSize: '16px' }}
                            >
                                ${circlerBalance}
                            </Typography>
                            <Typography
                                sx={{ fontWeight: '400', fontSize: '14px' }}
                            >
                                Circler Account Balance
                            </Typography>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: '1px',
                                    height: '14px',
                                    backgroundColor: 'divider',
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 'calc(100% + 14px)',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: { xs: '100%', sm: '180%' },
                                    height: '1px',
                                    backgroundColor: 'divider',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        width: '1px',
                                        height: '14px',
                                        backgroundColor: 'divider',
                                        top: 0,
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        width: '1px',
                                        height: '14px',
                                        backgroundColor: 'divider',
                                        top: 0,
                                    }}
                                />
                                <Grid container>
                                    <Grid item xs={4.5} textAlign="center">
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                fontSize: '14px',
                                            }}
                                        >
                                            ${beginningBalance}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: '400',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Beginning Balance
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={0.5} textAlign="center">
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                fontSize: '14px',
                                            }}
                                        >
                                            +
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} textAlign="center">
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                fontSize: '14px',
                                            }}
                                        >
                                            ${currentMonthTransactions}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: '400',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Current Month Transactions
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={1}
                    textAlign="center"
                    marginTop={{ xs: 8, sm: 0 }}
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    gap={0}
                    justifyContent={{ xs: 'center', sm: 'end' }}
                >
                    {realDifference && (
                        <ErrorOutlineIcon
                            sx={{ fontSize: 20, color: '#F5A623' }}
                        />
                    )}
                    <Stack
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Typography
                            sx={{ fontWeight: '600', fontSize: '16px' }}
                        >
                            ${difference}
                        </Typography>
                        <Typography
                            sx={{ fontWeight: '400', fontSize: '14px' }}
                        >
                            Difference
                        </Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    textAlign="center"
                    marginTop={{ xs: 2, sm: 0 }}
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    gap={1}
                    justifyContent="center"
                >
                    <FormControl fullWidth>
                        <Select
                            value={0}
                            renderValue={(selected) => (
                                <Typography variant="subtitle">
                                    Complete Reconciliation
                                </Typography>
                            )}
                            onChange={(event) =>
                                event?.target?.value === 1
                                    ? onCompleteReconciliation()
                                    : setSyncStatus('one')
                            }
                            sx={{
                                background: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#212936'
                                        : '#fff',

                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#9EA3AE'
                                        : '#6C727F',
                                borderRadius: '8px',
                                outline: 'none',
                                textTransform: 'none',
                                '.MuiSelect-select': {
                                    padding: '8px',
                                },
                            }}
                        >
                            <MenuItem value={1}>
                                Complete Reconciliation
                            </MenuItem>
                            <MenuItem value={2}>Save for later</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <div>
                        <ButtonGroup className='button-group'>
                            <Button
                                onClick={onCompleteReconciliation}
                                className='main-button'
                            >
                                {' '}
                                Complete Reconciliation{' '}
                            </Button>
                            <Button
                                className='arrow-button'
                                size='small'
                                onClick={handleClick}
                                aria-describedby={id}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem onClick={() => setSyncStatus('one')}>
                                Save for later
                            </MenuItem>
                        </Popover>
                    </div> */}

                    {/* <Button
                    sx={{
                        backgroundColor: '#0061DB',
                        color: 'white',
                        borderRadius: '8px',
                        paddingX: '10px',
                        textTransform: 'none',

                        '&:hover': {
                            backgroundColor: '#0056b3',
                            '@media (hover: hover)': {
                                backgroundColor: '#0056b3',
                            },
                        },
                    }}
                    onClick={() => setSyncStatus('one')}
                >
                    Save For Later
                </Button> */}
                </Grid>
            </Grid>
        </>
    );
};

export default BalanceSummary;
