import { CircularProgress, IconButton } from '@mui/material';
import { ReactComponent as RightArrow } from 'assets/icons/RightArrow.svg';
import { ReactComponent as RightArrowDarkMode } from 'assets/icons/RightArrowDarkMode.svg';
import { useThemeToggle } from 'hooks/useThemeToggle';
import { useEffect, useRef } from 'react';
import NavItemFirst from './NavItemFirst';
import NavList from './NavList';
import { StyledPopover2 } from './styles';

export default function NavListFirst({
    data,
    depth,
    title,
    onAccountLick,
    plaidId,
    changeDropDownLength,
    isDropDownOpen,
    handleDropDownToggle,
    rowIndex,
    companyId,
    isPanelDataLoading,
    transactionId,
    handleDropDownClose,
    arrowDown = true,
    onRight,
}) {
    const { themeMode } = useThemeToggle();
    const navRef = useRef(null);
    const popoverRef = useRef(null);

    // useEffect(() => {
    //     function handleClickOutside(event) {
    //         if (
    //             navRef?.current &&
    //             !navRef?.current?.contains(event?.target) &&
    //             popoverRef?.current &&
    //             !popoverRef?.current?.contains(event?.target)
    //         ) {
    //             handleDropDownClose();
    //         }
    //     }

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [navRef, popoverRef, handleDropDownClose]);

    // useEffect(() => {
    //     const handleWheel = (event) => {
    //         if (event.target.closest('.MuiPopover-paper')) {
    //             event.preventDefault();
    //         }
    //     };

    //     if (isDropDownOpen) {
    //         window.addEventListener('wheel', handleWheel, { passive: false });
    //     } else {
    //         window.removeEventListener('wheel', handleWheel);
    //     }

    //     // Cleanup event listener on component unmount
    //     return () => {
    //         window.removeEventListener('wheel', handleWheel);
    //     };
    // }, [isDropDownOpen]);

    return (
        <>
            <NavItemFirst
                ref={navRef}
                item={data}
                depth={depth}
                open={isDropDownOpen}
                mainTitle={title}
                onClick={() =>
                    handleDropDownToggle(transactionId, depth, companyId)
                }
            />
            <StyledPopover2
                ref={popoverRef}
                open={isDropDownOpen}
                depth={depth}
                onClose={handleDropDownClose}
                anchorEl={navRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {data?.children?.length && !isPanelDataLoading ? (
                    <>
                        <NavSubList
                            data={data?.children}
                            depth={depth}
                            onAccountLick={onAccountLick}
                            plaidId={plaidId}
                            transactionId={transactionId}
                            onRight={onRight}
                        />
                        {arrowDown && (
                            <div
                                onClick={() => changeDropDownLength()}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                {themeMode !== 'dark' ? (
                                    <IconButton color="inherit">
                                        <RightArrow
                                            style={{
                                                transform:
                                                    data?.children?.length !== 2
                                                        ? 'rotate(-90deg)'
                                                        : 'rotate(90deg)',
                                            }}
                                        />
                                    </IconButton>
                                ) : (
                                    <IconButton color="inherit">
                                        <RightArrowDarkMode
                                            width={16}
                                            height={16}
                                            style={{
                                                transform:
                                                    data?.children?.length !== 2
                                                        ? 'rotate(-90deg)'
                                                        : 'rotate(90deg)',
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <></>
                )}
                {isPanelDataLoading && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    </>
                )}
            </StyledPopover2>
        </>
    );
}

function NavSubList({
    data,
    depth,
    onAccountLick,
    plaidId,
    transactionId,
    onRight,
}) {
    return (
        <>
            {data.map((list) => (
                <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={depth + 1}
                    hasChild={!!list.children}
                    onAccountLick={onAccountLick}
                    plaidId={plaidId}
                    transactionId={transactionId}
                    onRight={onRight}
                />
            ))}
        </>
    );
}
