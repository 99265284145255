import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    meLoading: false,
    data: null,
    error: null,
    selectedItems: 'Transactions',
    checkedRows: {},
    attachedRows: {},
    transactionType: '',
    invoiceTolbarTax: [],
    invoiceServices: [
        {
            name: 'Type to add a service',
            qty: 1,
            item: 'Item',
            unitPrice: 0,
            tax: 0,
            total: 0,
            details: 'Description ..',
            client: {},
        },
    ],
    invoiceTotals: {
        subtotal: 0,
        taxAmount: 0,
        discountValue: 0,
        total: 0,
    },
    paymentDue: null,
    invoiceType: null,
    recurringData: null,
    invoiceId: null,
    paymentMethod: 'card',
    invoiceSettingStep: 'one',
};

const prepare = (payload, meta, error) => {
    return { payload: { ...payload }, meta: { ...meta }, error: { ...error } };
};
export const meSlice = createSlice({
    name: 'me',
    initialState,
    reducers: {
        GET_AUTH_ME: {
            reducer: () => {},
            prepare,
        },
        SET_CURRENT_USER_PENDING: {
            reducer: (state, { payload }) => {
                state.meLoading = payload.loading;
            },
            prepare,
        },
        SET_CURRENT_USER_SUCCESS: {
            reducer: (state, { payload }) => {
                state.data = payload.data;
                state.meLoading = false;
            },
            prepare,
        },
        SET_CURRENT_USER_ERROR: {
            reducer: (state, { payload }) => {
                state.error = payload.err;
                state.meLoading = false;
            },
            prepare,
        },
        SET_SELECTED_ITEM: {
            reducer: (state, { payload }) => {
                state.selectedItems = payload;
            },
            prepare: (itemName) => {
                return { payload: itemName };
            },
        },
        SET_CHECKED_ROWS: {
            reducer: (state, { payload }) => {
                state.checkedRows = payload;
            },
            prepare: (checkedRows) => {
                return { payload: checkedRows };
            },
        },
        SET_ATTACHED_ROWS: {
            reducer: (state, { payload }) => {
                state.attachedRows = {
                    ...state.attachedRows,
                    ...payload,
                };
            },
            prepare: (attachedRows) => {
                return { payload: attachedRows };
            },
        },
        SET_TRANSACTION_TYPE: {
            reducer: (state, { payload }) => {
                state.transactionType = payload;
            },
            prepare: (transactionType) => {
                return { payload: transactionType };
            },
        },
        SET_TAX_TYPE: {
            reducer: (state, { payload }) => {
                state.invoiceTolbarTax = payload;
            },
            prepare: (invoiceTolbarTax) => {
                return { payload: invoiceTolbarTax };
            },
        },
        SET_INVOICE_SERVICE: {
            reducer: (state, { payload }) => {
                state.invoiceServices = payload;
            },
            prepare: (invoiceServices) => {
                return { payload: invoiceServices };
            },
        },
        SET_INVOICE_TOTALS: {
            reducer: (state, { payload }) => {
                state.invoiceTotals = payload;
            },
            prepare: (invoiceTotals) => {
                return { payload: invoiceTotals };
            },
        },
        SET_PAYMENT_DUE: {
            reducer: (state, { payload }) => {
                state.paymentDue = payload;
            },
            prepare: (paymentDue) => {
                return { payload: paymentDue };
            },
        },
        SET_INVOICE_TYPE: {
            reducer: (state, { payload }) => {
                state.invoiceType = payload;
            },
            prepare: (invoiceType) => {
                return { payload: invoiceType };
            },
        },
        SET_RECURRING_DATA: {
            reducer: (state, { payload }) => {
                state.recurringData = payload;
            },
            prepare: (recurringData) => {
                return { payload: recurringData };
            },
        },
        SET_INVOICE_ID: {
            reducer: (state, { payload }) => {
                state.invoiceId = payload;
            },
            prepare: (invoiceId) => {
                return { payload: invoiceId };
            },
        },
        SET_PAYMENT_METHOD: {
            reducer: (state, { payload }) => {
                state.paymentMethod = payload;
            },
            prepare: (paymentMethod) => {
                return { payload: paymentMethod };
            },
        },
        SET_INVOICE_SETTING_STEPS: {
            reducer: (state, { payload }) => {
                state.invoiceSettingStep = payload;
            },
            prepare: (invoiceSettingStep) => {
                return { payload: invoiceSettingStep };
            },
        },
        RESET: (state) => initialState,
    },
});

export const {
    GET_AUTH_ME,
    SET_CURRENT_USER_SUCCESS,
    SET_CURRENT_USER_PENDING,
    SET_CURRENT_USER_ERROR,
    SET_SELECTED_ITEM,
    SET_CHECKED_ROWS,
    SET_ATTACHED_ROWS,
    SET_TRANSACTION_TYPE,
    SET_TAX_TYPE,
    SET_INVOICE_SERVICE,
    SET_INVOICE_TOTALS,
    SET_PAYMENT_DUE,
    SET_INVOICE_TYPE,
    SET_RECURRING_DATA,
    SET_INVOICE_ID,
    SET_PAYMENT_METHOD,
    SET_INVOICE_SETTING_STEPS,
    RESET,
} = meSlice.actions;

export default meSlice.reducer;
