import {
    Box,
    Button,
    Table,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useTheme,
    useMediaQuery,
    IconButton,
    CircularProgress,
    Snackbar,
    Alert,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import SyncIcon from '@mui/icons-material/Sync';
import { useDispatch, useSelector } from 'react-redux';
import {
    SET_TRANSACTION_TYPE,
    fetchBankAccountsFailure,
    fetchBankAccountsRequest,
    fetchBankAccountsSuccess,
    fetchLinkTokenRequest,
    fetchUnimportedTransactionsRequest,
    setAccessTokenRequest,
    syncTransactionsRequest,
} from 'store/actions';
import { store } from 'store';
import { usePlaidLink } from 'react-plaid-link';
import { useCompanyService } from 'store/services/company.service';
import {
    fetchAccountStatement,
    getBankAccounts,
} from 'store/services/bank.Service';
import ErrorAnimation from './Components/ErrorPopup';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadJson } from 'utils/helpers';

function SyncBankAccounts({ onSyncAll, setImportTransaction, setIsLoading }) {
    const [bank, setBank] = React.useState(true);
    const handlePopup = () => {
        setBank(false);
    };
    const [isDownloadLoading, setIsDownloadLoading] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const data = useSelector((state) => state?.company?.data);
    const companyId = data?.id;

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString('en-US', options);
    };
    const dispatch = useDispatch();
    const { accounts, loading, error } = useSelector((state) => state.bank);
    const { getUserAssociatedCompany, isLoading } = useCompanyService();
    const [initialLoading, setInitialLoading] = useState(true);
    const [errorState, setErrorState] = useState(null);
    const { linkToken, plaidId } = useSelector((state) => state.bank);
    const [snackbarMessage, setSnackbarMessage] = useState(false);

    const handleDownload = useCallback(async (accountId) => {
        const { token } = store.getState().auth;
        try {
            setIsDownloadLoading(accountId);
            const response = await fetchAccountStatement(accountId, token);
            if (response?.length) {
                downloadJson(response, `statement_${accountId}`);
            } else {
                setSnackbarMessage('No Statement Found.');
            }
        } catch (error) {
        } finally {
            setIsDownloadLoading(null);
        }
    }, []);

    const handleSnackbarClose = useCallback(() => {
        setSnackbarMessage(false);
    }, []);

    useEffect(() => {
        const fetchUserCompanyAndBankAccounts = async () => {
            const { token } = store.getState().auth;
            try {
                if (companyId) {
                    const bankAccountsData = await getBankAccounts(
                        companyId,
                        token,
                    );
                    dispatch(fetchBankAccountsSuccess(bankAccountsData));
                } else {
                    setErrorState('No associated company found.');
                }
            } catch (error) {
                setErrorState(error.message);
                dispatch(fetchBankAccountsFailure(error.message));
            } finally {
                setInitialLoading(false);
            }
        };

        fetchUserCompanyAndBankAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserAssociatedCompany, dispatch, plaidId]);

    useEffect(() => {
        const token = store.getState().auth.token;
        dispatch(fetchLinkTokenRequest({ token }));
    }, [dispatch]);

    const onSuccess = React.useCallback(async (public_token, metadata) => {
        const { token } = store.getState().auth;
        try {
            await dispatch(
                setAccessTokenRequest({ publicToken: public_token, token }),
            );

            const fetchUserCompanyAndBankAccounts = async () => {
                try {
                    if (companyId) {
                        const bankAccountsData = await getBankAccounts(
                            companyId,
                            token,
                        );
                        dispatch(fetchBankAccountsSuccess(bankAccountsData));
                        setImportTransaction(true);
                        dispatch(SET_TRANSACTION_TYPE('IMPORT'));
                    } else {
                        setErrorState('No associated company found.');
                    }
                } catch (error) {
                    setErrorState(error.message);
                    dispatch(fetchBankAccountsFailure(error.message));
                } finally {
                    setInitialLoading(false);
                }
            };

            fetchUserCompanyAndBankAccounts();
        } catch (error) {
            console.error('Error setting access token:', error);
            setErrorState('Failed to set access token.');
        }
    }, []);

    const config = {
        token: linkToken,
        onSuccess,
    };

    const { open, ready } = usePlaidLink(config);

    const handleSyncClick = () => {
        open();
    };

    const [accountsError, setAccountsError] = useState(false);
    const [accountsErrorMsg, setAccountsErrorMsg] = useState('');
    const sync = (id) => {
        const token = store.getState().auth.token;
        if (accounts?.length) {
            setIsLoading(true);
            const result = accounts?.map((item) => ({
                id: item?.account_id,
                name: item?.name,
            }));
            dispatch(syncTransactionsRequest({ token, onSyncAll, result }));
        } else {
            console.log('error');
            setAccountsErrorMsg(`There's no accounts for sync transactions`);
            setAccountsError(true);
        }
    };
    const handleCloseAnimation = () => {
        setAccountsError(false);
    };

    const handleUnImport = async () => {
        const { token } = store.getState().auth;
        const page = 1;
        if (accounts?.length) {
            setIsLoading(true);
            const result = accounts?.map((item) => ({
                id: item?.id,
                name: item?.name,
            }));
            accounts?.forEach((account, index) => {
                dispatch(
                    fetchUnimportedTransactionsRequest({
                        accountId: account.id,
                        page: page,
                        token: token,
                        ...(index === accounts?.length - 1 && {
                            onSyncAll,
                            result,
                        }),
                    }),
                );
            });
        } else {
            setAccountsErrorMsg(`There's no unimported transactions`);
            setAccountsError(true);
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: isMobile ? 0 : 4,
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                }}
            >
                <Typography ml={1.8} pb={2} sx={{ fontWeight: '800' }}>
                    Sync to bank accounts
                </Typography>

                <Button
                    sx={{
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#9EA3AE'
                                : '#6C727F',
                        borderRadius: '8px',
                        outline: 'none',
                        textTransform: 'none',
                    }}
                    onClick={handleSyncClick}
                    disabled={!ready}
                >
                    + Add new bank account
                </Button>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column-reverse', md: 'column' },
                }}
            >
                <TableContainer
                    sx={{
                        maxHeight: 360,
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '4px',
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        },
                    }}
                >
                    <Table>
                        <TableBody>
                            <TableRow
                                sx={{
                                    borderBottom: 'none',
                                    fontWeight: 'bold',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#212936'
                                            : '#ffffff',
                                }}
                            >
                                <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        fontWeight: 'bold',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#4D5461',
                                    }}
                                >
                                    Account
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#4D5461',
                                        // Ensure it takes up the appropriate space
                                        // width: '10%', // Adjust this width as necessary
                                    }}
                                >
                                    Last synced
                                </TableCell>
                                {/* <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#4D5461',
                                        // Ensure it takes up the appropriate space
                                        width: '15%', // Adjust this width as necessary
                                    }}
                                >
                                    Download Statement
                                </TableCell> */}
                            </TableRow>
                            {accounts?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        borderBottom: 'none',
                                        borderTop: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '1px solid #394150'
                                                : '1px solid #E5E6EB',
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#212936'
                                                : '#ffffff',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            borderTop: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',

                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : 'black',
                                        }}
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            textAlign: 'right',
                                            borderTop: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : 'black',
                                        }}
                                    >
                                        {formatDate(row.last_synced)}
                                    </TableCell>
                                    {/* <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            textAlign: 'right',
                                            borderTop: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : 'black',
                                        }}
                                    > */}
                                    {/* {isDownloadLoading !== row?.id && (
                                            <IconButton
                                                onClick={() =>
                                                    handleDownload(row?.id)
                                                }
                                                sx={{
                                                    minWidth: 0,
                                                    padding: 0,
                                                }}
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                        )} */}
                                    {/* {isDownloadLoading === row?.id && (
                                            <CircularProgress
                                                size={19}
                                                sx={{
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#D3D5DA'
                                                            : 'black',
                                                }}
                                            />
                                        )} */}
                                    {/* </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    display="flex"
                    justifyContent="end"
                    gap={2}
                    mt={5}
                    flexDirection={{ xs: 'column', md: 'row' }}
                >
                    <Button
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#9EA3AE'
                                    : '#6C727F',
                            borderRadius: '8px',
                            outline: 'none',
                            textTransform: 'none',
                        }}
                        onClick={handleUnImport}
                    >
                        UnImported Transactions
                    </Button>

                    <Button
                        startIcon={<SyncIcon />}
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: '8px',
                            paddingX: '20px',
                            '&:hover': {
                                backgroundColor: '#0056b3',
                                '@media (hover: hover)': {
                                    backgroundColor: '#0056b3',
                                },
                            },
                        }}
                        onClick={sync}
                    >
                        Sync All
                    </Button>

                    {accountsError && (
                        <ErrorAnimation
                            onClose={handleCloseAnimation}
                            data={accountsErrorMsg}
                        />
                    )}
                </Box>
            </Box>
            <Snackbar
                open={snackbarMessage}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default SyncBankAccounts;
