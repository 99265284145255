import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/icons/Edit.svg';
import { ReactComponent as EditIconDarkMode } from '../../assets/icons/EditDarkMode.svg';
import { ReactComponent as InfoCircle } from '../../assets/icons/InfoCircle.svg';
import { ReactComponent as InfoCircleDarkMode } from '../../assets/icons/InfoCircleDarkMode.svg';
import { ReactComponent as ParentAccount } from '../../assets/icons/ParentAccount.svg';
import {
    Button,
    DataTable,
    Menu,
    Modal,
    TablePagination,
    Typography,
} from '../../shared';
// import { ReactComponent as DeactiveAccount } from '../../assets/icons/DeactiveAccount.svg';
import { useSelector } from 'react-redux';
import { useCustomMediaQuery } from '../../hooks/useMediaQuery';
import { useThemeToggle } from '../../hooks/useThemeToggle';
import { useCoreService } from '../../store/services/core.service';
import { capitalize, isObjectEmpty } from '../../utils/helpers';
import ManageAccountModal from './ManageAccountModal';

const makeStyles = ({ isScreenLowerThan1024 }) => ({
    root: {
        p: isScreenLowerThan1024 ? '20px 20px' : '0px 40px',
    },
    toggleStyle: {
        padding: '6px',
        display: 'flex',
    },
    infoCircleStyle: {
        backgroundColor: (theme) => theme.backgroundColor.light,
    },
    chartOfAccountTextStyle: {
        color: (theme) => theme.textColor.primary,
        cursor: 'pointer',
        fontWeight: 500,
    },
    textWrapper: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

export const accountHeaders = [
    { id: 'number', label: 'Number', sort: true, maxWidth: '50px' },
    { id: 'name', label: 'Account Name', sort: true, maxWidth: '150px' },
    { id: 'type', label: 'Type', sort: true, maxWidth: '100px' },
    { id: 'basis', label: 'Basis', sort: true, maxWidth: '100px' },
    { id: 'description', label: 'Description', sort: true, maxWidth: '250px' },
    {
        id: 'memo',
        label: 'Memo for Bookkeeper (Vendors)',
        sort: true,
        maxWidth: '250px',
    },
];

export const Account = () => {
    const { themeMode } = useThemeToggle();
    const { getActiveAccounts, updateAccount, isLoading } = useCoreService();

    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const { data: company } = useSelector((state) => state.company);

    const [accounts, setAccounts] = useState([]);
    const [anchorEl, setAnchorEl] = useState();
    const [page, setPage] = useState(1);
    const [alignment] = useState('active');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showChartOfAccount, setShowChartOfAccount] = useState(false);

    const styles = makeStyles({ isScreenLowerThan1024 });
    const isEmpty = isObjectEmpty(selectedRecord);

    // const handleChange = (event, newAlignment) => {
    //     setAlignment(newAlignment);
    // };

    const handleSetAnchorEl = (target, index) => {
        setAnchorEl(target.currentTarget);
        setSelectedRecord(accounts.results[index]);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChartAccountModal = () => {
        setShowChartOfAccount(true);
    };

    const handleCloseModal = () => {
        setShowChartOfAccount(false);
    };

    function formattedTableData() {
        return accounts.results?.map((account) => {
            return {
                number: (
                    <Grid
                        item
                        display="flex"
                        alignItems="center"
                        key={account.id}
                    >
                        <Typography
                            text={account.account_number}
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : 'currentColor',
                            }}
                        />
                    </Grid>
                ),
                name: (
                    <Grid container>
                        {account.parent_account && (
                            <Grid item>
                                <ParentAccount />
                            </Grid>
                        )}
                        &nbsp;&nbsp;
                        <Grid item display="flex" alignItems="center">
                            <Typography
                                text={capitalize(account?.account_name)}
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#D3D5DA'
                                            : 'currentColor',
                                }}
                            />
                        </Grid>
                    </Grid>
                ),
                type: (
                    <Grid item display="flex" alignItems="center">
                        <Typography
                            text={`${capitalize(
                                account.account_type_1,
                            )} - ${capitalize(account.account_type_2)}`}
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : 'currentColor',
                            }}
                        />
                    </Grid>
                ),
                basis: (
                    <Grid item display="flex" alignItems="center">
                        <Typography
                            text={capitalize(account.basis)}
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : 'currentColor',
                            }}
                        />
                    </Grid>
                ),
                description: (
                    <Grid item display="flex" alignItems="center">
                        <Typography
                            text={capitalize(account.description)}
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : 'currentColor',
                                ...styles.textWrapper,
                            }}
                        />
                    </Grid>
                ),
                memo: (
                    <Grid item display="flex" alignItems="center">
                        <Typography
                            text={capitalize(account.note_to_bookkeeper)}
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : 'currentColor',
                                ...styles.textWrapper,
                            }}
                        />
                    </Grid>
                ),
            };
        });
    }

    const handleShowAccountModal = () => {
        anchorEl && setSelectedRecord(null);
        setShowAccountModal(true);
    };

    const handleAccountCloseModal = () => {
        setShowAccountModal(false);
        setSelectedRecord(null);
    };

    const handleManageAccount = (accountId, message) => {
        updateAccount(accountId, company.id, {
            note_to_bookkeeper: message,
        }).then((res) => {
            setAccounts((prevAccounts) => {
                const chartOfAccounts = [...prevAccounts.results];
                const accountIndex = accounts.results.findIndex(
                    (account) => account.id === accountId,
                );
                chartOfAccounts[accountIndex] = res;
                return { ...prevAccounts, results: chartOfAccounts };
            });
        });
        setShowAccountModal(false);
    };

    useEffect(() => {
        if (company?.id) {
            getActiveAccounts(company.id, page).then((activeAccounts) => {
                setAccounts(activeAccounts);
            });
            // else {
            //     getInActiveAccounts(company.id, page).then((activeAccounts) => {
            //         setAccounts(activeAccounts);
            //     });
            // }
        }
    }, [getActiveAccounts, company?.id, page]);

    const handlePageChange = (e, pageNumber) => {
        setPage(pageNumber);
    };
    // if (isLoading) {
    //     return <Loader />;
    // }

    return (
        <Box sx={styles.root}>
            <Grid container py={2} spacing={4} justifyContent={'space-between'}>
                <Grid item xs={8} md={8} lg={8} display={'flex'}>
                    <Box sx={styles.toggleStyle}>
                        <ToggleButtonGroup
                            exclusive
                            size="medium"
                            value={alignment}
                            // onChange={handleChange}
                            aria-label="active-inactive"
                            sx={{
                                padding: '6px',
                                borderRadius: '8px',
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#394150'
                                        : '#fff',
                                textTransform: 'capitalize',
                                '.Mui-selected': {
                                    borderRadius: '4px',
                                    borderTopLeftRadius: '4px !important',
                                    borderBottomLeftRadius: '4px !important',
                                    borderTopRightRadius: '4px !important',
                                    borderBottomRightRadius: '4px !important',
                                    backgroundColor: '#0061DB !important',
                                    color: (theme) => '#fff !important',
                                    textTransform: 'capitalize',
                                },
                                // '.Mui-selected:hover': {
                                //     backgroundColor: '#0061DB',
                                //     color: (theme) => `#fff`,
                                // },
                                '& button': {
                                    width: '100%',
                                    border: 'none',
                                    height: '36px',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#9EA3AE'
                                            : '#121826',
                                    textTransform: 'capitalize',
                                },
                            }}
                        >
                            <ToggleButton value="active">Active</ToggleButton>
                            {/* <ToggleButton value="inactive">
                                Inactive
                            </ToggleButton> */}
                        </ToggleButtonGroup>
                    </Box>
                    {!isMobileScreen && (
                        <Box
                            ml={2}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                            onClick={handleChartAccountModal}
                        >
                            {themeMode === 'dark' ? (
                                <InfoCircleDarkMode />
                            ) : (
                                <InfoCircle sx={styles.infoCircleStyle} />
                            )}
                            &nbsp;
                            <Typography
                                text="What is a chart of accounts?"
                                customStyles={styles.chartOfAccountTextStyle}
                            />
                        </Box>
                    )}
                </Grid>

                <Grid
                    item
                    xs={4}
                    md={4}
                    lg={4}
                    display={'flex'}
                    justifyContent={{
                        sm: 'flex-end',
                        xs: 'flex-end',
                        md: 'flex-end',
                        lg: 'flex-end',
                    }}
                    alignItems={'center'}
                >
                    <Button
                        text={
                            <Typography
                                text={isMobileScreen ? '' : 'Add New Account'}
                                customStyles={{
                                    fontWeight: 500,
                                    color: '#FFF',
                                }}
                            />
                        }
                        startIcon={<AddIcon htmlColor={'#FFF'} />}
                        customStyles={{
                            padding: '12px 20px',
                            height: '48px',
                            '& .MuiButton-startIcon': {
                                mr: isMobileScreen ? '0px' : '8px',
                            },
                        }}
                        onClick={() => handleShowAccountModal()}
                    />
                </Grid>
                {isMobileScreen && (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        onClick={handleChartAccountModal}
                    >
                        <InfoCircle
                            sx={styles.infoCircleStyle}
                            fill={themeMode === 'dark' ? 'white' : 'white'}
                        />
                        &nbsp;
                        <Typography
                            text="What is a chart of accounts?"
                            customStyles={styles.chartOfAccountTextStyle}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <DataTable
                        canEdit
                        headCells={accountHeaders}
                        data={formattedTableData()}
                        setAnchorEl={handleSetAnchorEl}
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid item xs={12} p={'10px 0'}>
                    <TablePagination
                        page={page}
                        count={accounts.count}
                        isMobileScreen={isMobileScreen}
                        onChange={handlePageChange}
                    />
                </Grid>
                <Menu
                    items={[
                        {
                            name: (
                                <Box display={'flex'} alignItems={'center'}>
                                    {themeMode === 'dark' ? (
                                        <EditIconDarkMode />
                                    ) : (
                                        <EditIcon />
                                    )}{' '}
                                    &nbsp; &nbsp;
                                    <Typography
                                        text={'Edit memo for bookkeeper'}
                                        customStyles={{
                                            fontSize: '14px',
                                            fontWeight: 500,
                                        }}
                                    />
                                </Box>
                            ),
                            onClick: () => {
                                handleShowAccountModal();
                            },
                        },
                        // {
                        //     name: (
                        //         <Box display={'flex'} alignItems={'center'}>
                        //             <DeactiveAccount /> &nbsp; &nbsp;
                        //             <Typography
                        //                 text={'Deactivate Account'}
                        //                 customStyles={{
                        //                     fontSize: '14px',
                        //                     fontWeight: 500,
                        //                     color: (theme) =>
                        //                         theme.textColor.warning,
                        //                 }}
                        //             />
                        //         </Box>
                        //     ),
                        //     onClick: () => {},
                        // },
                    ]}
                    anchorEl={anchorEl}
                    handleClose={handleCloseMenu}
                />
            </Grid>
            <Modal
                fullWidth
                title={
                    <Typography
                        text={'What is a chart of accounts?'}
                        customStyles={{
                            fontSize: isScreenLowerThan1024 ? '24px' : '32px',
                            fontWeight: 700,
                        }}
                    />
                }
                cancelBtnText={
                    <Typography
                        text={'Close'}
                        customStyles={{
                            color: '#fff',
                            fontSize: 18,
                            fontWeight: 500,
                        }}
                    />
                }
                showImprovementText={false}
                open={showChartOfAccount}
                handleClose={handleCloseModal}
                customCancelBtnStyle={{
                    backgroundColor: '#0061DB !important',
                }}
            >
                <Grid container spacing={0} justifyContent={'center'}>
                    <Grid item>
                        <Typography
                            text={
                                "A chart of accounts (COA) is an organizational tool that provides a complete listing of every financial account (category) in the general ledger of a company, broken down into subcategories. It is used to create visibility into a company's financial health for business leaders, boards, shareholders and/or investors. To make it easier for readers to locate specific accounts, each chart of accounts typically contains a name, brief description, and a number. Source: Investopedia"
                            }
                            customStyles={{
                                p: '0 16px',
                                fontSize: isScreenLowerThan1024
                                    ? '16px'
                                    : '18px',
                                fontWeight: 500,
                                color: (theme) => theme.primary.dark,
                            }}
                        />
                    </Grid>
                </Grid>
            </Modal>
            <ManageAccountModal
                open={showAccountModal}
                title={isEmpty ? 'Add New Account' : 'Update Account'}
                btnTitle={
                    <Typography
                        text={isEmpty ? 'Create' : 'Update'}
                        customStyles={{
                            fontWeight: 500,
                            color: '#FFF',
                        }}
                    />
                }
                account={selectedRecord}
                handleCloseModal={handleAccountCloseModal}
                handleManageAccount={handleManageAccount}
                showFooter={isEmpty ? false : true}
            />
        </Box>
    );
};
